/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  TableRow,
} from '@mui/material';
import AdminsListRow from './AdminsListRow';
import { StyledTableCell } from '../../../styles/admin/ExamsListStyles';
import EmptyDataComponent from '../../states/componentStates/EmptyDataComponent';
import TablePagination from '../../common/TablePagination';
import { AdminsInterface } from './AdminsList';

interface AdminsListType {
  admins: Array<AdminsInterface>;
  adminTableTitle: string;
  pageNum: number;
  onNext: React.MouseEventHandler<HTMLButtonElement>;
  onPrev: React.MouseEventHandler<HTMLButtonElement>;
  nextDisabled: boolean;
}

const AdminsListTable = (props: AdminsListType) => {
  const { admins, adminTableTitle, pageNum, onNext, onPrev, nextDisabled } =
    props;

  return (
    <Paper sx={{ boxShadow: '0px 8px 14px rgba(72, 84, 115, 0.15)' }}>
      <TableContainer sx={{ marginTop: 2 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>{adminTableTitle}</StyledTableCell>
            </TableRow>
          </TableHead>
          {admins.length > 0 ? (
            <TableBody sx={{ backgroundColor: '#ffffff' }}>
              {admins.map((admin: AdminsInterface) => (
                <AdminsListRow key={admin.id} admin={admin} />
              ))}
            </TableBody>
          ) : (
            <EmptyDataComponent message="No admins have been added" />
          )}
        </Table>
      </TableContainer>
      <TablePagination
        pageNum={pageNum}
        buttonDisabled={nextDisabled}
        handleNext={onNext}
        handlePrev={onPrev}
      />
    </Paper>
  );
};

export default AdminsListTable;
