import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import StudentExamsList from '../../components/student/examList/StudentExamsList';
import { DashboardContainer } from '../../styles/admin/DashboardStyles';

function StudentDashboardPage() {
  return (
    <DashboardContainer>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={12} md={9}>
          <Typography
            color="secondary.main"
            variant="h5"
            fontWeight="fontWeightBold"
          >
            Candidate Dashboard
          </Typography>
          <StudentExamsList />
        </Grid>
      </Grid>
    </DashboardContainer>
  );
}

export default StudentDashboardPage;
