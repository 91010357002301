import { Program } from './types.generated';

export const USER_KEY = 'isLoggedIn';
export const USER_ROLE = 'role';
export const USER_NAME = 'name';

export const ADMIN = 'Admin'

export const programs = [
  {
    id: 1,
    title: 'Blockchain',
    value: Program.Blockchain,
  },
  {
    id: 2,
    title: 'Full Stack',
    value: Program.FullStack,
  },
  {
    id: 3,
    title: 'Cybersecurity',
    value: Program.Cybersecurity,
  },
  {
    id: 4,
    title: 'Tech Sales',
    value: Program.TechSales,
  },
];
