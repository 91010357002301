import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Stack,
  FormControl,
  Select,
  SelectChangeEvent,
  MenuItem,
  useMediaQuery,
  Dialog,
  DialogContent,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MultipleChoiceComponent from './MCQuestion/MCQComponent';
import MultipleSelectionComponent from './MSQuestion/MSQComponent';
import { QuestionType } from './addQuestionProps';
import CustomDialogTitle from '../common/CustomDialogTitle';
import { DivAddQuestionStyle } from '../../../../styles/admin/QuestionsPageStyles';
import { QuestionInterface } from '../../../../pages/admin/questionBank/QuestionInterface';
import { programs } from '../../../../constants';

interface AddQuestionProps {
  data: QuestionInterface[];
}

const AddQuestionModal = ({ data }: AddQuestionProps) => {
  const matches = useMediaQuery('(min-width:600px)');

  const [selectedProgramIndex, setSelectedProgramIndex] = useState<number>(
    programs[0].id
  );

  const [module, setModule] = useState('');

  const types: QuestionType[] = [
    {
      id: 1,
      value: 'Multiple Choice',
      element: (
        <MultipleChoiceComponent
          program={programs[selectedProgramIndex - 1].value}
          module={module}
        />
      ),
    },
    {
      id: 2,
      value: 'Multiple Selection',
      element: (
        <MultipleSelectionComponent
          program={programs[selectedProgramIndex - 1].value}
          module={module}
        />
      ),
    },
  ];

  useEffect(() => {
    setSelectedProgramIndex(programs[0].id);
    setModule('');
  }, [data]);

  const getQuestionTypeComponent = (id: number) => {
    for (let i = 0; i < types.length; i++) {
      if (types[i].id === id) {
        return types[i].element;
      }
    }
    return null;
  };

  const [open, setOpen] = useState(false);
  const [selectedTypeIndex, setSelectedTypeIndex] = useState<number>(
    types[0].id
  );

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setSelectedProgramIndex(programs[0].id);
    setModule('');
  };

  const handleTypeChange = (event: SelectChangeEvent<number>) => {
    setSelectedTypeIndex(event.target.value as number);
  };

  const handleProgramChange = (event: SelectChangeEvent<number>) => {
    setSelectedProgramIndex(event.target.value as number);
  };

  const handleModuleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModule(event.target.value);
  };

  return (
    <div>
      <Button
        data-testid="add-question"
        variant="contained"
        disableElevation
        startIcon={matches ? <AddIcon /> : null}
        onClick={handleOpen}
        sx={{
          color: '#FFFFFF',
          textTransform: 'none',
          boxShadow: 0,
          height: '40px',
          backgroundColor: '#539536',
          minWidth: '150px',
        }}
      >
        {matches ? 'Add Question' : <AddIcon />}
      </Button>
      <Dialog
        data-testid="add-question-modal"
        open={open}
        onClose={handleClose}
        maxWidth={matches ? 'md' : 'sm'}
        fullScreen={!matches}
      >
        <CustomDialogTitle text="Add Question" handleClose={handleClose} />
        <DialogContent>
          <Box sx={{ height: '30px' }} />
          <DivAddQuestionStyle>
            <Stack direction="row" spacing={10}>
              <Typography
                id="program-label"
                sx={{ mt: 2, fontSize: matches ? 16 : 14 }}
              >
                Program
              </Typography>
              <FormControl sx={{ width: matches ? 400 : 220 }}>
                <Select
                  data-testid="select-program"
                  value={selectedProgramIndex}
                  onChange={handleProgramChange}
                  defaultValue={selectedProgramIndex}
                >
                  {programs.map((program) => {
                    return (
                      <MenuItem
                        data-testid="select-program-option"
                        key={program.id}
                        value={program.id}
                      >
                        {program.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
            <Box height={15} />

            <Stack direction="row" spacing={11}>
              <Typography
                data-testid="module-label"
                sx={{ mt: 2, fontSize: matches ? 16 : 14 }}
              >
                Module
              </Typography>
              <TextField
                required
                variant="outlined"
                placeholder="Type module here"
                inputProps={{ 'data-testid': 'module' }}
                onChange={handleModuleChange}
                value={module}
                sx={{ width: matches ? 400 : 220 }}
              />
            </Stack>
            <Box height={15} />

            <Stack direction="row" spacing={4.7}>
              <Typography
                id="question-type-label"
                sx={{ mt: 2, fontSize: matches ? 16 : 14 }}
              >
                Question Type
              </Typography>
              <FormControl sx={{ width: matches ? 400 : 220 }}>
                <Select
                  data-testid="select-question-type"
                  value={selectedTypeIndex}
                  onChange={handleTypeChange}
                  defaultValue={selectedTypeIndex}
                >
                  {types.map((type) => {
                    return (
                      <MenuItem
                        data-testid="select-option"
                        key={type.id}
                        value={type.id}
                      >
                        {type.value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
            <Box height={15} />
            {selectedTypeIndex ? (
              getQuestionTypeComponent(selectedTypeIndex)
            ) : (
              <span />
            )}
          </DivAddQuestionStyle>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddQuestionModal;
