import { gql } from '@apollo/client';

export const CREATE_MC_OPTION_MUTATION = gql`
  mutation CreateMultipleChoiceOption($input: CreateOptionInput!) {
    createMultipleChoiceOption(input: $input) {
      id
      description
      isCorrect
    }
  }
`;
