import React from 'react';
import { Typography, IconButton } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

function TablePagination(props: {
  pageNum: number;
  buttonDisabled: boolean;
  handleNext: React.MouseEventHandler<HTMLButtonElement>;
  handlePrev: React.MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <div
      style={{
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        textAlign: 'end',
      }}
    >
      <IconButton disabled={!(props.pageNum - 1)} onClick={props.handlePrev}>
        <NavigateBeforeIcon />
      </IconButton>
      <Typography component="span" color="secondary" sx={{ fontSize: 12 }}>
        Page {props.pageNum}
      </Typography>
      <IconButton disabled={props.buttonDisabled} onClick={props.handleNext}>
        <NavigateNextIcon />
      </IconButton>
    </div>
  );
}

export default TablePagination;
