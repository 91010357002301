import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Radio,
  Dialog,
  DialogContent,
  useMediaQuery,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Typography,
  Box,
  Button,
  DialogActions,
} from '@mui/material';
import QuestionTypeHeader from '../../common/QuestionTypeHeader';
import EditQuestionTextField from '../components/EditQuestionTextField';
import EditAnswerTextField from '../components/EditAnswerTextField';
import EditModuleTextField from '../components/EditModuleTextField';
import EditProgram from '../components/EditProgram';
import NewAnswerTextField from '../components/NewAnswerTextField';
import CustomDialog from '../../../../dialog/CustomDialog';
import QuestionActionButton from '../../common/QuestionActionButton';
import {
  DivStyle,
  ModuleProgramDivStyle,
  StyledDiv,
  StyledGridItem,
  StyledOptionDiv,
} from '../../../../../styles/admin/QuestionsPageStyles';
import { BottomScrollBox } from '../../../../../styles/styledComponents/ModalStyles';
import { EditDialogProps } from '../editQuestionProps';
import message from '../../../../dialog/message.json';
import CustomDialogTitle from '../../common/CustomDialogTitle';
import { QuestionAnswerInterface } from '../../../../../pages/admin/questionBank/QuestionInterface';
import {
  UpdateMultipleChoiceOptionMutation,
  UpdateMultipleChoiceOptionMutationVariables,
} from '../../../../../graphql/mutations/admin/multipleChoice/option/updateMultipleChoiceOption.generated';
import { UPDATE_MC_OPTION_MUTATION } from '../../../../../graphql/mutations/admin/multipleChoice/option/updateMultipleChoiceOption';
import {
  DeleteMultipleChoiceOptionMutation,
  DeleteMultipleChoiceOptionMutationVariables,
} from '../../../../../graphql/mutations/admin/multipleChoice/option/deleteMultipleChoiceOption.generated';
import { DELETE_MC_OPTION_MUTATION } from '../../../../../graphql/mutations/admin/multipleChoice/option/deleteMultipleChoiceOption';
import {
  CreateMultipleChoiceOptionMutation,
  CreateMultipleChoiceOptionMutationVariables,
} from '../../../../../graphql/mutations/admin/multipleChoice/option/createMultipleChoiceOption.generated';
import { CREATE_MC_OPTION_MUTATION } from '../../../../../graphql/mutations/admin/multipleChoice/option/createMultipleChoiceOption';
import { UPDATE_MC_QUESTION_MUTATION } from '../../../../../graphql/mutations/admin/multipleChoice/question/updateMultipleChoiceQuestion';
import {
  UpdateMultipleChoiceQuestionMutation,
  UpdateMultipleChoiceQuestionMutationVariables,
} from '../../../../../graphql/mutations/admin/multipleChoice/question/updateMultipleChoiceQuestion.generated';
import { GetMultipleChoiceQuestionQuery } from '../../../../../graphql/queries/admin/multipleChoice/getMultipleChoiceQuestion.generated';
import GET_MC_QUESTION_QUERY from '../../../../../graphql/queries/admin/multipleChoice/getMultipleChoiceQuestion';
import generateId from '../../../../../utils/idGenerator';
import { programs } from '../../../../../constants';
import { Program } from '../../../../../types.generated';

let mockIds: string[] = [];
const deletedIds: string[] = [];

function EditMCQModal({
  program,
  module,
  questionId,
  questionType,
  question,
  possibleAnswers,
}: EditDialogProps) {
  const matches = useMediaQuery('(max-width:600px)');

  const [programValue, setProgramValue] = useState(program as string);
  const [moduleValue, setModuleValue] = useState(module);
  const [questionValue, setQuestionValue] = useState(question);
  const [correctAnswerId, setCorrectAnswerId] = useState('');
  const [newOption, setNewOption] = useState('');
  const [options, setOptions] = useState(possibleAnswers);

  useEffect(() => {
    const answer = possibleAnswers.find(
      (element: QuestionAnswerInterface) => element.isCorrect
    );
    setCorrectAnswerId(answer?.id ?? '');
  }, []);

  const [openEditQuestion, setOpenEditQuestion] = useState(false);
  const [openUnsaveModal, setOpenUnsaveModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);

  const handleOpenUnsave = () => {
    setOpenUnsaveModal(true);
  };

  const handleCloseUnsave = () => {
    setOpenUnsaveModal(false);
  };

  const handleOpenQuestion = () => {
    setOpenEditQuestion(true);
  };

  const handleCloseEdit = () => {
    handleOpenUnsave();
  };

  const handleConfirmUnsave = () => {
    setModuleValue(module);
    setProgramValue(program);
    setQuestionValue(question);
    setCorrectAnswerId('');
    setNewOption('');
    setOptions(possibleAnswers);
    setOpenEditQuestion(false);
    setOpenUnsaveModal(false);
    if (deletedIds.length > 0) deletedIds.splice(0, deletedIds.length);
    if (mockIds.length > 0) mockIds.splice(0, mockIds.length);
  };

  const handleOpenSave = () => {
    setOpenSaveModal(true);
  };

  const handleCloseSave = () => {
    setOpenSaveModal(false);
  };

  const handleProgramChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProgramValue(event.target.value);
  };

  const handleModuleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModuleValue(e.target.value);
  };

  const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuestionValue(e.target.value);
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewOption(event.target.value);
  };

  const resetNewOptionField = () => {
    setNewOption('');
  };

  // handle each option edit
  const handleOptionTextChange =
    (changedOption: QuestionAnswerInterface) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const forUpdatedList = options.map((option: QuestionAnswerInterface) => {
        return option.id === changedOption.id
          ? { ...option, description: e.target.value }
          : option;
      });

      setOptions(forUpdatedList);
    };

  const removeOption = (removedId: string) => {
    const forUpdatedList = options.filter((option: QuestionAnswerInterface) => {
      return option.id !== removedId;
    });

    setOptions(forUpdatedList);

    // filter list to check which options from the original list are deleted
    const removedFromList = options.filter(
      (option: QuestionAnswerInterface) => {
        return option.id === removedId && !mockIds.includes(option.id);
      }
    );

    // push the id of the deleted option
    if (removedFromList.length > 0)
      deletedIds.push(removedFromList[0].id as string);

    mockIds = mockIds.filter((id) => id !== removedId);
  };

  const chooseCorrectAnswer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selected = event.target.value;
    setCorrectAnswerId(selected);

    const forUpdatedList = options.map((option: QuestionAnswerInterface) => {
      return selected === option.id
        ? { ...option, isCorrect: true }
        : { ...option, isCorrect: false };
    });

    setOptions(forUpdatedList);
  };

  const [updateMCQuestion] = useMutation<
    UpdateMultipleChoiceQuestionMutation,
    UpdateMultipleChoiceQuestionMutationVariables
  >(UPDATE_MC_QUESTION_MUTATION);

  const updateQuestion = () => {
    updateMCQuestion({
      variables: {
        input: {
          id: questionId,
          program: programValue as Program,
          module: moduleValue,
          question: questionValue,
        },
      },
    });
  };

  const [updateMCOption] = useMutation<
    UpdateMultipleChoiceOptionMutation,
    UpdateMultipleChoiceOptionMutationVariables
  >(UPDATE_MC_OPTION_MUTATION);

  const updateOptionList = () => {
    const updatedFromOldList = options.filter(
      (option: QuestionAnswerInterface) => {
        return !mockIds.includes(option.id as string);
      }
    );

    for (const updatedOption of updatedFromOldList) {
      updateMCOption({
        variables: {
          input: {
            id: updatedOption.id as string,
            description: updatedOption.description as string,
            isCorrect: updatedOption.isCorrect as boolean,
          },
        },
      });
    }
  };

  const [deleteMCOption] = useMutation<
    DeleteMultipleChoiceOptionMutation,
    DeleteMultipleChoiceOptionMutationVariables
  >(DELETE_MC_OPTION_MUTATION, {
    update(cache) {
      const existingOptions = cache.readQuery<GetMultipleChoiceQuestionQuery>({
        query: GET_MC_QUESTION_QUERY,
      });
      if (
        existingOptions &&
        existingOptions.multipleChoiceQuestion &&
        existingOptions.multipleChoiceQuestion?.options
      ) {
        const newOptions =
          existingOptions.multipleChoiceQuestion?.options.filter(
            (existingOption) =>
              deletedIds.forEach((o) => existingOption?.id !== o)
          );
        cache.writeQuery<GetMultipleChoiceQuestionQuery>({
          query: GET_MC_QUESTION_QUERY,
          data: {
            multipleChoiceQuestion: {
              __typename: 'MultipleChoiceQuestion',
              id: questionId,
              question: question,
              options: newOptions,
            },
          },
        });
      }
    },
  });

  const deleteOptionList = () => {
    if (deletedIds.length > 0) {
      for (const id of deletedIds) {
        deleteMCOption({
          variables: {
            input: {
              id,
            },
          },
        });
      }
    }
  };

  const [createMCOption] = useMutation<
    CreateMultipleChoiceOptionMutation,
    CreateMultipleChoiceOptionMutationVariables
  >(CREATE_MC_OPTION_MUTATION);

  const createNewOptionList = () => {
    const addedOptions = options.filter((option: QuestionAnswerInterface) => {
      return mockIds.includes(option.id as string);
    });

    for (const option of addedOptions) {
      createMCOption({
        variables: {
          input: {
            multipleChoiceQuestionId: questionId,
            description: option.description as string,
            isCorrect: option.isCorrect as boolean,
          },
        },
      });
    }
  };

  const handleConfirmSave = () => {
    setOpenEditQuestion(false);
    setOpenSaveModal(false);
    updateQuestion();
    updateOptionList();
    deleteOptionList();
    createNewOptionList();
  };

  return (
    <>
      <QuestionActionButton
        handleClick={handleOpenQuestion}
        isMatch={matches}
        label="Edit Question"
      />
      <Dialog
        open={openEditQuestion}
        onClose={handleCloseEdit}
        maxWidth={matches ? 'sm' : 'md'}
        fullWidth
        fullScreen={matches}
        data-testid="edit-mcq"
      >
        <CustomDialogTitle text="Edit Question" handleClose={handleCloseEdit} />
        <DialogContent>
          <Box sx={{ height: '20px' }} />
          <QuestionTypeHeader questionType={questionType} />
          <ModuleProgramDivStyle>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <EditProgram
                  program={programValue}
                  programs={programs}
                  onChange={handleProgramChange}
                />
              </Grid>
              <Grid item xs={6}>
                <EditModuleTextField
                  textValue={moduleValue}
                  onChange={handleModuleChange}
                />
              </Grid>
            </Grid>
          </ModuleProgramDivStyle>
          <DivStyle>
            <EditQuestionTextField
              textValue={question}
              onChange={handleQuestionChange}
            />
          </DivStyle>
          <StyledDiv>
            <Grid container>
              <StyledGridItem item sm={12} container>
                <Typography
                  sx={{ mt: 2, fontSize: matches ? 14 : 16, fontWeight: 600 }}
                >
                  Options
                </Typography>
              </StyledGridItem>
              <StyledGridItem item sm={12}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    value={correctAnswerId}
                    onChange={chooseCorrectAnswer}
                  >
                    <BottomScrollBox>
                      <div>
                        {options?.map(
                          (answer: QuestionAnswerInterface, index: number) => {
                            return (
                              <StyledOptionDiv key={answer.id}>
                                <FormControlLabel
                                  data-testid={`radio-${index + 1}`}
                                  value={answer?.id as string}
                                  control={<Radio />}
                                  label={
                                    <EditAnswerTextField
                                      textValue={answer?.description as string}
                                      onChange={handleOptionTextChange(answer)}
                                      removeFunction={() => {
                                        removeOption(answer.id as string);
                                      }}
                                    />
                                  }
                                />
                              </StyledOptionDiv>
                            );
                          }
                        )}
                      </div>
                    </BottomScrollBox>
                  </RadioGroup>
                </FormControl>
              </StyledGridItem>
            </Grid>
          </StyledDiv>
          <StyledDiv>
            <NewAnswerTextField
              optionChange={handleOptionChange}
              optionValue={newOption}
              handleClick={() => {
                const mockId: string = generateId();
                mockIds.push(mockId);

                const newList = options.concat({
                  id: mockId,
                  description: newOption,
                  isCorrect: false,
                });

                setOptions(newList);

                resetNewOptionField();
              }}
            />
          </StyledDiv>
        </DialogContent>
        <DialogActions>
          <DivStyle>
            <Button
              variant="contained"
              disableElevation
              onClick={handleOpenSave}
              fullWidth={matches}
              data-testid="save-button"
              sx={{
                color: '#FFFFFF',
                textTransform: 'none',
                boxShadow: 0,
              }}
            >
              Save Question
            </Button>
            <CustomDialog
              isOpen={openSaveModal}
              handleClose={handleCloseSave}
              handleConfirm={handleConfirmSave}
              title={message.save.title}
              content={message.save.content}
            />
          </DivStyle>
          <CustomDialog
            isOpen={openUnsaveModal}
            handleClose={handleCloseUnsave}
            handleConfirm={handleConfirmUnsave}
            title={message.unsave.title}
            content={message.unsave.content}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditMCQModal;

EditMCQModal.propTypes = {
  possibleAnswers: PropTypes.array,
};
