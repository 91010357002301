import { styled, TextField } from '@mui/material';

export const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export const ContentStyle = styled('div')(() => ({
  maxWidth: 450,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  marginTop: '18%',
  // justifyContent: 'center',
}));

export const TextFieldWrapper = styled(TextField)(() => ({
  fieldset: {
    borderRadius: '10px',
  },
}));
