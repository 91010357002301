/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import QuestionCard from '../QuestionCard';
import { MCQDisplayProps } from '../displayProps';
import { UserInterface } from '../../../../pages/admin/questionBank/QuestionInterface';
import AnswerItemDisplay from '../components/AnswerItemDisplay';
import { useGetMultipleChoiceQuestionQuery } from '../../../../graphql/queries/admin/multipleChoice/getMultipleChoiceQuestion.generated';

function MCQDisplay({
  id,
  author,
  showDetails,
  handleDelete,
}: MCQDisplayProps) {
  const { data, loading, error } = useGetMultipleChoiceQuestionQuery({
    variables: {
      id: id,
    },
    pollInterval: 2000,
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error...</div>;

  const questionType =
    data?.multipleChoiceQuestion?.__typename ?? 'MultipleChoiceQuestion';
  const question = data?.multipleChoiceQuestion?.question ?? '';
  const options = data?.multipleChoiceQuestion?.options ?? [];

  const user: UserInterface = author?.user as UserInterface;
  const firstName: string = user.firstName;
  const lastName: string = user.lastName;
  const createdBy = `${firstName} ${lastName}`;

  return (
    <div>
      <QuestionCard
        questionType={questionType}
        content={
          <Typography component="pre" variant="body2" color="black">
            {question}
          </Typography>
        }
        details={
          <Stack direction="column" paddingLeft={3.6} paddingRight={3.6}>
            {options?.map((option, index) => (
              <AnswerItemDisplay
                key={option?.id as string}
                optionId={option?.id as string}
                questionType={questionType}
                textValue={option?.description ?? ''}
                testId={`possible-answer-${index + 1}`}
                isCorrectAnswer={option?.isCorrect as boolean}
              />
            ))}
            <Box height={10} />
            <Typography
              data-testid="created-by"
              sx={{
                fontSize: 12,
                color: '#9F9F9F',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              Created by {createdBy}
            </Typography>
          </Stack>
        }
        showDetails={showDetails}
        handleDelete={handleDelete}
      />
    </div>
  );
}

export default MCQDisplay;
