import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCheckoutSessionQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateCheckoutSessionQueryQuery = { __typename?: 'Query', createCheckoutSession?: string | null };


export const CreateCheckoutSessionQueryDocument = gql`
    query CreateCheckoutSessionQuery {
  createCheckoutSession
}
    `;

/**
 * __useCreateCheckoutSessionQueryQuery__
 *
 * To run a query within a React component, call `useCreateCheckoutSessionQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutSessionQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateCheckoutSessionQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateCheckoutSessionQueryQuery(baseOptions?: Apollo.QueryHookOptions<CreateCheckoutSessionQueryQuery, CreateCheckoutSessionQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateCheckoutSessionQueryQuery, CreateCheckoutSessionQueryQueryVariables>(CreateCheckoutSessionQueryDocument, options);
      }
export function useCreateCheckoutSessionQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateCheckoutSessionQueryQuery, CreateCheckoutSessionQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateCheckoutSessionQueryQuery, CreateCheckoutSessionQueryQueryVariables>(CreateCheckoutSessionQueryDocument, options);
        }
export type CreateCheckoutSessionQueryQueryHookResult = ReturnType<typeof useCreateCheckoutSessionQueryQuery>;
export type CreateCheckoutSessionQueryLazyQueryHookResult = ReturnType<typeof useCreateCheckoutSessionQueryLazyQuery>;
export type CreateCheckoutSessionQueryQueryResult = Apollo.QueryResult<CreateCheckoutSessionQueryQuery, CreateCheckoutSessionQueryQueryVariables>;