export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  JSON: any;
}

export interface Admin {
  __typename?: 'Admin';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
}

export interface AdminsConnection {
  __typename?: 'AdminsConnection';
  nodes?: Maybe<Array<Maybe<Admin>>>;
  pageInfo: PageInfo;
}

export type Answer = MultipleChoiceAnswer | MultipleSelectionAnswer;

export interface AnswerInput {
  choices?: InputMaybe<Array<Scalars['String']>>;
  option?: InputMaybe<Scalars['String']>;
  questionId: Scalars['String'];
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export interface CheckExamInput {
  examId: Scalars['String'];
  token: Scalars['String'];
}

export interface Choice {
  __typename?: 'Choice';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCorrect?: Maybe<Scalars['Boolean']>;
}

export interface CreateAdminInput {
  email: Scalars['String'];
}

export interface CreateChoiceInput {
  description: Scalars['String'];
  isCorrect: Scalars['Boolean'];
  multipleSelectionQuestionId?: InputMaybe<Scalars['String']>;
}

export interface CreateExamInput {
  description: Scalars['String'];
  duration?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['Date']>;
  examType: ExamType;
  maxQuestionCount?: InputMaybe<Scalars['Int']>;
  module: Scalars['String'];
  passingRate?: InputMaybe<Scalars['Int']>;
  program: Program;
  quarter: Quarter;
  startDate?: InputMaybe<Scalars['Date']>;
  title: Scalars['String'];
  year: Scalars['Int'];
}

export interface CreateMultipleChoiceQuestionInput {
  module: Scalars['String'];
  options: Array<CreateOptionInput>;
  program: Program;
  question: Scalars['String'];
}

export interface CreateMultipleSelectionQuestionInput {
  choices: Array<CreateChoiceInput>;
  module: Scalars['String'];
  program: Program;
  question: Scalars['String'];
}

export interface CreateOptionInput {
  description: Scalars['String'];
  isCorrect: Scalars['Boolean'];
  multipleChoiceQuestionId?: InputMaybe<Scalars['String']>;
}

export interface DeleteAdminInput {
  id: Scalars['String'];
}

export interface DeleteChoiceInput {
  id: Scalars['String'];
}

export interface DeleteExamInput {
  id: Scalars['String'];
}

export interface DeleteExamPayload {
  __typename?: 'DeleteExamPayload';
  createdAt?: Maybe<Scalars['Date']>;
  createdById?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  examType?: Maybe<ExamType>;
  id?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  maxQuestionCount?: Maybe<Scalars['Int']>;
  program?: Maybe<Program>;
  quarter?: Maybe<Quarter>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  year?: Maybe<Scalars['Int']>;
}

export interface DeleteMultipleChoiceQuestionInput {
  id: Scalars['String'];
}

export interface DeleteMultipleSelectionQuestionInput {
  id: Scalars['String'];
}

export interface DeleteOptionInput {
  id: Scalars['String'];
}

export interface Exam {
  __typename?: 'Exam';
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Date']>;
  examType?: Maybe<ExamType>;
  id?: Maybe<Scalars['String']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  maxQuestionCount?: Maybe<Scalars['Int']>;
  module?: Maybe<Scalars['String']>;
  passingRate?: Maybe<Scalars['Int']>;
  program?: Maybe<Program>;
  quarter?: Maybe<Quarter>;
  questions?: Maybe<Array<Maybe<Question>>>;
  questionsConnection?: Maybe<QuestionConnection>;
  startDate?: Maybe<Scalars['Date']>;
  studentExams?: Maybe<Array<Maybe<StudentExam>>>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  year?: Maybe<Scalars['Int']>;
}


export interface ExamQuestionsConnectionArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}

export interface ExamDetails {
  __typename?: 'ExamDetails';
  createdAt?: Maybe<Scalars['Date']>;
  createdById?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Date']>;
  examType?: Maybe<ExamType>;
  id?: Maybe<Scalars['String']>;
  maxQuestionCount?: Maybe<Scalars['Int']>;
  module?: Maybe<Scalars['String']>;
  passingRate?: Maybe<Scalars['Int']>;
  program?: Maybe<Program>;
  quarter?: Maybe<Quarter>;
  startDate?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  year?: Maybe<Scalars['Int']>;
}

export interface ExamDetailsInput {
  createdAt?: InputMaybe<Scalars['Date']>;
  createdById: Scalars['String'];
  description: Scalars['String'];
  duration?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['Date']>;
  examType: ExamType;
  id: Scalars['String'];
  maxQuestionCount?: InputMaybe<Scalars['Int']>;
  module: Scalars['String'];
  passingRate?: InputMaybe<Scalars['Int']>;
  program: Program;
  quarter: Quarter;
  startDate?: InputMaybe<Scalars['Date']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Date']>;
  year: Scalars['Int'];
}

export interface ExamSubscription {
  __typename?: 'ExamSubscription';
  examId: Scalars['String'];
  userId: Scalars['String'];
}

export enum ExamType {
  Fixed = 'FIXED',
  Flexible = 'FLEXIBLE',
  Free = 'FREE'
}

export interface ExamsConnection {
  __typename?: 'ExamsConnection';
  nodes?: Maybe<Array<Maybe<Exam>>>;
  pageInfo: PageInfo;
}

export interface GenerateExamLinkInput {
  examId: Scalars['String'];
}

export interface GoogleSignInInput {
  tokenId: Scalars['String'];
}

export interface MultipleChoiceAnswer {
  __typename?: 'MultipleChoiceAnswer';
  option?: Maybe<Scalars['String']>;
  questionId: Scalars['String'];
}

export interface MultipleChoiceQuestion extends Question {
  __typename?: 'MultipleChoiceQuestion';
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  options?: Maybe<Array<Maybe<Option>>>;
  program?: Maybe<Program>;
  question?: Maybe<Scalars['String']>;
  questionType?: Maybe<QuestionType>;
  updatedAt?: Maybe<Scalars['Date']>;
}

export interface MultipleChoiceQuestionOnExamPayload {
  __typename?: 'MultipleChoiceQuestionOnExamPayload';
  examId?: Maybe<Scalars['String']>;
  multipleChoiceQuestionId?: Maybe<Scalars['String']>;
}

export interface MultipleChoiceQuestionOutput {
  __typename?: 'MultipleChoiceQuestionOutput';
  option?: Maybe<Option>;
  selected?: Maybe<Scalars['Boolean']>;
}

export interface MultipleSelectionAnswer {
  __typename?: 'MultipleSelectionAnswer';
  choices?: Maybe<Array<Scalars['String']>>;
  questionId: Scalars['String'];
}

export interface MultipleSelectionQuestion extends Question {
  __typename?: 'MultipleSelectionQuestion';
  choices?: Maybe<Array<Maybe<Choice>>>;
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  program?: Maybe<Program>;
  question?: Maybe<Scalars['String']>;
  questionType?: Maybe<QuestionType>;
  updatedAt?: Maybe<Scalars['Date']>;
}

export interface MultipleSelectionQuestionOnExamPayload {
  __typename?: 'MultipleSelectionQuestionOnExamPayload';
  examId?: Maybe<Scalars['String']>;
  multipleSelectionQuestionId?: Maybe<Scalars['String']>;
}

export interface MultipleSelectionQuestionOutput {
  __typename?: 'MultipleSelectionQuestionOutput';
  choice?: Maybe<Choice>;
  selected?: Maybe<Scalars['Boolean']>;
}

export interface Mutation {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']>;
  addQuestionOnExam?: Maybe<QuestionOnExamPayload>;
  createAdmin?: Maybe<Admin>;
  createExam?: Maybe<Exam>;
  createMultipleChoiceOption?: Maybe<Option>;
  createMultipleChoiceQuestion?: Maybe<MultipleChoiceQuestion>;
  createMultipleSelectionChoice?: Maybe<Choice>;
  createMultipleSelectionQuestion?: Maybe<MultipleSelectionQuestion>;
  deleteAdmin?: Maybe<Admin>;
  deleteExam?: Maybe<DeleteExamPayload>;
  deleteMultipleChoiceOption?: Maybe<Option>;
  deleteMultipleChoiceQuestion?: Maybe<MultipleChoiceQuestion>;
  deleteMultipleSelectionChoice?: Maybe<Choice>;
  deleteMultipleSelectionQuestion?: Maybe<MultipleSelectionQuestion>;
  deleteUser?: Maybe<User>;
  generateCertificate?: Maybe<Scalars['JSON']>;
  generateExamLink?: Maybe<Scalars['String']>;
  googleSignIn?: Maybe<UserProfile>;
  isExamLinkValid?: Maybe<Scalars['Boolean']>;
  openStudentExam?: Maybe<OpenStudentExamPayload>;
  register?: Maybe<UserProfile>;
  removeQuestionOnExam?: Maybe<QuestionOnExamPayload>;
  sendEmailCertificate?: Maybe<SendEmailCertificatePayload>;
  sendEmailNotification?: Maybe<Array<Maybe<ExamSubscription>>>;
  sendExamLink?: Maybe<Scalars['Boolean']>;
  signIn?: Maybe<UserProfile>;
  signOut: Scalars['Boolean'];
  submitStudentExam?: Maybe<SubmitStudentExamPayload>;
  subscribe?: Maybe<SubscriptionPayload>;
  unsubscribe?: Maybe<SubscriptionPayload>;
  updateAdmin?: Maybe<Admin>;
  updateExam?: Maybe<Exam>;
  updateMultipleChoiceOption?: Maybe<Option>;
  updateMultipleChoiceQuestion?: Maybe<MultipleChoiceQuestion>;
  updateMultipleSelectionChoice?: Maybe<Choice>;
  updateMultipleSelectionQuestion?: Maybe<MultipleSelectionQuestion>;
  updateStudentExamAnswers?: Maybe<UpdateStudentExamAnswersPayload>;
  updateUserName?: Maybe<User>;
}


export interface MutationAddQuestionOnExamArgs {
  input: QuestionOnExamInput;
}


export interface MutationCreateAdminArgs {
  input: CreateAdminInput;
}


export interface MutationCreateExamArgs {
  input: CreateExamInput;
}


export interface MutationCreateMultipleChoiceOptionArgs {
  input: CreateOptionInput;
}


export interface MutationCreateMultipleChoiceQuestionArgs {
  input: CreateMultipleChoiceQuestionInput;
}


export interface MutationCreateMultipleSelectionChoiceArgs {
  input: CreateChoiceInput;
}


export interface MutationCreateMultipleSelectionQuestionArgs {
  input: CreateMultipleSelectionQuestionInput;
}


export interface MutationDeleteAdminArgs {
  input: DeleteAdminInput;
}


export interface MutationDeleteExamArgs {
  input: DeleteExamInput;
}


export interface MutationDeleteMultipleChoiceOptionArgs {
  input: DeleteOptionInput;
}


export interface MutationDeleteMultipleChoiceQuestionArgs {
  input: DeleteMultipleChoiceQuestionInput;
}


export interface MutationDeleteMultipleSelectionChoiceArgs {
  input: DeleteChoiceInput;
}


export interface MutationDeleteMultipleSelectionQuestionArgs {
  input: DeleteMultipleSelectionQuestionInput;
}


export interface MutationDeleteUserArgs {
  id: Scalars['String'];
}


export interface MutationGenerateCertificateArgs {
  studentExamId: Scalars['String'];
}


export interface MutationGoogleSignInArgs {
  input?: InputMaybe<GoogleSignInInput>;
}


export interface MutationIsExamLinkValidArgs {
  input: CheckExamInput;
}


export interface MutationOpenStudentExamArgs {
  input: OpenStudentExamInput;
}


export interface MutationRegisterArgs {
  input?: InputMaybe<RegisterInput>;
}


export interface MutationRemoveQuestionOnExamArgs {
  input: QuestionOnExamInput;
}


export interface MutationSendEmailCertificateArgs {
  input: SendEmailCertificateInput;
}


export interface MutationSendEmailNotificationArgs {
  input: SendEmailNotificationInput;
}


export interface MutationSendExamLinkArgs {
  email: Scalars['String'];
}


export interface MutationSignInArgs {
  input?: InputMaybe<SignInInput>;
}


export interface MutationSubmitStudentExamArgs {
  input: SubmitStudentExamInput;
}


export interface MutationSubscribeArgs {
  examId: Scalars['String'];
}


export interface MutationUnsubscribeArgs {
  examId: Scalars['String'];
}


export interface MutationUpdateAdminArgs {
  input: UpdateAdminInput;
}


export interface MutationUpdateExamArgs {
  input: UpdateExamInput;
}


export interface MutationUpdateMultipleChoiceOptionArgs {
  input: UpdateOptionInput;
}


export interface MutationUpdateMultipleChoiceQuestionArgs {
  input: UpdateMultipleChoiceQuestionInput;
}


export interface MutationUpdateMultipleSelectionChoiceArgs {
  input: UpdateChoiceInput;
}


export interface MutationUpdateMultipleSelectionQuestionArgs {
  input: UpdateMultipleSelectionQuestionInput;
}


export interface MutationUpdateStudentExamAnswersArgs {
  input: UpdateStudentExamAnswersInput;
}


export interface MutationUpdateUserNameArgs {
  input: UpdateUserInput;
}

export interface OpenStudentExamInput {
  examDetails: ExamDetailsInput;
  examQuestions: Array<Scalars['JSON']>;
  openedAt: Scalars['Date'];
  token: Scalars['String'];
}

export interface OpenStudentExamPayload {
  __typename?: 'OpenStudentExamPayload';
  examDetails?: Maybe<ExamDetails>;
  examQuestions?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  id: Scalars['String'];
  openedAt: Scalars['Date'];
  student?: Maybe<User>;
  studentAnswers?: Maybe<Array<Maybe<Scalars['JSON']>>>;
}

export interface Option {
  __typename?: 'Option';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isCorrect?: Maybe<Scalars['Boolean']>;
}

export type Output = MultipleChoiceQuestionOutput | MultipleSelectionQuestionOutput;

export interface PageInfo {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  startCursor?: Maybe<Scalars['String']>;
}

export enum Program {
  Blockchain = 'BLOCKCHAIN',
  Cybersecurity = 'CYBERSECURITY',
  FullStack = 'FULL_STACK',
  TechSales = 'TECH_SALES'
}

export enum Quarter {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4'
}

export interface Query {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Admin>;
  admins?: Maybe<Array<Maybe<Admin>>>;
  adminsConnection?: Maybe<AdminsConnection>;
  createCheckoutSession?: Maybe<Scalars['String']>;
  currentUser?: Maybe<UserProfile>;
  exam?: Maybe<Exam>;
  exams?: Maybe<Array<Maybe<Exam>>>;
  examsConnection?: Maybe<ExamsConnection>;
  multipleChoiceQuestion?: Maybe<MultipleChoiceQuestion>;
  multipleChoiceQuestions?: Maybe<Array<Maybe<MultipleChoiceQuestion>>>;
  multipleSelectionQuestion?: Maybe<MultipleSelectionQuestion>;
  multipleSelectionQuestions?: Maybe<Array<Maybe<MultipleSelectionQuestion>>>;
  questions?: Maybe<Array<Maybe<Question>>>;
  questionsConnection?: Maybe<QuestionConnection>;
  searchQuestions?: Maybe<QuestionConnection>;
  studentExam?: Maybe<StudentExam>;
  studentExamResult?: Maybe<StudentExamResult>;
  studentExamResults?: Maybe<Array<Maybe<StudentExamResult>>>;
  studentExams?: Maybe<Array<Maybe<StudentExam>>>;
  studentExamsConnection?: Maybe<StudentExamsConnection>;
  studentExamsConnectionByStudentId?: Maybe<StudentExamsConnectionByStudentIdPayload>;
  subscription?: Maybe<SubscriptionPayload>;
  subscriptionStatus?: Maybe<SubscriptionStatusPayload>;
  user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
  usersConnection?: Maybe<UsersConnection>;
}


export interface QueryAdminArgs {
  id: Scalars['String'];
}


export interface QueryAdminsConnectionArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}


export interface QueryExamArgs {
  id: Scalars['String'];
}


export interface QueryExamsConnectionArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}


export interface QueryMultipleChoiceQuestionArgs {
  id: Scalars['String'];
}


export interface QueryMultipleSelectionQuestionArgs {
  id: Scalars['String'];
}


export interface QueryQuestionsArgs {
  module?: InputMaybe<Scalars['String']>;
  program?: InputMaybe<Program>;
}


export interface QueryQuestionsConnectionArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}


export interface QuerySearchQuestionsArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  keywords?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
}


export interface QueryStudentExamArgs {
  examId: Scalars['String'];
  studentId: Scalars['String'];
}


export interface QueryStudentExamResultArgs {
  studentExamId: Scalars['String'];
}


export interface QueryStudentExamResultsArgs {
  examId: Scalars['String'];
  studentId: Scalars['String'];
}


export interface QueryStudentExamsArgs {
  studentId: Scalars['String'];
}


export interface QueryStudentExamsConnectionArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}


export interface QueryStudentExamsConnectionByStudentIdArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  studentId: Scalars['String'];
}


export interface QuerySubscriptionArgs {
  examId: Scalars['String'];
}


export interface QueryUserArgs {
  id: Scalars['String'];
}


export interface QueryUsersConnectionArgs {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}

export interface Question {
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  program?: Maybe<Program>;
  question?: Maybe<Scalars['String']>;
  questionType?: Maybe<QuestionType>;
  updatedAt?: Maybe<Scalars['Date']>;
}

export interface QuestionConnection {
  __typename?: 'QuestionConnection';
  nodes?: Maybe<Array<Maybe<Question>>>;
  pageInfo: PageInfo;
}

export interface QuestionOnExamInput {
  examId: Scalars['String'];
  questionId: Scalars['String'];
}

export interface QuestionOnExamPayload {
  __typename?: 'QuestionOnExamPayload';
  examId?: Maybe<Scalars['String']>;
  questionId?: Maybe<Scalars['String']>;
}

export interface QuestionOutput {
  __typename?: 'QuestionOutput';
  output?: Maybe<Array<Maybe<Output>>>;
  points: Scalars['Float'];
  question?: Maybe<Question>;
  studentAnswer?: Maybe<Answer>;
}

export enum QuestionType {
  MultipleChoiceQuestion = 'MULTIPLE_CHOICE_QUESTION',
  MultipleSelectionQuestion = 'MULTIPLE_SELECTION_QUESTION'
}

export interface RegisterInput {
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  roles?: InputMaybe<Array<InputMaybe<Role>>>;
}

export enum Role {
  Admin = 'ADMIN',
  Student = 'STUDENT'
}

export interface SendEmailCertificateInput {
  certificateLink: Scalars['String'];
}

export interface SendEmailCertificatePayload {
  __typename?: 'SendEmailCertificatePayload';
  successful?: Maybe<Scalars['Boolean']>;
}

export interface SendEmailNotificationInput {
  event: Scalars['String'];
  examId: Scalars['String'];
}

export interface SignInInput {
  email: Scalars['String'];
  password: Scalars['String'];
}

export enum Status {
  Failed = 'FAILED',
  Paid = 'PAID',
  Passed = 'PASSED'
}

export interface StudentExam {
  __typename?: 'StudentExam';
  attempts?: Maybe<Scalars['Int']>;
  examDetails?: Maybe<ExamDetails>;
  examQuestions?: Maybe<Array<Maybe<Question>>>;
  examSummary?: Maybe<Array<Maybe<QuestionOutput>>>;
  id?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['Date']>;
  passed?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  student?: Maybe<User>;
  studentAnswers?: Maybe<Array<Maybe<Answer>>>;
  submittedAt?: Maybe<Scalars['Date']>;
}

export interface StudentExamResult {
  __typename?: 'StudentExamResult';
  attempts?: Maybe<Scalars['Int']>;
  examDetails?: Maybe<ExamDetails>;
  examSummary?: Maybe<Array<Maybe<QuestionOutput>>>;
  id?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['Date']>;
  passed?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  student?: Maybe<User>;
  submittedAt?: Maybe<Scalars['Date']>;
}

export interface StudentExamsConnection {
  __typename?: 'StudentExamsConnection';
  nodes?: Maybe<Array<Maybe<StudentExam>>>;
  pageInfo: PageInfo;
}

export interface StudentHistoryByExam {
  __typename?: 'StudentHistoryByExam';
  studentExams?: Maybe<Array<Maybe<StudentExam>>>;
}

export interface SubmissionDetails {
  __typename?: 'SubmissionDetails';
  examEndTimestamp?: Maybe<Scalars['Date']>;
  examStartTimestamp?: Maybe<Scalars['Date']>;
}

export interface SubmitStudentExamInput {
  examDetails: ExamDetailsInput;
  examQuestions: Array<Scalars['JSON']>;
  id: Scalars['String'];
  studentAnswers: Array<AnswerInput>;
  submittedAt?: InputMaybe<Scalars['Date']>;
  token: Scalars['String'];
}

export interface SubmitStudentExamPayload {
  __typename?: 'SubmitStudentExamPayload';
  examDetails?: Maybe<ExamDetails>;
  passed?: Maybe<Scalars['Boolean']>;
  score?: Maybe<Scalars['Float']>;
  student?: Maybe<User>;
  submittedAt?: Maybe<Scalars['Date']>;
}

export interface SubscriptionPayload {
  __typename?: 'SubscriptionPayload';
  examId?: Maybe<Scalars['String']>;
}

export interface SubscriptionStatusPayload {
  __typename?: 'SubscriptionStatusPayload';
  examLink?: Maybe<Scalars['String']>;
  status?: Maybe<Status>;
}

export interface TokenInput {
  token: Scalars['String'];
}

export interface UpdateAdminInput {
  email: Scalars['String'];
  id: Scalars['String'];
}

export interface UpdateChoiceInput {
  description: Scalars['String'];
  id: Scalars['String'];
  isCorrect: Scalars['Boolean'];
}

export interface UpdateExamInput {
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['Date']>;
  examType: ExamType;
  id: Scalars['String'];
  isPublished?: InputMaybe<Scalars['Boolean']>;
  maxQuestionCount?: InputMaybe<Scalars['Int']>;
  module?: InputMaybe<Scalars['String']>;
  program?: InputMaybe<Program>;
  quarter?: InputMaybe<Quarter>;
  startDate?: InputMaybe<Scalars['Date']>;
  title?: InputMaybe<Scalars['String']>;
  year?: InputMaybe<Scalars['Int']>;
}

export interface UpdateMultipleChoiceQuestionInput {
  id: Scalars['String'];
  module?: InputMaybe<Scalars['String']>;
  program?: InputMaybe<Program>;
  question: Scalars['String'];
}

export interface UpdateMultipleSelectionQuestionInput {
  id: Scalars['String'];
  module?: InputMaybe<Scalars['String']>;
  program?: InputMaybe<Program>;
  question: Scalars['String'];
}

export interface UpdateOptionInput {
  description: Scalars['String'];
  id: Scalars['String'];
  isCorrect: Scalars['Boolean'];
}

export interface UpdateStudentExamAnswersInput {
  id: Scalars['String'];
  studentAnswers: Array<AnswerInput>;
}

export interface UpdateStudentExamAnswersPayload {
  __typename?: 'UpdateStudentExamAnswersPayload';
  examDetails?: Maybe<ExamDetails>;
  id?: Maybe<Scalars['String']>;
  openedAt?: Maybe<Scalars['Date']>;
  student?: Maybe<User>;
}

export interface UpdateUserInput {
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
}

export interface User {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
}

export interface UserProfile {
  __typename?: 'UserProfile';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Role>>>;
}

export interface UsersConnection {
  __typename?: 'UsersConnection';
  nodes?: Maybe<Array<Maybe<User>>>;
  pageInfo: PageInfo;
}

export interface StudentExamsConnectionByStudentIdPayload {
  __typename?: 'studentExamsConnectionByStudentIdPayload';
  nodes?: Maybe<Array<Maybe<StudentHistoryByExam>>>;
  pageInfo: PageInfo;
}
