/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useState, useEffect } from 'react';
import { Box, Container } from '@mui/material';
import QuestionBankHeader from '../../../components/admin/questionBank/QuestionBankHeader';
import QuestionBankTable from '../../../components/admin/questionBank/QuestionBankTable';
import { QuestionInterface } from './QuestionInterface';
import { FilterInterface } from '../../../components/filters/filtersInterface';
import {
  SearchQuestionsQuery,
  useSearchQuestionsQuery,
} from '../../../graphql/queries/admin/questions/searchQuestions.generated';
import LoadingPage from '../../../components/states/LoadingPage';
import ErrorPage from '../../../components/states/ErrorPage';

function QuestionsPage() {
  const [page, setPage] = useState(1);

  const [searchValue, setSearchValue] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [dataType, setDataType] = useState('');

  const [data, setData] = useState<any>();
  const [questionsData, setQuestionData] = useState<QuestionInterface[]>([]);

  const [noMatchFound, setNoMatchFound] = useState<boolean>(false);

  const {
    data: searchResults,
    loading: searchLoading,
    error: searchError,
    fetchMore,
  } = useSearchQuestionsQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      first: 10,
      last: null,
      after: null,
      before: null,
      keywords: searchValue,
      filters: filterValue,
    },
    pollInterval: 2000,
  });

  const endCursor = searchResults?.searchQuestions?.pageInfo.endCursor;
  const startCursor = searchResults?.searchQuestions?.pageInfo.startCursor;
  const hasPrevPage = searchResults?.searchQuestions?.pageInfo.hasPreviousPage;
  const hasNextPage = searchResults?.searchQuestions?.pageInfo.hasNextPage;

  const moreResults = () => {
    fetchMore({
      variables: {
        first: 10,
        last: null,
        after: endCursor,
        before: null,
      },
    });
  };

  const prevResults = () => {
    fetchMore({
      variables: {
        first: null,
        last: 10,
        after: null,
        before: startCursor,
        keywords: searchValue,
        filters: filterValue,
      },
    });
  };

  const handleFilters = (filters: FilterInterface[]) => {
    const convert = Object.fromEntries(filters.map((o) => [o.type, o.value]));
    setFilterValue(JSON.stringify(convert));
  };

  const updateResults = () => {
    const isSearchMatch =
      Number(searchResults?.searchQuestions?.nodes?.length) > 0;

    if (isSearchMatch) {
      setData(searchResults?.searchQuestions?.nodes as SearchQuestionsQuery);
      setDataType('search');
    } else {
      setData(searchResults?.searchQuestions?.nodes as SearchQuestionsQuery);
      setDataType('question');
    }
    setNoMatchFound(!isSearchMatch);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    updateResults();
  }, [searchResults, searchLoading, searchError]);

  useEffect(() => {
    if (data && dataType === 'question') {
      const result: QuestionInterface[] = Object.values(
        data
      ) as QuestionInterface[];

      setQuestionData(result);
    }
    if (data && dataType === 'search') {
      const result: QuestionInterface[] = Object.values(
        data
      ) as QuestionInterface[];

      setQuestionData(result);
    }
  }, [data]);

  const [disableButton, setDisableButton] = useState<boolean>(!hasNextPage);

  useEffect(() => {
    if (hasNextPage) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [hasNextPage]);

  const handleClickNext = () => {
    if (hasNextPage) {
      moreResults();
    }
    setPage(page + 1);
  };

  const handleClickPrev = () => {
    if (hasPrevPage) {
      prevResults();
      setPage(page - 1);
      setDisableButton(false);
    }
  };

  useEffect(() => {
    if (!hasPrevPage && page !== 1) {
      setPage(1);
    }
  }, [searchResults]);

  // if (searchError) return <ErrorPage />;
  // if (searchLoading) return <LoadingPage />;

  return (
    <Box component="main">
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <QuestionBankHeader
          data={questionsData}
          searchValue={searchValue}
          changeFunction={handleSearchChange}
          handleClearInput={() => setSearchValue('')}
        />
        <QuestionBankTable
          questions={questionsData}
          searchValue={searchValue}
          noMatchFound={noMatchFound}
          isSearchLoading={searchLoading}
          pageNum={page}
          onNext={handleClickNext}
          onPrev={handleClickPrev}
          nextDisabled={disableButton}
          onFilter={(filters: FilterInterface[]) => handleFilters(filters)}
        />
      </Container>
    </Box>
  );
}

export default QuestionsPage;
