import { gql } from '@apollo/client';

export const DELETE_MC_QUESTION_MUTATION = gql`
  mutation DeleteMultipleChoiceQuestion(
    $input: DeleteMultipleChoiceQuestionInput!
  ) {
    deleteMultipleChoiceQuestion(input: $input) {
      id
    }
  }
`;
