import React from 'react';
import { Stack, Container, Typography } from '@mui/material';
import {
  SignInWithGoogle,
  SignOutWithGoogle,
} from '../../components/authentication/google';
import { SignInLocalForm } from '../../components/authentication/local';
import {
  RootStyle,
  ContentStyle,
} from '../../styles/styledComponents/SignInPageStyles';

const SignInPage = () => {
  return (
    <RootStyle title="SignIn">
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontSize: '32px', fontWeight: 'bold', textAlign: 'center' }}
            >
              <span style={{ color: '#539536' }}>Sign in to </span>
              <span style={{ color: '#374EA2' }}>Your Account </span>
            </Typography>
          </Stack>

          <Stack direction="column" spacing={2}>
            <SignInWithGoogle />
            {/* <SignOutWithGoogle />
            <Typography sx={{ fontSize: '24px', textAlign: 'center' }}>
              or
            </Typography>
            <SignInLocalForm /> */}
          </Stack>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

export default SignInPage;
