import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Box,
  Dialog,
  DialogContent,
  useMediaQuery,
  Link,
  Typography,
  Stack,
  Button,
} from '@mui/material';
import QuestionTypeHeader from '../common/QuestionTypeHeader';
import EditMCQModal from '../EditQuestion/MCQuestion/EditMCQModal';
import EditMSQModal from '../EditQuestion/MSQuestion/EditMSQModal';
import CustomDialog from '../../../dialog/CustomDialog';
import message from '../../../dialog/message.json';
import { QDialogProps } from './questionModalProps';
import { QuestionAnswerInterface } from '../../../../pages/admin/questionBank/QuestionInterface';
import {
  DivStyle,
  StyledQuestion,
  StyledAuthorDiv,
  QuestionLinkDiv,
} from '../../../../styles/admin/QuestionsPageStyles';
import { DELETE_MC_QUESTION_MUTATION } from '../../../../graphql/mutations/admin/multipleChoice/question/deleteMultipleChoiceQuestion';
import {
  DeleteMultipleChoiceQuestionMutation,
  DeleteMultipleChoiceQuestionMutationVariables,
} from '../../../../graphql/mutations/admin/multipleChoice/question/deleteMultipleChoiceQuestion.generated';
import {
  DeleteMultipleSelectionQuestionMutation,
  DeleteMultipleSelectionQuestionMutationVariables,
} from '../../../../graphql/mutations/admin//multipleSelection/question/deleteMultipleSelectionQuestion.generated';
import { DELETE_MS_QUESTION_MUTATION } from '../../../../graphql/mutations/admin/multipleSelection/question/deleteMultipleSelectionQuestion';
import AnswerItemDisplay from '../../../card/ExamQuestionsView/components/AnswerItemDisplay';
import { getDate } from '../../../../utils/dateConverter';
import CustomDialogTitle from '../common/CustomDialogTitle';
import {
  formatProgramText,
  formatQuestionType,
} from '../../../../utils/wordFormatter';
import Tag from '../../../common/ChipTags';

function QuestionModal({
  questionId,
  program,
  module,
  questionType,
  question,
  possibleAnswers,
  author,
  createdAt,
}: QDialogProps) {
  const matches = useMediaQuery('(max-width:600px)');

  const [openQuestion, setOpenQuestion] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleClickOpen = () => {
    setOpenQuestion(true);
  };

  const handleOpenDelete = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDelete = () => {
    setOpenDeleteModal(false);
  };

  const [deleteMCQuestion] = useMutation<
    DeleteMultipleChoiceQuestionMutation,
    DeleteMultipleChoiceQuestionMutationVariables
  >(DELETE_MC_QUESTION_MUTATION, {
    update(cache) {
      const deletedId = cache.identify({
        questionId,
        __typename: 'MultipleChoiceQuestion',
      });
      cache.evict({
        id: deletedId,
      });
      cache.gc();
    },
  });

  const handleClose = () => {
    setOpenQuestion(false);
  };

  const [deleteMSQuestion] = useMutation<
    DeleteMultipleSelectionQuestionMutation,
    DeleteMultipleSelectionQuestionMutationVariables
  >(DELETE_MS_QUESTION_MUTATION, {
    update(cache) {
      const deletedId = cache.identify({
        questionId,
        __typename: 'MultipleSelectionQuestion',
      });
      cache.evict({
        id: deletedId,
      });
      cache.gc();
    },
  });

  const handleDeleteQuestion = () => {
    setOpenDeleteModal(false);
    setOpenQuestion(false);
    if (questionType === 'MultipleChoiceQuestion') {
      setTimeout(() => {
        deleteMCQuestion({
          variables: {
            input: {
              id: questionId,
            },
          },
        });
      }, 1500);
    } else {
      setTimeout(() => {
        deleteMSQuestion({
          variables: {
            input: {
              id: questionId,
            },
          },
        });
      }, 1500);
    }
  };

  const ViewQuestion = () => {
    return (
      <>
        <CustomDialogTitle text="View Question" handleClose={handleClose} />
        <Box sx={{ height: '15px' }} />
        <DialogContent>
          <QuestionTypeHeader questionType={questionType} />
          <StyledQuestion>
            <Typography
              component="pre"
              sx={{
                fontSize: 16,
                fontWeight: 600,
                color: '#212427',
                display: 'inline',
              }}
            >
              {question}
            </Typography>
            <Tag
              label={formatProgramText(program as string)}
              mRight={1}
              mLeft={2}
            />
            <Tag label={module} mRight={1} mLeft={0} />{' '}
          </StyledQuestion>
          <Box height={20} />
          <DivStyle>
            <Stack direction="column" paddingLeft={1}>
              {possibleAnswers.map(
                (option: QuestionAnswerInterface, index: number) => (
                  <AnswerItemDisplay
                    key={option.id as string}
                    optionId={option?.id as string}
                    questionType={questionType}
                    textValue={option.description ?? ''}
                    testId={`possible-answer-${index + 1}`}
                    isCorrectAnswer={option.isCorrect as boolean}
                  />
                )
              )}
            </Stack>
          </DivStyle>
          <StyledAuthorDiv>
            <Stack direction="row" justifyContent="space-between">
              <Typography
                sx={{ fontSize: 12, color: '#9F9F9F' }}
                data-testid="created-by"
              >
                Created by {author}
              </Typography>
              <Typography
                sx={{ fontSize: 12, color: '#9F9F9F' }}
                data-testid="date-created"
              >
                {getDate(createdAt)}
              </Typography>
            </Stack>
          </StyledAuthorDiv>
          <DivStyle>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="baseline"
              spacing={12}
            >
              <Box>
                <Button
                  disableElevation
                  variant="contained"
                  color="error"
                  onClick={handleOpenDelete}
                  sx={{
                    color: '#FFFFFF',
                    textTransform: 'none',
                    boxShadow: 0,
                  }}
                >
                  Delete
                </Button>
              </Box>
              {questionType === 'MultipleChoiceQuestion' ? (
                <EditMCQModal
                  program={program}
                  module={module}
                  questionId={questionId}
                  possibleAnswers={possibleAnswers}
                  questionType={questionType}
                  question={question}
                />
              ) : (
                <EditMSQModal
                  program={program}
                  module={module}
                  questionId={questionId}
                  possibleAnswers={possibleAnswers}
                  questionType={questionType}
                  question={question}
                />
              )}
            </Stack>
          </DivStyle>
        </DialogContent>
        <CustomDialog
          isOpen={openDeleteModal}
          handleClose={handleCloseDelete}
          handleConfirm={handleDeleteQuestion}
          title={message.deleteQuestion.title}
          content={message.deleteQuestion.content}
        />
      </>
    );
  };

  return (
    <>
      <QuestionLinkDiv>
        <Link
          component="button"
          variant="body2"
          onClick={handleClickOpen}
          underline="hover"
          color="#212427"
          sx={{ textAlign: 'start', paddingBottom: '5px' }}
          data-testid="question"
        >
          {question}
        </Link>
        <Tag label={formatQuestionType(questionType)} mRight={1} mLeft={2} />
        <Tag
          label={formatProgramText(program as string)}
          mRight={1}
          mLeft={0}
        />
        <Tag label={module} mRight={1} mLeft={0} />
      </QuestionLinkDiv>

      <Dialog
        open={openQuestion}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        fullScreen={matches}
      >
        <ViewQuestion />
      </Dialog>
    </>
  );
}

export default QuestionModal;

QuestionModal.propTypes = {
  possibleAnswers: PropTypes.array,
};
