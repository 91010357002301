//for question type

export const formatQuestionType = (word: string) => {
  word = word.replace(/([A-Z])/g, ' $1').trim();
  return word.replace(' Question', '');
};

export const formatProgramText = (program: string) => {
  return (
    program.charAt(0).toUpperCase() +
    program.slice(1).split('_').join(' ').toLowerCase()
  );
};
