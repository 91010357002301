import * as Types from '../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SubscriptionStatusQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SubscriptionStatusQuery = { __typename?: 'Query', subscriptionStatus?: { __typename?: 'SubscriptionStatusPayload', status?: Types.Status | null, examLink?: string | null } | null };


export const SubscriptionStatusDocument = gql`
    query SubscriptionStatus {
  subscriptionStatus {
    status
    examLink
  }
}
    `;

/**
 * __useSubscriptionStatusQuery__
 *
 * To run a query within a React component, call `useSubscriptionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionStatusQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionStatusQuery, SubscriptionStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionStatusQuery, SubscriptionStatusQueryVariables>(SubscriptionStatusDocument, options);
      }
export function useSubscriptionStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionStatusQuery, SubscriptionStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionStatusQuery, SubscriptionStatusQueryVariables>(SubscriptionStatusDocument, options);
        }
export type SubscriptionStatusQueryHookResult = ReturnType<typeof useSubscriptionStatusQuery>;
export type SubscriptionStatusLazyQueryHookResult = ReturnType<typeof useSubscriptionStatusLazyQuery>;
export type SubscriptionStatusQueryResult = Apollo.QueryResult<SubscriptionStatusQuery, SubscriptionStatusQueryVariables>;