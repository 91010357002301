/* eslint-disable @typescript-eslint/no-unsafe-return */
import React from 'react';
import { QuestionComponentProps } from '../viewExamComponentsProps';
import MCQuestionComponent from './MCQuestionComponent';
import MSQuestionComponent from './MSQuestionComponent';

function QuestionComponent({
  id,
  type,
  studentAnswer,
}: QuestionComponentProps) {
  const getStudentAnswer = () => {
    if (studentAnswer) {
      if (studentAnswer?.option === '') return 'empty';
      return studentAnswer.option || studentAnswer.choices;
    }
  };

  switch (type) {
    case 'MultipleChoiceQuestion':
      return <MCQuestionComponent id={id} studentAnswer={getStudentAnswer()} />;
    case 'MultipleSelectionQuestion':
      return <MSQuestionComponent id={id} studentAnswer={getStudentAnswer()} />;
    default:
      return null;
  }
}

export default QuestionComponent;
