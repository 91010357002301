import React from 'react';
import { Typography } from '@mui/material';
import { ExamDescriptionProps } from './viewExamComponentsProps';

function ExamDescription({ content }: ExamDescriptionProps) {
  return (
    <Typography
      color="secondary.main"
      fontWeight="fontWeightBold"
      sx={{ fontSize: 16 }}
    >
      {content}
    </Typography>
  );
}

export default ExamDescription;
