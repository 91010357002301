import React, { useState } from 'react';
import { TableRow, Typography, Link, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { StyledTableCell } from '../../../styles/admin/CandidateExamDetailsPageStyles';
import Tag from '../../common/ChipTags';
import { StudentExamsListInterface } from '../../student/examList/studentExamsInterface';

interface CandidateDetailsRowPropsInterface {
  exam: StudentExamsListInterface[];
}

const CandidateDetailsRow = (props: CandidateDetailsRowPropsInterface) => {
  const { exam } = props;
  const [withMultipleAttempts, _] = useState(exam.length > 1);

  const [openAttempts, setOpenAttempts] = useState(false);

  return (
    <>
      {exam.map((examAttempts, index) => (
        <TableRow
          selected={openAttempts && index > 0}
          sx={{
            visibility: index === 0 || openAttempts ? 'visible' : 'collapse',
            background: openAttempts ? '#374EA230' : '',
            // '&:hover': {
            //   background: '#53953625',
            // },
            height: '48px',
          }}
          key={examAttempts.id}
          onClick={() => {
            // setOpenAttempts(!openAttempts);
          }}
          hover={(index === 0 && !openAttempts) || (index > 0 && openAttempts)}
        >
          {index > 0 ? (
            <StyledTableCell align="left"></StyledTableCell>
          ) : (
            <StyledTableCell align="left">
              {examAttempts.examDetails.title}
            </StyledTableCell>
          )}
          <StyledTableCell
            align="center"
            padding="none"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '150px',
            }}
          >
            {examAttempts.examDetails.description}
          </StyledTableCell>
          <StyledTableCell align="center">
            {exam.length - index}
          </StyledTableCell>
          <StyledTableCell align="center">
            <Typography
              sx={{
                fontSize: '12px',
                color: '#808080',
              }}
            >
              <span
                style={{
                  fontSize: 14,
                  color: '#000000',
                }}
              >
                {Math.round(examAttempts.score * 100) / 100}
              </span>
              /{examAttempts.examDetails.maxQuestionCount}
            </Typography>
          </StyledTableCell>
          <StyledTableCell align="center">
            {examAttempts.passed != null ? (
              examAttempts.passed ? (
                <Tag minWidth="60px" label="Passed" bg="#BDE7BD" />
              ) : (
                <Tag minWidth="60px" label="Failed" bg="#FF9997" />
              )
            ) : null}
          </StyledTableCell>
          <StyledTableCell align="center" padding="none">
            {examAttempts.submittedAt
              ? new Date(examAttempts.submittedAt).toLocaleString()
              : 'In Progress'}
          </StyledTableCell>

          <StyledTableCell align="center" padding="none">
            {examAttempts.passed ? (
              <Link
                rel="noreferrer"
                href={`${window.location.origin}/certificate/view/${examAttempts.id}`}
                target="_blank"
                underline="hover"
              >
                view certificate
              </Link>
            ) : (
              <></>
            )}
          </StyledTableCell>
          {withMultipleAttempts && index === 0 ? (
            <StyledTableCell align="center" padding="none">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenAttempts(!openAttempts);
                }}
              >
                {openAttempts ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            </StyledTableCell>
          ) : (
            <StyledTableCell
              align="center"
              sx={{
                padding: 0,
              }}
            ></StyledTableCell>
          )}
        </TableRow>
      ))}
    </>
  );
};

export default CandidateDetailsRow;
