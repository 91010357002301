import { Typography } from '@mui/material';
import styled from '@mui/styles/styled';

export const ExamHeaderDiv = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const ExamDetailsDiv = styled('div')(() => ({
  width: '30%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const ExamItemsDiv = styled('div')(() => ({
  width: '30%',
  display: 'flex',
  alignItems: 'center',
}));

export const ExamDetailsTypography = styled(Typography)(() => ({
  fontSize: 15,
  marginBottom: 5,
  color: '#9F9F9F',
}));

export const ExamDetailsTitleTypography = styled(Typography)(() => ({
  fontSize: 13,
  marginBottom: 5,
  color: '#374EA2',
}));

export const ExamReturnButton = styled('div')(() => ({
  display: 'flex',
  alignItems: 'start',
  paddingTop: '10px',
  paddingLeft: '30px',
}));

export const DivMaxQuestionStyle = styled('div')(() => ({
  textAlign: 'start',
  alignItems: 'center',
  paddingBottom: '25px',
  paddingLeft: '25px',
  paddingRight: '60px',
}));

export const DivPublishMax = styled('div')(() => ({
  position: 'absolute',
  right: '120px',
  cursor: 'pointer',
  fontWeight: '500',
}));
