import { gql } from '@apollo/client';

const REMOVE_QUESTION_ON_EXAM_MUTATION = gql`
  mutation RemoveQuestionOnExam($input: QuestionOnExamInput!) {
    removeQuestionOnExam(input: $input) {
      examId
      questionId
    }
  }
`;

export default REMOVE_QUESTION_ON_EXAM_MUTATION;
