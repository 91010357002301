import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { StyledDialogContent } from '../../../styles/admin/QuestionsPageStyles';
import { StyledDialogButtonBox } from '../../../styles/styledComponents/CustomDialogStyles';
import { LoadingButton } from '@mui/lab';
import { useUpdateUserNameMutation } from '../../../graphql/mutations/admin/user/updateUserName.generated';

export interface CandidateDeleteDialogProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  candidateFirstName: string;
  candidateLastName: string;
  userId: string;
}

function CandidateDeleteDialog({
  isOpen,
  setOpen,
  candidateFirstName,
  candidateLastName,
  userId,
}: CandidateDeleteDialogProps) {
  const matches = useMediaQuery('(min-width:600px)');
  const [firstName, setFirstName] = useState(candidateFirstName);
  const [lastName, setLastName] = useState(candidateLastName);

  const [updateCandidateName, { loading }] = useUpdateUserNameMutation();

  const handleConfirmUpdate = async () => {
    await updateCandidateName({
      variables: {
        input: {
          id: userId,
          firstName,
          lastName,
        },
      },
      onCompleted: () => {
        setOpen(false);
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
    setFirstName(candidateFirstName);
    setLastName(candidateLastName);
  };

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogContent>
          <StyledDialogContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography sx={{ fontSize: matches ? 18 : 14, fontWeight: 600 }}>
                Update Candidate Name
              </Typography>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ marginTop: '12px' }}>
              <Box
                sx={{
                  marginTop: '12px',
                  marginY: '4px',
                  width: '100%',
                  display: 'flex',
                  gap: '16px',
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: matches ? 14 : 12,
                      fontWeight: 'bold',
                    }}
                  >
                    First Name
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={handleFirstNameChange}
                    value={firstName}
                    size="small"
                    sx={{ maxWidth: 400 }}
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: matches ? 14 : 12,
                      fontWeight: 'bold',
                    }}
                  >
                    Last Name
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="text"
                    onChange={handleLastNameChange}
                    value={lastName}
                    size="small"
                    sx={{ maxWidth: 400 }}
                  />
                </Box>
              </Box>
            </Box>
          </StyledDialogContent>
          <StyledDialogButtonBox>
            <Button
              onClick={handleClose}
              sx={{
                marginRight: '15px',
                textTransform: 'none',
                boxShadow: 0,
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              disableElevation
              loading={loading}
              variant="contained"
              onClick={() => {
                handleConfirmUpdate();
              }}
              data-testid="confirm-button"
              autoFocus
              sx={{
                color: '#FFFFFF',
                textTransform: 'none',
                boxShadow: 0,
              }}
            >
              Save
            </LoadingButton>
          </StyledDialogButtonBox>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CandidateDeleteDialog;
