import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import theme from '../themes/theme';
import PublicRoute from '../routes/PublicRoute';
import LandingPage from '../pages/landingPage/LandingPage';
import SignInPage from '../pages/SignInPage/SignInPage';
import DashboardPage from '../pages/admin/dashboard/DashboardPage';
import QuestionsPage from '../pages/admin/questionBank/QuestionsPage';
import ExamsPage from '../pages/admin/examBank/ExamsPage';
import ViewExamDataPage from '../pages/admin/viewExam/ViewExamDataPage';
import ExamCoverPage from '../pages/student/exam/ExamCoverPage';
import EditExamDataPage from '../pages/admin/editExam/EditExamDataPage';
import ViewStudentExamDataPage from '../pages/admin/viewStudentExam/ViewStudentExamDataPage';
import HarassmentPolicyPage from '../pages/resourcesPages/HarassmentPolicyPage';
import HonourCodePage from '../pages/resourcesPages/HonourCodePage';
import NonDiscriminationStatementPage from '../pages/resourcesPages/NonDiscriminationStatementPage';
import PrivacyPolicyPage from '../pages/resourcesPages/PrivacyPolicyPage';
import TermsOfServicePage from '../pages/resourcesPages/TermsOfServicePage';
import ExamData from '../pages/student/exam/ExamData';
import ExamScorePage from '../pages/student/exam/examScore/ExamScorePage';
import ProtectedAdminRoute from './ProtectedAdminRoute';
import ProtectedStudentRoute from './ProtectedStudentRoute';
import StudentDashboardPage from '../pages/student/StudentDashboardPage';
import AdminManagementPage from '../pages/admin/adminManagement/AdminManagementPage';
import ExamCertificate from '../pages/student/exam/examCertificate/ExamCertificatePage';
import CandidateManagementPage from '../pages/admin/candidateManagement/CandidateManagementPage';
import CandidateDetailsPage from '../pages/admin/candidateManagement/CandidateExamDetailsPage';

function AppRoutes() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route element={<ProtectedAdminRoute allowedRole="ADMIN" />}>
            <Route path="/admindashboard" element={<DashboardPage />} />
            <Route
              path="/candidate-management"
              element={<CandidateManagementPage />}
            />
            <Route
              path="/candidate-details/:id"
              element={<CandidateDetailsPage />}
            />
            <Route path="/questionbank" element={<QuestionsPage />} />
            <Route path="/adminmanagement" element={<AdminManagementPage />} />
            <Route path="/exambank" element={<ExamsPage />} />
            <Route path="/exam/:id" element={<ViewExamDataPage />} />
            <Route path="/exam/edit/:id" element={<EditExamDataPage />} />
            <Route
              path="/viewexam/:studId/:examId"
              element={<ViewStudentExamDataPage />}
            />
          </Route>
          <Route element={<ProtectedStudentRoute allowedRole="STUDENT" />}>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/candidatedashboard"
              element={<StudentDashboardPage />}
            />

            <Route
              path="/exams"
              element={<div> this is the list of exams</div>}
            />
            <Route path="/exam/cover/:examId" element={<ExamCoverPage />} />
            <Route path="/take/:examId/:authToken" element={<ExamData />} />
            <Route
              path="/take/:examId/:authToken/score"
              element={<ExamScorePage />}
            />
          </Route>
          <Route element={<PublicRoute role="ADMIN" />}>
            <Route path="/" element={<LandingPage />} />
            <Route path="/signin" element={<SignInPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/terms-of-service" element={<TermsOfServicePage />} />
          </Route>
          <Route
            path="/certificate/view/:studentExamId"
            element={<ExamCertificate />}
          />
        </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default AppRoutes;
