import React from 'react';
import { Grid } from '@mui/material';
import LoaderImage from '../../styles/styledComponents/StatesStyles';
import Loader from '../../assets/merkle_trees_loader.gif';

const LoadingPage = () => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <LoaderImage src={Loader} alt="loader" />
    </Grid>
  );
};

export default LoadingPage;
