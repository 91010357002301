export const getDate = (timestamp: Date) => {
  const rawDate = new Date(timestamp);
  return rawDate.toLocaleDateString('en-US', {
    day: 'numeric',
    year: 'numeric',
    month: 'long',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
};

export const getExamDateAndTime = (timestamp: number) => {
  const rawDate = new Date(timestamp);
  const date = rawDate.toLocaleDateString('en-US', {
    day: 'numeric',
    year: 'numeric',
    month: 'long',
  });

  const time = rawDate.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  });

  return `${date} at ${time}`;
};

export const mergeDateTime = (date: Date, time: Date) => {
  if ((date && time) !== null) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      time.getHours(),
      time.getMinutes(),
      time.getSeconds()
    ).toISOString();
  } else {
    return null;
  }
};
