import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Skeleton,
  Stack,
} from '@mui/material';
import { StyledTableCell } from '../../../styles/admin/ExamsListStyles';

const TableSkeletonRow = () => {
  return (
    <TableRow>
      <TableCell sx={{ py: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Skeleton
              animation="wave"
              variant="circular"
              width={20}
              height={20}
            />
            <Skeleton animation="wave" width={150} height={30} />
            <Skeleton animation="wave" width={50} height={30} />
            <Skeleton animation="wave" width={50} height={30} />
          </Stack>
          <Skeleton
            animation="wave"
            variant="circular"
            width={20}
            height={20}
          />
        </Stack>
      </TableCell>
    </TableRow>
  );
};

const TableLoadingState = () => {
  return (
    <Paper sx={{ boxShadow: 'none' }}>
      <TableContainer sx={{ marginTop: 2 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell> Exams</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: '#ffffff' }}>
            <TableSkeletonRow />
            <TableSkeletonRow />
            <TableSkeletonRow />
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableLoadingState;
