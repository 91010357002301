import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StudentExamDetailsQueryVariables = Types.Exact<{
  examId: Types.Scalars['String'];
}>;


export type StudentExamDetailsQuery = { __typename?: 'Query', exam?: { __typename?: 'Exam', id?: string | null, title?: string | null, description?: string | null, program?: Types.Program | null, module?: string | null, year?: number | null, quarter?: Types.Quarter | null, maxQuestionCount?: number | null, createdAt?: any | null, updatedAt?: any | null, createdById?: string | null, startDate?: any | null, endDate?: any | null, duration?: number | null, examType?: Types.ExamType | null, passingRate?: number | null } | null };


export const StudentExamDetailsDocument = gql`
    query StudentExamDetails($examId: String!) {
  exam(id: $examId) {
    id
    title
    description
    program
    module
    year
    quarter
    maxQuestionCount
    createdAt
    updatedAt
    createdById
    startDate
    endDate
    duration
    examType
    passingRate
  }
}
    `;

/**
 * __useStudentExamDetailsQuery__
 *
 * To run a query within a React component, call `useStudentExamDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentExamDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentExamDetailsQuery({
 *   variables: {
 *      examId: // value for 'examId'
 *   },
 * });
 */
export function useStudentExamDetailsQuery(baseOptions: Apollo.QueryHookOptions<StudentExamDetailsQuery, StudentExamDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentExamDetailsQuery, StudentExamDetailsQueryVariables>(StudentExamDetailsDocument, options);
      }
export function useStudentExamDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentExamDetailsQuery, StudentExamDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentExamDetailsQuery, StudentExamDetailsQueryVariables>(StudentExamDetailsDocument, options);
        }
export type StudentExamDetailsQueryHookResult = ReturnType<typeof useStudentExamDetailsQuery>;
export type StudentExamDetailsLazyQueryHookResult = ReturnType<typeof useStudentExamDetailsLazyQuery>;
export type StudentExamDetailsQueryResult = Apollo.QueryResult<StudentExamDetailsQuery, StudentExamDetailsQueryVariables>;