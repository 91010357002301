import React from 'react';
import { Typography, Link } from '@mui/material';
import footerLogo from '../../assets/merkle_trees_blue.png';
import {
  BoxStyle,
  FooterDivStyle,
  LogoStyle,
} from '../../styles/styledComponents/FooterStyles';

const Copyright = () => {
  return (
    <Typography variant="body2" color="text.secondary">
      {'Copyright © '}
      <Link
        color="inherit"
        href="https://kingslanduniversity.com/"
        sx={{ textDecoration: 'none' }}
      >
        Merkle Trees
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
};

function Footer() {
  return (
    <FooterDivStyle>
      <BoxStyle>
        <LogoStyle src={footerLogo} alt="logo" />
      </BoxStyle>
      <BoxStyle>
        <Copyright />
      </BoxStyle>
    </FooterDivStyle>
  );
}

export default Footer;
