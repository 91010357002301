import React from 'react';
import { useParams } from 'react-router-dom';
import ErrorPage from '../../../components/states/ErrorPage';
import LoadingPage from '../../../components/states/LoadingPage';
import { useExamQuery } from '../../../graphql/queries/admin/exam/getExam.generated';
import { AllQuestions, ExamDetails } from './editExamProps';
import EditExamPage from './EditExamPage';
import { useQuestionsQuery } from '../../../graphql/queries/admin/questions/getQuestions.generated';

function EditExamDataPage() {
  const params = useParams();
  const examId = String(params.id);

  const {
    data: examDetails,
    loading: examDetailsLoading,
    error: examDetailsError,
  } = useExamQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      examId: examId,
    },
    pollInterval: 2000,
  });

  const {
    data: questionsData,
    loading: questionsDataLoading,
    error: questionsDataError,
  } = useQuestionsQuery({
    variables: {
      program: examDetails?.exam?.program,
      module: examDetails?.exam?.module as string,
    },
    pollInterval: 2000,
  });

  if (examDetailsLoading || questionsDataLoading) return <LoadingPage />;
  if (examDetailsError || questionsDataError)
    return (
      <ErrorPage
        errorMessage={
          examDetailsError
            ? examDetailsError.message
            : questionsDataError?.message
        }
      />
    );

  const exam = examDetails?.exam as ExamDetails;
  const questions = questionsData?.questions as AllQuestions[];

  return <EditExamPage questions={questions} exam={exam} />;
}

export default EditExamDataPage;
