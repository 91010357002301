import React from 'react';
import { Typography, Grid } from '@mui/material';
import { DashboardContainer } from '../../../styles/admin/DashboardStyles';
import AdminsList from '../../../components/admin/adminManagement/AdminsList';

const AdminManagementPage = () => {
  return (
    <DashboardContainer>
      <Typography
        color="secondary.main"
        variant="h5"
        fontWeight="fontWeightBold"
        sx={{ width: '100%' }}
      >
        Admin Management
      </Typography>
      <Grid container spacing={3} direction="row">
        <AdminsList />
      </Grid>
    </DashboardContainer>
  );
};

export default AdminManagementPage;
