import React from 'react';
import { Popover, Typography, Button } from '@mui/material';
import { AccountPaper } from '../../../styles/styledComponents/SideNavigationStyles';

const AccountPopover = (props: {
  userName: string;
  userType: string;
  isOpen: boolean;
  anchorEl: HTMLDivElement | null;
  handleClose: () => void;
  handleSignout: () => void;
}) => {
  return (
    <Popover
      open={props.isOpen}
      anchorEl={props.anchorEl}
      onClose={props.handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{ marginLeft: 2 }}
    >
      <AccountPaper>
        <Typography
          fontWeight="fontWeightBold"
          variant="body1"
          data-testid="account-name"
        >
          {props.userName}
        </Typography>
        <Typography sx={{ marginBottom: 1, fontSize: 14 }}>
          {props.userType}
        </Typography>
        <Button
          data-testid="signout-button"
          variant="contained"
          size="small"
          sx={{ textTransform: 'none', boxShadow: 0 }}
          onClick={props.handleSignout}
        >
          Sign out
        </Button>
      </AccountPaper>
    </Popover>
  );
};

export default AccountPopover;
