/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';
import {
  DeleteAdminMutation,
  DeleteAdminMutationVariables,
} from '../../../../graphql/mutations/admin/admin/deleteAdmin.generated';
import CustomAlert from '../../../alert/CustomAlert';
import DELETE_ADMIN_MUTATION from '../../../../graphql/mutations/admin/admin/deleteAdmin';
import { AdminsInterface } from '../../../admin/adminManagement/AdminsList';
import { RefetchContext } from '../../../../components/admin/adminManagement/adminManagementContext';

interface deleteModalProps {
  admin: AdminsInterface;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const DeleteAdminModal = (props: deleteModalProps) => {
  const { id, email } = props.admin;
  const { open, setOpen } = props;
  const refetchAdminsList = useContext(RefetchContext);
  const [openSuccessAlert, setOpenSuccessAlert] = useState(false);

  const [deleteAdmin] = useMutation<
    DeleteAdminMutation,
    DeleteAdminMutationVariables
  >(DELETE_ADMIN_MUTATION, {
    variables: {
      input: {
        id: id,
      },
    },
    onCompleted: () => {
      setOpen(false);
      setOpenSuccessAlert(true);
      refetchAdminsList();
    },
  });

  const handleClose = () => setOpen(false);
  const handleDelete = () => {
    deleteAdmin();
  };

  return (
    <>
      <CustomAlert
        message="Admin has been removed!"
        open={openSuccessAlert}
        handleClose={() => setOpenSuccessAlert(false)}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: 20,
            background: 'whitesmoke',
            boxShadow: 24,
            borderRadius: '5px',
            p: 3,
            maxWidth: '500px',
          }}
        >
          <Grid container spacing={0} direction="column" alignItems="flex-end">
            <Grid item justifyContent="flex-end">
              <Typography
                id="modal-modal-title"
                sx={{
                  mb: 1,
                  color: '#444444',
                  fontWeight: 'medium',
                  fontSize: '24px',
                }}
              >
                Are you sure you want to remove this admin?
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{
                  mb: 4,
                  color: '#808080',
                  fontWeight: 'regular',
                  fontSize: '14px',
                }}
              >
                <strong>{email}</strong> will be removed permanently. This
                action cannot be undone. Do you still want to proceed?
              </Typography>
            </Grid>

            <Grid
              item
              justifyContent="flex-end"
              sx={{
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#a9a9a9',
                  fontSize: '12px',
                  '&:hover': {
                    backgroundColor: '#949494',
                  },
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  fontSize: '12px',
                  marginLeft: '12px',
                }}
                onClick={handleDelete}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteAdminModal;
