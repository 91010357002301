import React from 'react';
import { Stack } from '@mui/material';
import { useGetMultipleSelectionQuestionQuery } from '../../../../graphql/queries/admin/multipleSelection/getMultipleSelectionQuestion.generated';
import { QuestionAnswerInterface } from '../../../../pages/admin/questionBank/QuestionInterface';
import { QuestionComponentDetailsProps } from '../viewExamComponentsProps';
import AnswerItemDisplay from '../../../card/ExamQuestionsView/components/AnswerItemDisplay';
import QuestionComponent from './QuestionComponentDetails';

function MSQuestionComponent({
  id,
  studentAnswer,
}: QuestionComponentDetailsProps) {
  const { data, loading, error } = useGetMultipleSelectionQuestionQuery({
    variables: {
      id: id as string,
    },
    pollInterval: 2000,
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error...</div>;

  const question: string = data?.multipleSelectionQuestion?.question as string;
  const questionType =
    data?.multipleSelectionQuestion?.__typename ?? 'MultipleSelectionQuestion';
  const choices: QuestionAnswerInterface[] = data?.multipleSelectionQuestion
    ?.choices as QuestionAnswerInterface[];

  return (
    <>
      <QuestionComponent
        questionContent={question}
        details={
          <Stack direction="column" paddingLeft={3.6} paddingRight={3.6}>
            {choices?.map((option, index) => (
              <AnswerItemDisplay
                key={option?.id as string}
                optionId={option?.id as string}
                questionType={questionType}
                textValue={option?.description ?? ''}
                testId={`possible-answer-${index + 1}`}
                isCorrectAnswer={option?.isCorrect as boolean}
                studentAnswer={studentAnswer}
              />
            ))}
          </Stack>
        }
      />
    </>
  );
}

export default MSQuestionComponent;
