import { gql } from '@apollo/client';

const UPDATE_ANSWERS_MUTATION = gql`
  mutation UpdateStudentExamAnswers($input: UpdateStudentExamAnswersInput!) {
    updateStudentExamAnswers(input: $input) {
      id
      openedAt
      examDetails {
        id
      }
      student {
        id
      }
    }
  }
`;

export default UPDATE_ANSWERS_MUTATION;
