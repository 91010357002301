import { gql } from '@apollo/client';

export const CREATE_MS_CHOICE_MUTATION = gql`
  mutation CreateMultipleSelectionChoice($input: CreateChoiceInput!) {
    createMultipleSelectionChoice(input: $input) {
      id
      description
      isCorrect
    }
  }
`;
