import { gql } from '@apollo/client';

const CREATE_EXAM_MUTATION = gql`
  mutation CreateExam($input: CreateExamInput!) {
    createExam(input: $input) {
      id
      title
      description
      program
      module
      year
      quarter
      isPublished
      startDate
      endDate
      createdAt
      updatedAt
      passingRate
    }
  }
`;

export default CREATE_EXAM_MUTATION;
