/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState, useEffect } from 'react';
import ErrorPage from '../../../components/states/ErrorPage';
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
  StyledTableCell,
  DashboardContainer,
} from '../../../styles/admin/CandidateExamDetailsPageStyles';
import { useGetStudentExamsByStudentIdQuery } from '../../../graphql/queries/student/exam/getPaginatedExamsByStudentId.generated';
import { StudentExamsListInterface } from '../../../components/student/examList/studentExamsInterface';
import CandidateDetailsRow from '../../../components/admin/candidateManagement/CandidateDetailsRow';
import EmptyDataComponent from '../../../components/states/componentStates/EmptyDataComponent';
import TablePagination from '../../../components/common/TablePagination';

export interface StudentExamsAttemptHistoryInterface {
  studentExams: StudentExamsListInterface[];
}
const CandidateDetailsPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const studentId = String(params.id);

  const { data, loading, error, fetchMore } =
    useGetStudentExamsByStudentIdQuery({
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      variables: {
        studentId,
        first: 10,
        last: null,
        after: null,
        before: null,
      },
    });
  const [page, setPage] = useState(1);

  const endCursor = data?.studentExamsConnectionByStudentId?.pageInfo.endCursor;
  const startCursor =
    data?.studentExamsConnectionByStudentId?.pageInfo.startCursor;
  const hasPrevPage =
    data?.studentExamsConnectionByStudentId?.pageInfo.hasPreviousPage;
  const hasNextPage =
    data?.studentExamsConnectionByStudentId?.pageInfo.hasNextPage;

  const moreResults = () => {
    fetchMore({
      variables: {
        first: 10,
        last: null,
        after: endCursor,
        before: null,
      },
    });
  };

  const prevResults = () => {
    fetchMore({
      variables: {
        first: null,
        last: 10,
        after: null,
        before: startCursor,
      },
    });
  };

  const [disableButton, setDisableButton] = useState<boolean>(!hasNextPage);

  useEffect(() => {
    if (hasNextPage) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [hasNextPage]);

  const handleClickNext = () => {
    if (hasNextPage) {
      moreResults();
    }
    setPage(page + 1);
  };

  const handleClickPrev = () => {
    if (hasPrevPage) {
      prevResults();
      setPage(page - 1);
      setDisableButton(false);
    }
  };

  useEffect(() => {
    if (!hasPrevPage && page !== 1) {
      setPage(1);
    }
  }, [data]);

  if (loading) return <></>;
  if (error) return <ErrorPage errorMessage={error.message} />;

  const exams: StudentExamsAttemptHistoryInterface[] = data
    ?.studentExamsConnectionByStudentId
    ?.nodes as unknown as StudentExamsAttemptHistoryInterface[];

  return (
    <DashboardContainer>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            color="secondary.main"
            variant="h5"
            fontWeight="fontWeightBold"
          >
            Candidate Details
          </Typography>
          <Box
            sx={{
              marginTop: 2,
            }}
          >
            <Button
              color="secondary"
              startIcon={<ArrowBackIosNewIcon />}
              sx={{ textTransform: 'none' }}
              onClick={() => navigate(-1)}
            >
              Return
            </Button>
          </Box>
          <Paper
            sx={{
              boxShadow: 'none',
            }}
          >
            <TableContainer
              sx={{
                marginTop: 1,
                borderRadius: '4px',
              }}
            >
              <Table
                size="small"
                stickyHeader
                sx={{ width: '100%', maxHeight: '50vh' }}
              >
                {exams.length > 0 ? (
                  <>
                    <TableHead sx={{ backgroundColor: '#374EA2' }}>
                      <TableRow
                        sx={{
                          backgroundColor: 'transparent',
                        }}
                      >
                        <StyledTableCell align="left">Title</StyledTableCell>
                        <StyledTableCell align="center">
                          Description
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Attempt no.
                        </StyledTableCell>
                        <StyledTableCell align="center">Score</StyledTableCell>
                        <StyledTableCell align="center">
                          Remarks
                        </StyledTableCell>
                        <StyledTableCell align="center" padding="none">
                          Submitted Date
                        </StyledTableCell>
                        <StyledTableCell align="center" padding="none">
                          Certificate
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          sx={{ paddingLeft: 0 }}
                        ></StyledTableCell>
                      </TableRow>
                    </TableHead>
                  </>
                ) : (
                  <EmptyDataComponent message="This candidate has no exam history" />
                )}
                <TableBody>
                  {exams.map(
                    (exam: StudentExamsAttemptHistoryInterface, examIndex) => {
                      return (
                        <CandidateDetailsRow
                          exam={exam.studentExams}
                          key={examIndex}
                        />
                      );
                    }
                  )}
                </TableBody>
              </Table>
              <TablePagination
                pageNum={page}
                buttonDisabled={disableButton}
                handleNext={handleClickNext}
                handlePrev={handleClickPrev}
              />
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </DashboardContainer>
  );
};

export default CandidateDetailsPage;
