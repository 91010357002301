/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useContext, useState } from 'react';
import { TextField, Paper, Typography, Button, Stack } from '@mui/material';
import {
  CreateAdminMutationVariables,
  CreateAdminMutation,
} from '../../../graphql/mutations/admin/admin/createAdmin.generated';
import CREATE_ADMIN_MUTATION from '../../../graphql/mutations/admin/admin/createAdmin';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import { useMutation } from '@apollo/client';
import CustomAlert from '../../alert/CustomAlert';
import { validateEmail } from './validateEmail';
import { RefetchContext } from './adminManagementContext';

const AddEmail = () => {
  const refetchAdminsList = useContext(RefetchContext);

  const [email, setEmail] = useState('');
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');

  const [createAdmin] = useMutation<
    CreateAdminMutation,
    CreateAdminMutationVariables
  >(CREATE_ADMIN_MUTATION, {
    variables: {
      input: {
        email: email,
      },
    },
    onCompleted: () => {
      setOpen(true);
      setEmail('');
      setError('');
      refetchAdminsList();
    },
    onError: (e) => {
      if (e.message === 'Unique constraint failed') {
        setError('Email already exists in Admin list');
      }
    },
  });

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSave = () => {
    if (validateEmail(email)) {
      createAdmin();
    } else {
      setError('Email must be valid');
    }
  };

  return (
    <>
      <CustomAlert
        message="New admin has been added!"
        open={open}
        handleClose={() => setOpen(false)}
      />
      <Paper
        sx={{
          width: '300px',
          marginTop: 2,
          border: '1px solid #EAEDF0',
          boxShadow: '0px 8px 14px rgba(72, 84, 115, 0.15)',
          borderRadius: '8px',
          background: '#FFFFFF',
        }}
      >
        <Stack>
          <Stack
            direction="row"
            justifyContent="flex-start"
            sx={{
              background: '#F0F0F0',
              borderBottom: '1.5px solid #EAEDF0',
              borderRadius: '8px 8px 0px 0px',
            }}
          >
            <Typography
              sx={{
                px: '12px',
                py: '8px',
                fontSize: '14px',
                fontWeigth: 600,
                color: '#6B7280',
              }}
            >
              ADD NEW ADMIN
            </Typography>
          </Stack>

          <Stack
            spacing={3}
            direction="column"
            justifyContent="flex-start"
            sx={{
              px: '12px',
              py: '14px',
            }}
          >
            <TextField
              error={error != ''}
              helperText={error}
              required
              sx={{ marginTop: 1 }}
              placeholder="Email"
              variant="outlined"
              size="small"
              value={email}
              onChange={handleEmailChange}
            />
            <Stack direction="row" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={handleSave}
                sx={{ fontSize: '12px', backgroundColor: '#539536' }}
              >
                <PersonAddAltRoundedIcon sx={{ fontSize: '16px', mr: 1 }} />
                Save
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </>
  );
};

export default AddEmail;
