import React, { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Typography,
  Stack,
  TextField,
  MenuItem,
  Dialog,
  useMediaQuery,
  DialogContent,
} from '@mui/material';
import {
  DatePicker,
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Add } from '@mui/icons-material';
import {
  AddExamButtonContainer,
  DivAddExamStyle,
  AddExamContentStyle,
} from '../../../../styles/admin/AddExamModalStyles';
import { programs, quarters, types } from './dropdownOptionsData';
import AddExamTextField from './AddExamTextField';
import CustomDialogTitle from '../common/CustomDialogTitle';
import CREATE_EXAM_MUTATION from '../../../../graphql/mutations/admin/exam/createExam';
import {
  CreateExamMutation,
  CreateExamMutationVariables,
} from '../../../../graphql/mutations/admin/exam/createExam.generated';
import { Program, Quarter, ExamType } from '../../../../types.generated';
import { mergeDateTime } from '../../../../utils/dateConverter';

const AddExamModal = () => {
  const matches = useMediaQuery('(min-width:600px)');
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [examType, setExamType] = useState('FREE');
  const [year, setYear] = useState<Date | null>(new Date());
  const [program, setProgram] = useState('BLOCKCHAIN');
  const [module, setModule] = useState('');
  const [quarter, setQuarter] = useState('Q1');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [duration, setDuration] = useState<number | null>(null);
  const [passingRate, setPassingRate] = useState<number | null>(null);

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleExamTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setExamType(event.target.value);
  };

  const handleProgramChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProgram(event.target.value);
  };

  const handleModuleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setModule(event.target.value);
  };

  const handleQuarterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuarter(event.target.value);
  };

  const handleDurationChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDuration(Number(event.target.value));
  };

  const handlePassingRateChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassingRate(Number(event.target.value));
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const startDateTime = mergeDateTime(startDate as Date, startTime as Date);
  const endDateTime = mergeDateTime(endDate as Date, endTime as Date);

  const [createExam] = useMutation<
    CreateExamMutation,
    CreateExamMutationVariables
  >(CREATE_EXAM_MUTATION, {
    variables: {
      input: {
        title: title,
        description: description,
        program: program as Program,
        module: module,
        year: year?.getFullYear() as number,
        quarter: quarter as Quarter,
        startDate: startDateTime,
        endDate: endDateTime,
        duration: duration,
        examType: examType as ExamType,
        passingRate: passingRate,
      },
    },
    onCompleted: (data) => {
      handleClose();
      setTimeout(() => {
        navigate(`/exam/edit/${String(data?.createExam?.id)}`);
      }, 1200);
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    createExam();
  };

  return (
    <div>
      <Button
        data-testid="add-exam-button"
        variant="contained"
        disableElevation
        startIcon={<Add />}
        onClick={handleOpen}
        sx={{ textTransform: 'none', boxShadow: 0, backgroundColor: '#539536' }}
      >
        Add Exam
      </Button>
      <Dialog
        data-testid="add-exam-modal"
        open={open}
        onClose={handleClose}
        maxWidth={matches ? 'md' : 'sm'}
        fullScreen={!matches}
      >
        <CustomDialogTitle text="Add Exam" handleClose={handleClose} />
        <DialogContent sx={{ width: 600 }}>
          <Box sx={{ height: '30px' }} />
          <form onSubmit={handleSubmit}>
            <DivAddExamStyle>
              <AddExamContentStyle>
                <AddExamTextField
                  title="Exam Title"
                  placeholder="Type exam title here"
                  onChange={handleTitleChange}
                />
                <AddExamTextField
                  title="Description"
                  placeholder="Type description here"
                  onChange={handleDescriptionChange}
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                  marginY={1}
                >
                  <Typography sx={{ fontSize: 16 }}>Exam Type</Typography>
                  <TextField
                    required
                    select
                    data-testid="exam-type-textfield"
                    value={examType}
                    onChange={handleExamTypeChange}
                    defaultValue={examType}
                    sx={{ width: 400 }}
                  >
                    {types.map((type) => (
                      <MenuItem key={type.id} value={type.value}>
                        {type.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                  marginY={1}
                >
                  <Typography sx={{ fontSize: 16 }}>Program</Typography>
                  <TextField
                    required
                    select
                    data-testid="program-textfield"
                    value={program}
                    onChange={handleProgramChange}
                    defaultValue={program}
                    sx={{ width: 400 }}
                  >
                    {programs.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                <AddExamTextField
                  title="Module"
                  placeholder="Type module here"
                  onChange={handleModuleChange}
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                  marginY={1}
                >
                  <Typography sx={{ fontSize: 16 }}>Cohort</Typography>
                  <Stack direction="row" spacing={1}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        views={['year']}
                        value={year}
                        PopperProps={{ placement: 'right-start' }}
                        onChange={(newValue) => setYear(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            data-testid="year-date-picker"
                            sx={{ width: 196 }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <TextField
                      required
                      data-testid="quarter-textfield"
                      select
                      value={quarter}
                      onChange={handleQuarterChange}
                      defaultValue={quarter}
                      sx={{ width: 196 }}
                    >
                      {quarters.map((option) => (
                        <MenuItem key={option.id} value={option.title}>
                          {option.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={1}
                  marginY={1}
                >
                  <Typography sx={{ fontSize: 16 }}>Passing Rate</Typography>
                  <Stack direction="row" spacing={1} paddingLeft="19px">
                    <TextField
                      required
                      data-testid="exam-passing-rate"
                      type="number"
                      sx={{
                        width: 90,
                      }}
                      value={passingRate || ''}
                      onChange={handlePassingRateChange}
                      InputProps={{ inputProps: { min: 0 } }}
                    />
                  </Stack>
                </Stack>

                {examType === 'FIXED' && (
                  <>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                      marginY={1}
                    >
                      <Typography sx={{ fontSize: 16 }}>Start</Typography>
                      <Stack direction="row" spacing={1}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            disablePast
                            showTodayButton
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                required
                                data-testid="start-date-picker"
                                placeholder="Select Date"
                                sx={{ width: 196 }}
                              />
                            )}
                            value={startDate}
                            onChange={(newValue) => {
                              setStartDate(newValue);
                            }}
                          />
                          <MobileTimePicker
                            showTodayButton
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                required
                                data-testid="start-time-picker"
                                placeholder="Select Time"
                                sx={{ width: 196 }}
                              />
                            )}
                            value={startTime}
                            onChange={(newValue) => {
                              setStartTime(newValue);
                            }}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                      marginY={1}
                    >
                      <Typography sx={{ fontSize: 16 }}>End</Typography>
                      <Stack direction="row" spacing={1}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            minDate={startDate}
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                required
                                data-testid="end-date-picker"
                                placeholder="Select Date"
                                sx={{ width: 196 }}
                              />
                            )}
                            value={endDate}
                            onChange={(newValue) => {
                              setEndDate(newValue);
                            }}
                          />
                          <MobileTimePicker
                            showTodayButton
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                required
                                data-testid="end-time-picker"
                                placeholder="Select Time"
                                sx={{ width: 196 }}
                              />
                            )}
                            value={endTime}
                            onChange={(newValue) => {
                              setEndTime(newValue);
                            }}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Stack>
                  </>
                )}
                {examType === 'FLEXIBLE' && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={2}
                    marginY={1}
                  >
                    <Typography sx={{ fontSize: 16 }}>Duration</Typography>
                    <Stack direction="row" spacing={1} paddingLeft="44px">
                      <TextField
                        required
                        data-testid="exam-duration"
                        type="number"
                        sx={{
                          width: 90,
                        }}
                        value={duration || ''}
                        onChange={handleDurationChange}
                        InputProps={{ inputProps: { min: 0 } }}
                      />
                      <Typography
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        Minutes
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </AddExamContentStyle>
              <Box height={20} />
              <AddExamButtonContainer>
                <Button
                  data-testid="save-exam-button"
                  variant="contained"
                  type="submit"
                  disableElevation
                  sx={{ textTransform: 'none', boxShadow: 0 }}
                >
                  Save Exam
                </Button>
              </AddExamButtonContainer>
            </DivAddExamStyle>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddExamModal;
