import { gql } from '@apollo/client';

const DELETE_EXAM_MUTATION = gql`
  mutation DeleteExam($input: DeleteExamInput!) {
    deleteExam(input: $input) {
      id
    }
  }
`;

export default DELETE_EXAM_MUTATION;
