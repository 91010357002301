import React from 'react';
import { ScorePercentageChartProps } from '../submissionDetails';
import { VictoryLabel, VictoryPie } from 'victory';

function ScorePercentageChart({
  scorePercentage,
  endAngle,
}: ScorePercentageChartProps) {
  return (
    <>
      <svg width={300} height={300}>
        <VictoryPie
          animate={{
            duration: 2000,
            easing: 'circle',
          }}
          endAngle={endAngle}
          standalone={false}
          innerRadius={65}
          labelRadius={200}
          width={300}
          height={300}
          data={[
            { x: 0, y: scorePercentage },
            { x: 0, y: 100 - scorePercentage },
          ]}
          /**
           * Hide labels
           */
          labels={() => ''}
          colorScale={['#374EA2', '#EEEEEE']}
        />
        <VictoryLabel
          textAnchor="middle"
          style={{ fontSize: 28 }}
          x={150}
          y={150}
          text={`${scorePercentage}%`}
        />
      </svg>
    </>
  );
}

export default ScorePercentageChart;
