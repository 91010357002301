import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QuestionsQueryVariables = Types.Exact<{
  program?: Types.InputMaybe<Types.Program>;
  module?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type QuestionsQuery = { __typename?: 'Query', questions?: Array<{ __typename?: 'MultipleChoiceQuestion', id?: string | null, question?: string | null, program?: Types.Program | null, module?: string | null, updatedAt?: any | null, createdById?: string | null } | { __typename?: 'MultipleSelectionQuestion', id?: string | null, question?: string | null, program?: Types.Program | null, module?: string | null, updatedAt?: any | null, createdById?: string | null } | null> | null };


export const QuestionsDocument = gql`
    query Questions($program: Program, $module: String) {
  questions(program: $program, module: $module) {
    id
    question
    program
    module
    updatedAt
    createdById
  }
}
    `;

/**
 * __useQuestionsQuery__
 *
 * To run a query within a React component, call `useQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionsQuery({
 *   variables: {
 *      program: // value for 'program'
 *      module: // value for 'module'
 *   },
 * });
 */
export function useQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
      }
export function useQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
        }
export type QuestionsQueryHookResult = ReturnType<typeof useQuestionsQuery>;
export type QuestionsLazyQueryHookResult = ReturnType<typeof useQuestionsLazyQuery>;
export type QuestionsQueryResult = Apollo.QueryResult<QuestionsQuery, QuestionsQueryVariables>;