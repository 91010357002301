import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  TableRow,
  ToggleButtonGroup,
  ToggleButton,
  Grid,
} from '@mui/material';
import { StyledTableCell } from '../../../styles/admin/ExamsListStyles';
import EmptyDataComponent from '../../states/componentStates/EmptyDataComponent';
import TablePagination from '../../common/TablePagination';
import { StudentExamsListInterface } from './studentExamsInterface';
import StudentExamsListRow from './StudentExamsListRow';
import { styled } from '@mui/styles';

interface StudentExamsListType {
  exams: Array<StudentExamsListInterface>;
  examTableTitle: string;
  pageNum: number;
  onNext: React.MouseEventHandler<HTMLButtonElement>;
  onPrev: React.MouseEventHandler<HTMLButtonElement>;
  nextDisabled: boolean;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
  '&.MuiToggleButtonGroup-root': {
    backgroundColor: 'whitesmoke',
    height: '30px',
    border: '0px',
  },
});

const StyledToggleButton = styled(ToggleButton)({
  '&.Mui-selected': {
    color: 'whitesmoke',
    backgroundColor: '#374EA2',
    border: '3px solid whitesmoke',
  },
  '&.Mui-disabled': {
    color: 'white',
  },
});

const StudentExamsListTable = (props: StudentExamsListType) => {
  const { exams, examTableTitle, pageNum, onNext, onPrev, nextDisabled } =
    props;

  const [alignment, setAlignment] = useState('All');
  const now = Date.now();

  const handleChange = (
    e: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment != alignment) {
      setAlignment(newAlignment);
    }
  };

  return (
    <Paper sx={{ boxShadow: 'none' }}>
      <TableContainer sx={{ marginTop: 2 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Grid
                  container
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Grid item>{examTableTitle}</Grid>
                  <Grid item>
                    <StyledToggleButtonGroup
                      value={alignment}
                      exclusive
                      onChange={handleChange}
                    >
                      <StyledToggleButton value="All">All</StyledToggleButton>
                      <StyledToggleButton value="Ongoing">
                        Ongoing
                      </StyledToggleButton>
                    </StyledToggleButtonGroup>
                  </Grid>
                </Grid>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {exams.length > 0 ? (
            <TableBody sx={{ backgroundColor: '#ffffff' }}>
              {exams.map((exam: StudentExamsListInterface) =>
                alignment === 'Ongoing' ? (
                  Number(exam.examDetails.endDate) > now ? (
                    <StudentExamsListRow key={exam.id} exam={exam} />
                  ) : (
                    <></>
                  )
                ) : (
                  <StudentExamsListRow key={exam.id} exam={exam} />
                )
              )}
            </TableBody>
          ) : (
            <EmptyDataComponent message="No exams have been added" />
          )}
        </Table>
      </TableContainer>
      <TablePagination
        pageNum={pageNum}
        buttonDisabled={nextDisabled}
        handleNext={onNext}
        handlePrev={onPrev}
      />
    </Paper>
  );
};

export default StudentExamsListTable;
