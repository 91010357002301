import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
  Box,
  IconButton,
  Button,
  Typography,
  Stack,
  FormControl,
  TextField,
  InputAdornment,
  FormControlLabel,
  useMediaQuery,
  FormGroup,
  Checkbox,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { MSQChoiceInterface } from './choiceInterface';
import CustomAlert from '../../../../alert/CustomAlert';
import CREATE_MS_QUESTION_MUTATION from '../../../../../graphql/mutations/admin/multipleSelection/question/createMultipleSelectionQuestion';
import {
  CreateMultipleSelectionQuestionMutation,
  CreateMultipleSelectionQuestionMutationVariables,
} from '../../../../../graphql/mutations/admin/multipleSelection/question/createMultipleSelectionQuestion.generated';
import { QuestionTagsProps } from '../addQuestionProps';

const style = {
  maxHeight: 200,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column-reverse',
};

const style2 = {
  maxHeight: 100,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column-reverse',
};

const defaultChoices = [
  {
    id: '1',
    description: '',
    isCorrect: false,
  },
  {
    id: '2',
    description: '',
    isCorrect: false,
  },
  {
    id: '3',
    description: '',
    isCorrect: false,
  },
  {
    id: '4',
    description: '',
    isCorrect: false,
  },
];

let choices: MSQChoiceInterface[] = JSON.parse(
  JSON.stringify(defaultChoices)
) as MSQChoiceInterface[];

const MultipleSelectionComponent = ({ program, module }: QuestionTagsProps) => {
  const [questionContent, setQuestionContent] = useState('');
  const [choice, setChoice] = useState('');
  const [allChoices, updateAllChoices] =
    useState<MSQChoiceInterface[]>(choices);
  const [selectedValue, setSelectedValue] = useState(false);
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(5);

  const matches = useMediaQuery('(min-width:600px)');

  const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestionContent(event.target.value);
  };

  const handleChoiceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChoice(event.target.value);
  };

  const handleDefaultChoiceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const { id, value } = event.target;
    const allChoicesCopy = [...allChoices];
    allChoicesCopy[Number(id)].description = value;
    updateAllChoices(allChoicesCopy);
  };

  const setCorrectChoice = (value: string, isSelected: boolean) => {
    const foundChoice = allChoices.find((element) => element.id === value);

    if (foundChoice) {
      foundChoice.isCorrect = isSelected;
    }
  };

  const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    setSelectedValue(checked);
    setCorrectChoice(value, checked);
  };

  const removeChoice = (id: string) => {
    choices = allChoices.filter((element) => element.id !== id);
    updateAllChoices(choices);
  };

  const resetInputField = () => {
    setChoice('');
  };

  const resetDefaultChoices = () => {
    updateAllChoices([]);
    choices = JSON.parse(
      JSON.stringify(defaultChoices)
    ) as MSQChoiceInterface[];
    updateAllChoices(choices);
  };

  const resetFields = () => {
    setTimeout(() => {
      resetDefaultChoices();
      setQuestionContent('');
    }, 1500);
  };

  useEffect(() => {
    updateAllChoices(choices);
  }, [allChoices]);

  const [createMSQuestion] = useMutation<
    CreateMultipleSelectionQuestionMutation,
    CreateMultipleSelectionQuestionMutationVariables
  >(CREATE_MS_QUESTION_MUTATION);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const choicesList = allChoices
      .map((choiceElement) => ({
        description: choiceElement.description,
        isCorrect: choiceElement.isCorrect,
      }))
      .filter((choiceElement) => choiceElement.description !== '');

    createMSQuestion({
      variables: {
        input: {
          program: program,
          module: module,
          question: questionContent,
          choices: choicesList,
        },
      },
      onCompleted: () => {
        setOpen(true);
        resetFields();
      },
    });
  };

  return (
    <Box>
      <CustomAlert
        message="Question was successfully added!"
        open={open}
        handleClose={() => setOpen(false)}
      />

      <form onSubmit={handleSubmit}>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={matches ? 2 : 2.2} marginY={1}>
            <Typography
              data-testid="msq-question-label"
              sx={{ mt: 2, fontSize: matches ? 16 : 14 }}
            >
              Question Content
            </Typography>
            <TextField
              required
              multiline
              data-testid="msq-question-content"
              variant="outlined"
              placeholder="Type question here"
              onChange={handleQuestionChange}
              value={questionContent}
              sx={{ width: matches ? 400 : 220 }}
            />
          </Stack>

          <Box sx={matches ? style : style2}>
            <div>
              {allChoices?.map((element: MSQChoiceInterface, index) => {
                const { id, description } = element;

                return (
                  <Stack direction="row" marginY={1} key={id}>
                    <Box width={matches ? 140 : 90} />
                    <FormControl>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={id}
                              onChange={handleAnswerChange}
                            />
                          }
                          label={
                            <TextField
                              key={id}
                              multiline
                              id={String(index)}
                              data-testid="msq-added-choice"
                              variant="standard"
                              placeholder={`choice ${index + 1}`}
                              value={description}
                              onChange={handleDefaultChoiceChange}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => {
                                        removeChoice(id);
                                      }}
                                      edge="end"
                                    >
                                      <CloseOutlined />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              sx={{ width: matches ? 380 : 200 }}
                            />
                          }
                        />
                      </FormGroup>
                    </FormControl>
                  </Stack>
                );
              })}
            </div>
          </Box>

          <Stack direction="row" spacing={0.5}>
            <Box width={140} />
            <TextField
              data-testid="msq-choice"
              variant="standard"
              placeholder="new choice"
              onChange={handleChoiceChange}
              value={choice}
              sx={{ width: 400 }}
            />
            <Button
              disableRipple
              onClick={() => {
                choices.push({
                  id: String(count),
                  description: choice,
                  isCorrect: false,
                });
                setCount(count + 1);
                resetInputField();
              }}
              sx={{
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                },
              }}
            >
              Add
            </Button>
          </Stack>

          <Box height={10} />

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            <Button
              type="submit"
              variant="contained"
              disableElevation
              size={matches ? 'medium' : 'small'}
              sx={{
                width: matches ? 155 : 130,
                textTransform: 'none',
              }}
            >
              Save Question
            </Button>
          </div>
        </Stack>
      </form>
    </Box>
  );
};

export default MultipleSelectionComponent;
