import React from 'react';
import MCQuestionModal from './MCQuestionModal';
import { QDialogDataProps } from '../questionModalProps';
import {
  GetUserQuery,
  useGetUserQuery,
} from '../../../../../graphql/queries/user/user.generated';
import {
  GetMultipleChoiceQuestionQuery,
  useGetMultipleChoiceQuestionQuery,
} from '../../../../../graphql/queries/admin/multipleChoice/getMultipleChoiceQuestion.generated';
import ErrorPage from '../../../../states/ErrorPage';
import TableIndividualRowLoading from '../../../../states/componentStates/TableIndividualRowLoading';

function MCDataPage(props: QDialogDataProps) {
  const { questionId, authorId } = props;

  const {
    data: questionAuthor,
    loading: questionAuthorLoading,
    error: questionAuthorError,
  } = useGetUserQuery({
    variables: {
      id: authorId,
    },
    pollInterval: 2000,
  });

  const {
    data: MCQuestion,
    loading: MCLoading,
    error: MCError,
  } = useGetMultipleChoiceQuestionQuery({
    variables: {
      id: questionId,
    },
    pollInterval: 2000,
  });

  if (MCLoading || questionAuthorLoading) return <TableIndividualRowLoading />;
  if (MCError || questionAuthorError)
    return (
      <ErrorPage
        errorMessage={MCError ? MCError.message : questionAuthorError?.message}
      />
    );

  return (
    <MCQuestionModal
      data={MCQuestion as GetMultipleChoiceQuestionQuery}
      author={questionAuthor as GetUserQuery}
    />
  );
}

export default MCDataPage;
