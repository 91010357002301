import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import theme from '../../themes/theme';

export const WelcomeContainer = styled('div')(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const AboutImage = styled('img')(() => ({
  maxHeight: '400px',
  maxWidth: '350px',
}));

export const SectionTitle = styled(Typography)(() => ({
  color: theme.palette.primary.main,
  fontWeight: 600,
  marginBottom: 12,
}));

export const FeaturesSectionContainer = styled('div')(() => ({
  marginTop: 10,
  marginBottom: 5,
  backgroundColor: '#f8f8f8',
}));

export const FeaturesIcon = styled('img')(() => ({
  height: '100px',
  width: '100px',
}));

export const FeaturesIconContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  paddingTop: '20px',
}));
