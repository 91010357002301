import React from 'react';
import { Stack, Typography } from '@mui/material';
import { LogoImage } from '../../styles/styledComponents/ResourcesPageStyles';
import Logo from '../../assets/merkle_trees_logo_transparent.png';

const ResourceHeader = (props: { title: string }) => {
  return (
    <Stack direction="column" alignItems="center" spacing={2}>
      <LogoImage src={Logo} alt="merkle-trees-logo" />
      <Typography fontWeight="fontWeightBold" variant="h4" color="primary.main">
        {props.title}
      </Typography>
    </Stack>
  );
};

export default ResourceHeader;
