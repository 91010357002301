import React, { useState } from 'react';
import { IconButton, MenuItem } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CandidateDeleteDialog from './CandidateDeleteDialog';
import { StyledMenu } from '../../../styles/styledComponents/MenuStyles';
import CandidateUpdateDialog from './CandidateUpdateDialog';
import { UserInterface } from '../../../pages/admin/questionBank/QuestionInterface';
import CandidateSendExamLinkDialog from './CandidateSendExamLinkDialog';

interface CandidateManagementMenuProps {
  candidate: UserInterface;
}
const CandidateManagementMenu = (props: CandidateManagementMenuProps) => {
  const { firstName, lastName, email, id } = props.candidate;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openSendExamLinkDialog, setOpenSendExamLinkDialog] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="customized-menu"
        MenuListProps={{
          'aria-labelledby': 'customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <MenuItem
          href=""
          disableRipple
          onClick={(e) => {
            e.stopPropagation();
            setOpenUpdateDialog(true);
          }}
        >
          <EditOutlinedIcon />
          Edit
        </MenuItem>
        <MenuItem
          href=""
          disableRipple
          onClick={(e) => {
            e.stopPropagation();
            setOpenDeleteDialog(true);
          }}
        >
          <DeleteOutlineIcon />
          Delete
        </MenuItem>
        <MenuItem
          href=""
          disableRipple
          onClick={(e) => {
            e.stopPropagation();
            setOpenSendExamLinkDialog(true);
          }}
        >
          <AttachEmailOutlinedIcon />
          Send Exam Link
        </MenuItem>
      </StyledMenu>
      <CandidateUpdateDialog
        isOpen={openUpdateDialog}
        setOpen={setOpenUpdateDialog}
        candidateFirstName={firstName}
        candidateLastName={lastName}
        userId={id}
      />
      <CandidateDeleteDialog
        isOpen={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        candidateEmail={email}
        userId={id}
      />
      <CandidateSendExamLinkDialog
        isOpen={openSendExamLinkDialog}
        setOpen={setOpenSendExamLinkDialog}
        candidateEmail={email}
      />
    </div>
  );
};

export default CandidateManagementMenu;
