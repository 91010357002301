import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { useGoogleLogout } from 'react-google-login';
import DashboardIcon from '@mui/icons-material/Dashboard';
import QuizBankIcon from '@mui/icons-material/Quiz';
import ExamBankIcon from '@mui/icons-material/QuestionAnswer';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import {
  AccountContainer,
  LogoContainer,
  ListItemIconContainer,
  Drawer,
  Logo,
} from '../../../styles/styledComponents/SideNavigationStyles';
import sideNavigationLogo from '../../../assets/merkle_trees_reversed.png';
import { useMutation } from '@apollo/client';
import SIGN_OUT_MUTATION from '../../../graphql/mutations/auth/signOut';
import { removeUser } from '../../../routes/userInfo/user';
import AccountPopover from '../common/AccountPopover';
import LoadingPage from '../../states/LoadingPage';

const textStyle = { color: '#ffffff', fontSize: '14px' };
const buttonStyle = { color: '#ffffff', fontSize: '16px' };

const menus = [
  {
    index: 1,
    text: 'Dashboard',
    icon: <DashboardIcon sx={buttonStyle} />,
    onClick: '/admindashboard',
  },
  {
    index: 2,
    text: 'Candidate Management',
    icon: <FolderSharedIcon sx={buttonStyle} />,
    onClick: '/candidate-management',
  },
  {
    index: 3,
    text: 'Exam Bank',
    icon: <ExamBankIcon sx={buttonStyle} />,
    onClick: '/exambank',
  },
  {
    index: 4,
    text: 'Question Bank',
    icon: <QuizBankIcon sx={buttonStyle} />,
    onClick: '/questionbank',
  },
  {
    index: 5,
    text: 'Admin Management',
    icon: <SupervisorAccountIcon sx={buttonStyle} />,
    onClick: '/adminmanagement',
  },
];

const SideNavigation = (props: { name: string }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [selectedMenu, setSelectedMenu] = useState(1);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (index: number) => {
    setSelectedMenu(index);
  };

  const [signOutUser] = useMutation(SIGN_OUT_MUTATION);

  const [loading, setLoading] = useState(false);

  const handleSignOut = () => {
    setLoading(true);
    setTimeout(() => {
      signOutUser();
      removeUser();
      window.location.href = '/';
    }, 2000);
  };

  if (loading) return <LoadingPage />;

  return (
    <Drawer variant="permanent" anchor="left">
      <LogoContainer>
        <Button
          disableRipple
          sx={{
            textTransform: 'none',
            '&.MuiButtonBase-root:hover': {
              bgcolor: 'transparent',
            },
          }}
          onClick={() => navigate('/')}
        >
          <Logo src={sideNavigationLogo} alt="assess-logo" />
        </Button>
      </LogoContainer>
      <List>
        {menus.map((menu) => (
          <ListItemButton
            key={menu.index}
            data-testid={menu.text}
            onClick={() => {
              navigate(`${menu.onClick}`);
              handleMenuClick(menu.index);
            }}
            selected={selectedMenu === menu.index}
          >
            <ListItemIconContainer>{menu.icon}</ListItemIconContainer>
            <ListItemText
              primary={menu.text}
              primaryTypographyProps={textStyle}
            />
          </ListItemButton>
        ))}
      </List>
      <AccountContainer>
        <ListItemButton
          data-testid="Account"
          onClick={(event) => {
            handleClick(event);
          }}
        >
          <ListItemIconContainer>
            <AccountCircleIcon sx={buttonStyle} />
          </ListItemIconContainer>
          <ListItemText primary="Account" primaryTypographyProps={textStyle} />
        </ListItemButton>
        <AccountPopover
          userName={props.name}
          userType="Admin"
          isOpen={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleSignout={handleSignOut}
        />
      </AccountContainer>
    </Drawer>
  );
};

export default SideNavigation;
