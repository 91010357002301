import React from 'react';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import QuestionComponent from '../QuestionComponentDetails';
import { StudentAnswerInterface } from '../answerInterface';
import { MCQuestionComponentProps } from './mcQuestionComponentProps';

function MCQuestionComponent({
  id,
  question,
  options,
  existingAnswer,
  handleMCAnswerChange,
}: MCQuestionComponentProps) {
  return (
    <>
      <QuestionComponent
        questionContent={question}
        details={
          <FormControl>
            <RadioGroup
              defaultValue={existingAnswer?.option}
              onChange={
                handleMCAnswerChange as (
                  event: React.ChangeEvent<HTMLInputElement>
                ) => void
              }
              name={id}
            >
              {options?.map((option: StudentAnswerInterface) => {
                return (
                  <FormControlLabel
                    key={option.id as string}
                    data-testid="radio"
                    value={option.id as string}
                    control={
                      <Radio
                        disableRipple
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 20,
                          },
                          '&&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          fontSize: 14,
                        }}
                      >
                        {option.description as string}
                      </Typography>
                    }
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        }
      />
    </>
  );
}

export default MCQuestionComponent;
