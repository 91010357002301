import { Box, styled } from '@mui/material';

export const FooterDivStyle = styled('div')(() => ({
  width: '100%',
  padding: '25px',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}));

export const LogoStyle = styled('img')(() => ({
  width: '100%',
  maxWidth: '200px',
  height: 'auto',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: '30px',
}));

export const BoxStyle = styled(Box)(() => ({
  paddingLeft: 2,
  paddingRight: 2,
  paddingTop: 3,
  paddingBottom: 3,
  marginTop: 'auto',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}));
