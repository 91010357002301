import React from 'react';
import MCQuestionComponent from './MCQuestion/MCQuestionComponent';
import { Option } from './MCQuestion/mcQuestionComponentProps';
import MSQuestionComponent from './MSQuestion/MSQuestionComponent';
import { Choice } from './MSQuestion/msQuestionComponentProps';
import { QuestionComponentDetailsProps } from './studentTakeExamComponentsProps';

function AnswerableQuestionComponent({
  id,
  question,
  options,
  choices,
  type,
  existingAnswer,
  handleMCAnswerChange,
  handleMSAnswerChange,
}: QuestionComponentDetailsProps) {
  switch (type) {
    case 'MULTIPLE_CHOICE_QUESTION':
      return (
        <MCQuestionComponent
          id={id}
          question={question}
          options={options as Option[]}
          existingAnswer={existingAnswer}
          handleMCAnswerChange={
            handleMCAnswerChange as (
              event: React.ChangeEvent<HTMLInputElement>
            ) => void
          }
        />
      );
    case 'MULTIPLE_SELECTION_QUESTION':
      return (
        <MSQuestionComponent
          question={question}
          choices={choices as Choice[]}
          existingAnswer={existingAnswer}
          handleMSAnswerChange={
            handleMSAnswerChange as (
              event: React.ChangeEvent<HTMLInputElement>,
              checked: boolean
            ) => void
          }
        />
      );
    default:
      return null;
  }
}

export default AnswerableQuestionComponent;
