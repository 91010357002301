import { styled } from '@mui/material';

export const ExamScorePageContainer = styled('div')(() => ({
  margin: '30px 40px 30px 40px',
}));

export const ExamScoreContentsBox = styled('div')(() => ({
  marginTop: 20,
  borderRadius: 5,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
}));

export const SubmissionDetailsContainer = styled('div')(() => ({
  maxHeight: '100%',
  backgroundColor: '#f5f5f5',
  borderRadius: 5,
  padding: 20,
}));

export const SubmissionDetailsDiv = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: 12,
}));
