/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState, useEffect } from 'react';
import ErrorPage from '../../states/ErrorPage';
import { UserInterface } from '../../../pages/admin/questionBank/QuestionInterface';
import { useAllUsersQuery } from '../../../graphql/queries/user/getPaginatedUsers.generated';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import EmptyDataComponent from '../../states/componentStates/EmptyDataComponent';
import TablePagination from '../../common/TablePagination';
import { useNavigate } from 'react-router-dom';
import { StyledTableCell } from '../../../styles/admin/CandidateManagementPageStyles';
import CandidateManagementMenu from './CandidateManagementMenu';

const CandidateList = () => {
  const navigate = useNavigate();
  const { data, loading, error, fetchMore } = useAllUsersQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      first: 10,
      last: null,
      after: null,
      before: null,
    },
    pollInterval: 2000,
  });
  const [page, setPage] = useState(1);

  const endCursor = data?.usersConnection?.pageInfo.endCursor;
  const startCursor = data?.usersConnection?.pageInfo.startCursor;
  const hasPrevPage = data?.usersConnection?.pageInfo.hasPreviousPage;
  const hasNextPage = data?.usersConnection?.pageInfo.hasNextPage;

  const moreResults = () => {
    fetchMore({
      variables: {
        first: 10,
        last: null,
        after: endCursor,
        before: null,
      },
    });
  };

  const prevResults = () => {
    fetchMore({
      variables: {
        first: null,
        last: 10,
        after: null,
        before: startCursor,
      },
    });
  };

  const [disableButton, setDisableButton] = useState<boolean>(!hasNextPage);

  useEffect(() => {
    if (hasNextPage) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [hasNextPage]);

  const handleClickNext = () => {
    if (hasNextPage) {
      moreResults();
    }
    setPage(page + 1);
  };

  const handleClickPrev = () => {
    if (hasPrevPage) {
      prevResults();
      setPage(page - 1);
      setDisableButton(false);
    }
  };

  useEffect(() => {
    if (!hasPrevPage && page !== 1) {
      setPage(1);
    }
  }, [data]);

  if (loading) return <></>;
  if (error) return <ErrorPage errorMessage={error.message} />;

  const candidates: UserInterface[] = data?.usersConnection
    ?.nodes as unknown as UserInterface[];

  return (
    <>
      <Paper
        sx={{
          boxShadow: 'none',
        }}
      >
        <TableContainer
          sx={{
            marginTop: 2,
            borderRadius: '4px',
          }}
        >
          <Table
            size="small"
            stickyHeader
            sx={{ width: '100%', maxHeight: '50vh' }}
          >
            {candidates.length > 0 ? (
              <>
                <TableHead sx={{ backgroundColor: '#374EA2' }}>
                  <TableRow
                    sx={{
                      backgroundColor: 'transparent',
                    }}
                  >
                    <StyledTableCell align="left">Email</StyledTableCell>

                    <StyledTableCell align="left">Name</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody sx={{ backgroundColor: '#FFFFFF' }}>
                  {candidates.map((candidate: UserInterface) => (
                    <TableRow
                      key={candidate.id}
                      onClick={() => {
                        navigate(`/candidate-details/${candidate.id}/`, {
                          state: { prevPath: location.pathname },
                        });
                      }}
                      hover
                    >
                      <StyledTableCell align="left">
                        {candidate.email}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {candidate.firstName} {candidate.lastName}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'end',
                        }}
                      >
                        <CandidateManagementMenu
                          key={candidate.id}
                          candidate={candidate}
                        />
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            ) : (
              <EmptyDataComponent message="No candidates have been added" />
            )}
          </Table>
          <TablePagination
            pageNum={page}
            buttonDisabled={disableButton}
            handleNext={handleClickNext}
            handlePrev={handleClickPrev}
          />
        </TableContainer>
      </Paper>
    </>
  );
};

export default CandidateList;
