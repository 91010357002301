import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Footer from '../layouts/footer/Footer';
import Header from '../layouts/header/Header';
import { getUser, getUserRoles } from './userInfo/user';
import ProtectedStudentRoute from './ProtectedStudentRoute';

const PublicRouteLayout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

function PublicRoute(props: { role: string }) {
  const isAuthenticated = getUser();

  let roles: string[] = [];

  if (isAuthenticated) {
    roles = getUserRoles();
  }

  return !isAuthenticated ? (
    <PublicRouteLayout />
  ) : roles.includes(props.role) ? (
    <Navigate to="/admindashboard" />
  ) : (
    <>
      <ProtectedStudentRoute allowedRole="STUDENT" />
    </>
  );
}

export default PublicRoute;
