import React, { useState } from 'react';
import { Typography, Stack, Grid } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import SessionExpired from '../modal/authentication/SessionExpired';

interface ErrorPageProps {
  errorMessage: string | undefined;
}

const ErrorPage = (props: ErrorPageProps) => {
  const { errorMessage } = props;
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);

  console.log(errorMessage);
  if (errorMessage == 'You must be logged in' && !sessionExpired) {
    setSessionExpired(true);
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      {sessionExpired ? <SessionExpired /> : <></>}
      <Stack direction="column" spacing={0.5}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <ErrorIcon sx={{ color: '#e57373', fontSize: '40px' }} />
          <Typography
            fontWeight="fontWeightBold"
            variant="h4"
            sx={{ color: '#374EA2' }}
          >
            Error
          </Typography>
        </Stack>
        <Typography variant="h6" sx={{ color: '#BEBEBE' }}>
          Something went wrong :(
        </Typography>
        <Typography variant="body2" sx={{ color: '#BEBEBE' }}>
          You may check your connection and try again.
        </Typography>
      </Stack>
    </Grid>
  );
};

export default ErrorPage;
