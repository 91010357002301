import { AppBar } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LogoStyle = styled('img')(() => ({
  width: '100%',
  maxWidth: '120px',
  height: 'auto',
  display: 'block',
  paddingRight: '15px',
}));

export const AppBarStyle = styled(AppBar)(() => ({
  backgroundColor: '#FFFFFF',
  color: '#374EA2',
  justifyContent: 'center',
  alignItems: 'center',
}));
