import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllExamsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AllExamsQuery = { __typename?: 'Query', examsConnection?: { __typename?: 'ExamsConnection', nodes?: Array<{ __typename?: 'Exam', id?: string | null, title?: string | null, description?: string | null, program?: Types.Program | null, module?: string | null, year?: number | null, quarter?: Types.Quarter | null, isPublished?: boolean | null, createdAt?: any | null, updatedAt?: any | null, createdById?: string | null, studentExams?: Array<{ __typename?: 'StudentExam', id?: string | null, score?: number | null, submittedAt?: any | null, passed?: boolean | null, student?: { __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null } | null } | null> | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, startCursor?: string | null, endCursor?: string | null } } | null };


export const AllExamsDocument = gql`
    query AllExams($first: Int, $last: Int, $after: String, $before: String) {
  examsConnection(first: $first, last: $last, after: $after, before: $before) {
    nodes {
      id
      title
      description
      program
      module
      year
      quarter
      isPublished
      createdAt
      updatedAt
      createdById
      studentExams {
        id
        score
        student {
          id
          firstName
          lastName
          email
        }
        submittedAt
        passed
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useAllExamsQuery__
 *
 * To run a query within a React component, call `useAllExamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllExamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllExamsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useAllExamsQuery(baseOptions?: Apollo.QueryHookOptions<AllExamsQuery, AllExamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllExamsQuery, AllExamsQueryVariables>(AllExamsDocument, options);
      }
export function useAllExamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllExamsQuery, AllExamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllExamsQuery, AllExamsQueryVariables>(AllExamsDocument, options);
        }
export type AllExamsQueryHookResult = ReturnType<typeof useAllExamsQuery>;
export type AllExamsLazyQueryHookResult = ReturnType<typeof useAllExamsLazyQuery>;
export type AllExamsQueryResult = Apollo.QueryResult<AllExamsQuery, AllExamsQueryVariables>;