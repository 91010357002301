import React from 'react';
import { DialogTitle, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { QModalCloseProps } from './commonProps';

function ModalClose({ handleClose }: QModalCloseProps) {
  return (
    <>
      <DialogTitle>
        <Grid container>
          <Grid
            item
            md={12}
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
    </>
  );
}

export default ModalClose;
