import React from 'react';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
  Typography,
} from '@mui/material';
import { AnswerDisplayProps } from './AnswerDisplayProps';

interface AnswerProps {
  optionId: string;
  textValue: string;
  testId: string;
  isCorrectAnswer: boolean;
  studentAnswer?: string | string[];
}

const MCQDisplayComponent = ({
  optionId,
  textValue,
  testId,
  isCorrectAnswer,
  studentAnswer,
}: AnswerProps) => {
  const getAnswer = () => {
    if (studentAnswer) {
      return optionId === studentAnswer;
    }
    return isCorrectAnswer;
  };
  return (
    <RadioGroup
      aria-labelledby="demo-controlled-radio-buttons-group"
      defaultValue={textValue}
      style={{
        background: studentAnswer && isCorrectAnswer ? '#00800040' : 'white',
      }}
    >
      <FormControlLabel
        data-testid="radio"
        checked={getAnswer()}
        control={
          <Radio
            data-testid={testId}
            value={textValue}
            disableRipple
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 20,
              },
              '&&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          />
        }
        label={
          <Typography
            sx={{
              fontSize: 14,
              textAlign: 'start',
            }}
          >
            {textValue}
          </Typography>
        }
      />
    </RadioGroup>
  );
};

const MSQDisplayComponent = ({
  optionId,
  textValue,
  testId,
  isCorrectAnswer,
  studentAnswer,
}: AnswerProps) => {
  const getAnswer = () => {
    if (studentAnswer) {
      return studentAnswer.indexOf(optionId) > -1;
    }
    return isCorrectAnswer;
  };

  return (
    <FormGroup
      style={{
        background: studentAnswer && isCorrectAnswer ? '#00800040' : 'white',
      }}
    >
      <FormControlLabel
        checked={getAnswer()}
        control={
          <Checkbox
            data-testid={testId}
            value={textValue}
            disableRipple
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 20,
              },
              '&&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          />
        }
        label={
          <Typography
            sx={{
              fontSize: 14,
              textAlign: 'start',
            }}
          >
            {textValue}
          </Typography>
        }
      />
    </FormGroup>
  );
};

function AnswerItemDisplay({
  optionId,
  questionType,
  textValue,
  testId,
  isCorrectAnswer,
  studentAnswer,
}: AnswerDisplayProps) {
  return (
    <FormControl>
      {questionType === 'MultipleChoiceQuestion' ? (
        <MCQDisplayComponent
          optionId={optionId}
          textValue={textValue}
          testId={testId}
          isCorrectAnswer={isCorrectAnswer}
          studentAnswer={studentAnswer}
        />
      ) : (
        <MSQDisplayComponent
          optionId={optionId}
          textValue={textValue}
          testId={testId}
          isCorrectAnswer={isCorrectAnswer}
          studentAnswer={studentAnswer}
        />
      )}
    </FormControl>
  );
}

export default AnswerItemDisplay;
