import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendEmailCertificateMutationVariables = Types.Exact<{
  input: Types.SendEmailCertificateInput;
}>;


export type SendEmailCertificateMutation = { __typename?: 'Mutation', sendEmailCertificate?: { __typename?: 'SendEmailCertificatePayload', successful?: boolean | null } | null };


export const SendEmailCertificateDocument = gql`
    mutation SendEmailCertificate($input: SendEmailCertificateInput!) {
  sendEmailCertificate(input: $input) {
    successful
  }
}
    `;
export type SendEmailCertificateMutationFn = Apollo.MutationFunction<SendEmailCertificateMutation, SendEmailCertificateMutationVariables>;

/**
 * __useSendEmailCertificateMutation__
 *
 * To run a mutation, you first call `useSendEmailCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailCertificateMutation, { data, loading, error }] = useSendEmailCertificateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendEmailCertificateMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailCertificateMutation, SendEmailCertificateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailCertificateMutation, SendEmailCertificateMutationVariables>(SendEmailCertificateDocument, options);
      }
export type SendEmailCertificateMutationHookResult = ReturnType<typeof useSendEmailCertificateMutation>;
export type SendEmailCertificateMutationResult = Apollo.MutationResult<SendEmailCertificateMutation>;
export type SendEmailCertificateMutationOptions = Apollo.BaseMutationOptions<SendEmailCertificateMutation, SendEmailCertificateMutationVariables>;