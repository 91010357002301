import React from 'react';
import { useParams } from 'react-router-dom';
import ErrorPage from '../../../components/states/ErrorPage';
import LoadingPage from '../../../components/states/LoadingPage';
import ViewStudentExamPage from './ViewStudentExamPage';
import { useGetStudentExamQuery } from '../../../graphql/queries/admin/exam/getStudentExam.generated';

function ViewStudentExamDataPage() {
  const params = useParams();
  const examId = String(params.examId);
  const studId = String(params.studId);

  const {
    data: studentExam,
    loading: studentExamLoading,
    error: studentExamError,
  } = useGetStudentExamQuery({
    variables: {
      studentId: studId,
      examId: examId,
    },
    pollInterval: 2000,
  });

  if (studentExamLoading) return <LoadingPage />;
  if (studentExamError)
    return <ErrorPage errorMessage={studentExamError.message} />;
  return <ViewStudentExamPage exam={studentExam} />;
}

export default ViewStudentExamDataPage;
