import { gql } from '@apollo/client';

const CREATE_MC_QUESTION_MUTATION = gql`
  mutation CreateMultipleChoiceQuestion(
    $input: CreateMultipleChoiceQuestionInput!
  ) {
    createMultipleChoiceQuestion(input: $input) {
      program
      module
      question
      options {
        id
        description
        isCorrect
      }
    }
  }
`;

export default CREATE_MC_QUESTION_MUTATION;
