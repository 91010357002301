import { gql } from '@apollo/client';

const SUBSCRIPTION_STATUS_QUERY = gql`
  query SubscriptionStatus {
    subscriptionStatus {
      status
      examLink
    }
  }
`;

export default SUBSCRIPTION_STATUS_QUERY;
