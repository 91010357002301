import { styled, Typography, TableCell, tableCellClasses } from '@mui/material';

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    fontWeight: 'bold',
    fontSize: 18,
    paddingBottom: 8,
    paddingTop: 8,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.common.white,
    paddingBottom: 4,
    paddingTop: 4,
  },
}));

export const RowContainer = styled('div')(() => ({
  justifyContent: 'space-between',
  display: 'flex',
  alignItems: 'center',
  minHeight: 44,
}));

export const ExamContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const ExamStatusContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: '10px',
}));

export const ExamDetailsText = styled(Typography)(() => ({
  fontSize: '11px',
  color: '#a9a9a9',
  marginRight: '10px',
}));
