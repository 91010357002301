import { gql } from '@apollo/client';

export const GET_MS_QUESTION_QUERY = gql`
  query GetMultipleSelectionQuestion($id: String!) {
    multipleSelectionQuestion(id: $id) {
      id
      program
      module
      question
      createdAt
      updatedAt
      choices {
        id
        description
        isCorrect
      }
    }
  }
`;

export default GET_MS_QUESTION_QUERY;
