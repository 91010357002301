import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMultipleSelectionQuestionQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetMultipleSelectionQuestionQuery = { __typename?: 'Query', multipleSelectionQuestion?: { __typename?: 'MultipleSelectionQuestion', id?: string | null, program?: Types.Program | null, module?: string | null, question?: string | null, createdAt?: any | null, updatedAt?: any | null, choices?: Array<{ __typename?: 'Choice', id?: string | null, description?: string | null, isCorrect?: boolean | null } | null> | null } | null };


export const GetMultipleSelectionQuestionDocument = gql`
    query GetMultipleSelectionQuestion($id: String!) {
  multipleSelectionQuestion(id: $id) {
    id
    program
    module
    question
    createdAt
    updatedAt
    choices {
      id
      description
      isCorrect
    }
  }
}
    `;

/**
 * __useGetMultipleSelectionQuestionQuery__
 *
 * To run a query within a React component, call `useGetMultipleSelectionQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMultipleSelectionQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMultipleSelectionQuestionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMultipleSelectionQuestionQuery(baseOptions: Apollo.QueryHookOptions<GetMultipleSelectionQuestionQuery, GetMultipleSelectionQuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMultipleSelectionQuestionQuery, GetMultipleSelectionQuestionQueryVariables>(GetMultipleSelectionQuestionDocument, options);
      }
export function useGetMultipleSelectionQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMultipleSelectionQuestionQuery, GetMultipleSelectionQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMultipleSelectionQuestionQuery, GetMultipleSelectionQuestionQueryVariables>(GetMultipleSelectionQuestionDocument, options);
        }
export type GetMultipleSelectionQuestionQueryHookResult = ReturnType<typeof useGetMultipleSelectionQuestionQuery>;
export type GetMultipleSelectionQuestionLazyQueryHookResult = ReturnType<typeof useGetMultipleSelectionQuestionLazyQuery>;
export type GetMultipleSelectionQuestionQueryResult = Apollo.QueryResult<GetMultipleSelectionQuestionQuery, GetMultipleSelectionQuestionQueryVariables>;