import { gql } from '@apollo/client';

export const GET_MC_QUESTION_QUERY = gql`
  query GetMultipleChoiceQuestion($id: String!) {
    multipleChoiceQuestion(id: $id) {
      id
      program
      module
      question
      createdAt
      updatedAt
      options {
        id
        description
        isCorrect
      }
    }
  }
`;

export default GET_MC_QUESTION_QUERY;
