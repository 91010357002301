import React from 'react';
import { Stack, Typography } from '@mui/material';
import { ResourcesPageContainer } from '../../styles/styledComponents/ResourcesPageStyles';
import ResourceHeader from '../../components/resources/ResourceHeader';
import ResourceSection from '../../components/resources/ResourceSection';
import data from './resourcesPagesTexts.json';

const TermsOfServicePage = () => {
  const overview = data.texts[4].termsOfService?.overview as string[];
  const section1 = data.texts[4].termsOfService?.section1 as string[];
  const section2 = data.texts[4].termsOfService?.section2 as string[];
  const section3 = data.texts[4].termsOfService?.section3 as string[];
  const section4 = data.texts[4].termsOfService?.section4 as string[];
  const section5 = data.texts[4].termsOfService?.section5 as string[];
  const section6 = data.texts[4].termsOfService?.section6 as string[];
  const section7 = data.texts[4].termsOfService?.section7 as string[];
  const section8 = data.texts[4].termsOfService?.section8 as string[];
  const section9 = data.texts[4].termsOfService?.section9 as string[];
  const section10 = data.texts[4].termsOfService?.section10 as string[];
  const section11 = data.texts[4].termsOfService?.section11 as string[];
  const section12 = data.texts[4].termsOfService?.section12 as string[];
  const section13 = data.texts[4].termsOfService?.section13 as string[];
  const section14 = data.texts[4].termsOfService?.section14 as string[];
  const section15 = data.texts[4].termsOfService?.section15 as string[];
  const section16 = data.texts[4].termsOfService?.section16 as string[];
  const section17 = data.texts[4].termsOfService?.section17 as string[];
  const section18 = data.texts[4].termsOfService?.section18 as string[];
  const section19 = data.texts[4].termsOfService?.section19 as string[];
  const section20 = data.texts[4].termsOfService?.section20 as string[];
  const paymentScheduleAndMethods = data.texts[4].termsOfService
    ?.paymentScheduleAndMethods as string[];
  const courses = data.texts[4].termsOfService?.courses as string[];

  return (
    <ResourcesPageContainer>
      <Stack direction="column" spacing={4}>
        <ResourceHeader title="The Merkle Trees Terms of Service" />
        <ResourceSection title="OVERVIEW" contents={overview} />
        <ResourceSection
          title="SECTION 1 – ONLINE STORE TERMS"
          contents={section1}
        />
        <ResourceSection
          title="SECTION 2 – GENERAL CONDITIONS"
          contents={section2}
        />
        <ResourceSection
          title="SECTION 3 – ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION"
          contents={section3}
        />
        <ResourceSection
          title="SECTION 4 – MODIFICATIONS TO THE SERVICE AND PRICES"
          contents={section4}
        />
        <ResourceSection
          title="SECTION 5 – PRODUCTS OR SERVICES (if applicable)"
          contents={section5}
        />
        <ResourceSection
          title="SECTION 6 – ACCURACY OF BILLING AND ACCOUNT INFORMATION"
          contents={section6}
        />
        <ResourceSection
          title="SECTION 7 – OPTIONAL TOOLS"
          contents={section7}
        />
        <ResourceSection
          title="SECTION 8 – THIRD-PARTY LINKS"
          contents={section8}
        />
        <ResourceSection
          title="SECTION 9 – USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS"
          contents={section9}
        />
        <ResourceSection
          title="SECTION 10 – PERSONAL INFORMATION"
          contents={section10}
        />
        <ResourceSection
          title="SECTION 11 – ERRORS, INACCURACIES AND OMISSIONS"
          contents={section11}
        />
        <ResourceSection
          title="SECTION 12 – PROHIBITED USES"
          contents={section12}
        />
        <ResourceSection
          title="SECTION 13 – DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY"
          contents={section13}
        />
        <ResourceSection
          title="SECTION 14 – INDEMNIFICATION"
          contents={section14}
        />
        <ResourceSection
          title="SECTION 15 – SEVERABILITY"
          contents={section15}
        />
        <ResourceSection
          title="SECTION 16 – TERMINATION"
          contents={section16}
        />
        <ResourceSection
          title="SECTION 17 – ENTIRE AGREEMENT"
          contents={section17}
        />
        <ResourceSection
          title="SECTION 18 – GOVERNING LAW"
          contents={section18}
        />
        <ResourceSection
          title="SECTION 19 – CHANGES TO TERMS OF SERVICE"
          contents={section19}
        />
        <ResourceSection
          title="SECTION 20 – CONTACT INFORMATION"
          contents={section20}
        />
        <Typography
          fontWeight="fontWeightBold"
          variant="h5"
          color="primary.main"
        >
          Product Purchase Refund Policy
        </Typography>
        <ResourceSection
          title="Payment Schedule & Methods"
          contents={paymentScheduleAndMethods}
        />
        <ResourceSection title="Courses" contents={courses} />
      </Stack>
    </ResourcesPageContainer>
  );
};

export default TermsOfServicePage;
