import React from 'react';
import { Typography, Grid } from '@mui/material';
import { DashboardContainer } from '../../../styles/admin/DashboardStyles';
import CandidateList from '../../../components/admin/candidateManagement/CandidateList';

const CandidateManagementPage = () => {
  return (
    <DashboardContainer>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12} md={9}>
          <Typography
            color="secondary.main"
            variant="h5"
            fontWeight="fontWeightBold"
          >
            Candidate Management
          </Typography>
          <CandidateList />
        </Grid>
      </Grid>
    </DashboardContainer>
  );
};
export default CandidateManagementPage;
