import { styled } from '@mui/material';

export const ExamsPageContainer = styled('div')(() => ({
  margin: 30,
}));

export const ExamsPageHeaderContainer = styled('div')(() => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
}));
