/* eslint-disable @typescript-eslint/naming-convention */

import React, { useState, useEffect } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Grid, Typography, Paper, Box } from '@mui/material';
import { WelcomeBackContainer } from '../../../styles/student/ExamCoverPageStyles';
import { useParams } from 'react-router-dom';
import { useGetBasicExamDetailsQuery } from '../../../graphql/queries/admin/exam/getBasicExamDetails.generated';
import LoadingPage from '../../../components/states/LoadingPage';

const ExamCoverPage = () => {
  const params = useParams();
  const examId = String(params.examId);
  const { data, loading, error } = useGetBasicExamDetailsQuery({
    variables: { examId },
  });

  if (loading) return <LoadingPage />;

  return (
    <Box>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        marginTop={8}
        paddingX={2}
      >
        <Grid item xs={12} sm={11} md={10} justifyContent="start">
          <WelcomeBackContainer>
            <Typography
              fontWeight="fontWeightBold"
              variant="h4"
              color="secondary.main"
              sx={{ mr: 1 }}
            >
              Welcome back,
            </Typography>
            <Typography
              variant="h4"
              fontWeight="fontWeightBold"
              color="#539536"
            >
              Candidate!
            </Typography>
          </WelcomeBackContainer>

          <Typography
            fontWeight="fontWeightMedium"
            color="secondary.main"
            align="left"
          >
            You have successfully paid for the exam certification. Please check
            your <span style={{ fontWeight: 'bold' }}>email inbox</span> for the{' '}
            <span style={{ fontWeight: 'bold', color: '#539536' }}>
              exam link
            </span>
            . You may begin whenever you are ready.
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ background: '#f8f8f8', mt: 6, py: 4, px: 2 }}>
        {/* <Container maxWidth="md" sx={{ pt: 4, pb: 4, bgcolor: 'green' }}> */}
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={8} md={6}>
            <Paper
              sx={{
                background: '#FFFFFF',
                width: '100%',
                p: 4,
                boxShadow: '8px 8px 12px #B8B8B8',
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                gap: '24px',
              }}
            >
              <Box>
                <Typography
                  fontWeight="fontWeightBold"
                  variant="h5"
                  color="secondary.main"
                >
                  {data?.exam?.title}
                </Typography>
                <Typography
                  fontWeight="fontWeightMedium"
                  color="secondary.main"
                  mt={2}
                >
                  {data?.exam?.description}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  flexDirection: 'column',
                  alignItems: 'start',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <CalendarMonthIcon sx={{ color: '#539536' }} />
                  <Typography
                    fontWeight="fontWeightBold"
                    color="#539536"
                    fontSize="14px"
                  >
                    Certificate is valid forever
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}
                >
                  <AccessTimeIcon sx={{ color: '#539536' }} />
                  <Typography
                    fontWeight="fontWeightBold"
                    color="#539536"
                    fontSize="14px"
                  >
                    {data?.exam?.duration ?? 'Unlimited time'}
                  </Typography>
                </Box>
              </Box>
              {/* <a
                href={examLink}
                style={{
                  textDecoration: 'none',
                }}
              >
                <Button
                  disableElevation
                  variant="contained"
                  sx={{
                    color: '#FFFFFF',
                    bgcolor: 'secondary.main',
                    textTransform: 'capitalize',
                  }}
                >
                  open exam
                </Button>
              </a> */}
            </Paper>
          </Grid>
        </Grid>
        {/* </Container> */}
      </Box>
    </Box>
  );
};

export default ExamCoverPage;
