import React, { useEffect, useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import CustomDialog from '../../../../components/dialog/CustomDialog';
import { Exam, ExamAnswerableQuestionInterface } from '../examProps';
import AnswerableQuestionComponent from '../../../../components/student/QuestionComponent/AnswerableQuestionComponent';
import { Option } from '../../../../components/student/QuestionComponent/MCQuestion/mcQuestionComponentProps';
import { Choice } from '../../../../components/student/QuestionComponent/MSQuestion/msQuestionComponentProps';
import SUBMIT_EXAM_ANSWERS_MUTATION from '../../../../graphql/mutations/student/submitAnswers/submitExamAnswer';
import {
  SubmitStudentExamMutation,
  SubmitStudentExamMutationVariables,
} from '../../../../graphql/mutations/student/submitAnswers/submitExamAnswer.generated';
import UPDATE_ANSWERS_MUTATION from '../../../../graphql/mutations/student/updateAnswers/updateAnswers';
import {
  UpdateStudentExamAnswersMutation,
  UpdateStudentExamAnswersMutationVariables,
} from '../../../../graphql/mutations/student/updateAnswers/updateAnswers.generated';
import { useSendEmailNotificationMutation } from '../../../../graphql/mutations/admin/exam/subscription/sendEmailNotification.generated';
import { ExamSubmitButtonDiv } from '../../../../styles/student/StudentExamPageStyles';
import { AnswerInput, ExamDetailsInput } from '../../../../types.generated';
import message from '../../../../components/dialog/message.json';
import {
  AnswerSheetInterface,
  generateAnswerSheet,
} from '../../../../utils/generateAnswerSheet';
import ExamDetails from '../../../../components/admin/viewExam/ExamDetails';
import SUBSCRIPTION_STATUS_QUERY from '../../../../graphql/queries/admin/exam/subscription/getSubscriptionStatus';

function AnswerableQuestions(props: {
  instanceId: string;
  examDetails: Exam;
  questions: ExamAnswerableQuestionInterface[];
  examStartTimestamp: Date;
  duration?: number;
  existingAnswers: AnswerSheetInterface[];
}) {
  const navigate = useNavigate();
  const params = useParams();
  const token = String(params.authToken);

  const [answers, setAnswers] = useState<AnswerSheetInterface[]>();
  const [questions, setQuestions] = useState<ExamAnswerableQuestionInterface[]>(
    []
  );
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setQuestions(props.questions);

    if (props.existingAnswers.length > 0) {
      setAnswers(props.existingAnswers);
    } else {
      const answerSheet = generateAnswerSheet(props.questions);
      setAnswers(answerSheet);
    }
  }, []);

  const [sendEmailNotification] = useSendEmailNotificationMutation();
  const [updateExamAnswers] = useMutation<
    UpdateStudentExamAnswersMutation,
    UpdateStudentExamAnswersMutationVariables
  >(UPDATE_ANSWERS_MUTATION);

  const updateAnswers = (addAnswer?: AnswerSheetInterface[]) => {
    updateExamAnswers({
      variables: {
        input: {
          id: props.instanceId,
          studentAnswers: addAnswer as AnswerInput[],
        },
      },
      onError: (errorObject: ApolloError) => {
        setError(errorObject.message);
      },
    });
  };

  const handleMCAnswers =
    (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const addAnswer = answers?.map((answer) => {
        return answer.questionId === id
          ? { ...answer, option: (event.target as HTMLInputElement).value }
          : answer;
      });
      setAnswers(addAnswer);
      updateAnswers(addAnswer);
    };

  const handleMSAnswers =
    (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const addAnswer = answers?.map((answer) => {
        if (answer.questionId === id) {
          //Checks to see if Option is to be removed or to be added
          if (answer.choices) {
            const indexExists = answer.choices.indexOf(event.target.value);
            if (indexExists > -1) {
              answer.choices?.splice(indexExists, 1);
              return answer;
            }
          }

          return {
            ...answer,
            choices: [...(answer.choices as string[]), event.target.value],
          };
        }
        return answer;
      });

      setAnswers(addAnswer);
      updateAnswers(addAnswer);
    };

  const getMatchingExistingAnswer = (item: ExamAnswerableQuestionInterface) => {
    const existingAnswer = props.existingAnswers.filter((answer) => {
      return answer.questionId === item.id;
    });

    return existingAnswer[0];
  };

  const [submitExamAnswer] = useMutation<
    SubmitStudentExamMutation,
    SubmitStudentExamMutationVariables
  >(SUBMIT_EXAM_ANSWERS_MUTATION);

  const submitExam = () => {
    const examEndTimestamp = new Date();
    console.log(props.examDetails);
    submitExamAnswer({
      variables: {
        input: {
          id: props.instanceId,
          examDetails: props.examDetails as ExamDetailsInput,
          studentAnswers: answers as AnswerInput[],
          examQuestions: questions,
          submittedAt: examEndTimestamp.toISOString(),
          token: token,
        },
      },
      onCompleted: (data: SubmitStudentExamMutation) => {
        if (data) {
          const examScore = data?.submitStudentExam?.score;
          const passed = data?.submitStudentExam?.passed;
          navigate(`/take/${props.examDetails.id}/${token}/score`, {
            state: {
              examDetails: props.examDetails,
              score: examScore,
              examStartTimestamp: props.examStartTimestamp,
              examEndTimestamp: examEndTimestamp,
              instanceId: props.instanceId,
              passed,
            },
          });
          // sendEmailNotification({
          //   variables: {
          //     input: {
          //       event: 'finished',
          //       examId: props.examDetails.id,
          //     },
          //   },
          // });
        }
      },
      refetchQueries: [{ query: SUBSCRIPTION_STATUS_QUERY }],
      onError: (errorObject: ApolloError) => {
        setError(errorObject.message);
      },
    });
  };

  useEffect(() => {
    if (props.examDetails.examType === 'FLEXIBLE' && props.duration) {
      const day = new Date(props.examStartTimestamp).getTime();
      const ONE_MIN_IN_MSEC = 60000;
      const durationInMsec = props.duration * ONE_MIN_IN_MSEC;
      const timeLeft = day + durationInMsec + 5000 - new Date().getTime();
      const timer = setTimeout(() => {
        submitExam();
      }, timeLeft);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [answers, questions]);

  const [openSaveModal, setOpenSaveModal] = useState(false);

  const handleOpenSave = () => {
    const unansweredQuestions = answers?.filter((answer) => {
      const { choices, option } = answer;
      if (choices) return choices.length === 0;
      return option === '';
    });

    const isExamValid = unansweredQuestions?.length === 0;

    if (isExamValid) {
      setOpenSaveModal(true);
    } else {
      alert(
        'Some questions are unanswered. Make sure to answer all of them before you submit!'
      );
    }
  };

  const handleCloseSave = () => {
    setOpenSaveModal(false);
  };

  const handleConfirmSave = () => {
    setOpenSaveModal(false);
    submitExam();
  };

  return (
    <>
      {questions?.map((question: ExamAnswerableQuestionInterface) => {
        return (
          <AnswerableQuestionComponent
            key={question.id}
            question={question.question}
            options={question.options as Option[]}
            choices={question.choices as Choice[]}
            type={question.questionType}
            existingAnswer={getMatchingExistingAnswer(question)}
            id={question.id}
            handleMCAnswerChange={handleMCAnswers(question.id)}
            handleMSAnswerChange={handleMSAnswers(question.id)}
          />
        );
      })}
      <ExamSubmitButtonDiv>
        {!error ? (
          <Button
            disableElevation
            variant="contained"
            sx={{
              textTransform: 'none',
              boxShadow: 0,
            }}
            onClick={handleOpenSave}
          >
            Submit
          </Button>
        ) : (
          <Typography color="red">{error}</Typography>
        )}
      </ExamSubmitButtonDiv>
      <CustomDialog
        isOpen={openSaveModal}
        handleClose={handleCloseSave}
        handleConfirm={handleConfirmSave}
        title={message.submitExam.title}
        content={message.submitExam.content}
      />
    </>
  );
}

export default AnswerableQuestions;
