import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DivStyle } from '../../../../styles/admin/QuestionsPageStyles';
import CustomDialog from '../../../dialog/CustomDialog';
import CustomDialogTitle from '../common/CustomDialogTitle';
import message from '../../../dialog/message.json';
import { PublishDialogProps } from './publishExamProps';
import {
  DivMaxQuestionStyle,
  DivPublishMax,
} from '../../../../styles/admin/ViewExamStyles';
import { useMutation } from '@apollo/client';
import { ExamType, InputMaybe } from '../../../../types.generated';
import UPDATE_PUBLISH_STATUS_AND_MAX_QUESTION_COUNT_MUTATION from '../../../../graphql/mutations/admin/exam/addMaxQuestionsAndPublish/addMaxQuestions';
import {
  UpdatePublishStatusAndMaxQuestionsCountMutation,
  UpdatePublishStatusAndMaxQuestionsCountMutationVariables,
} from '../../../../graphql/mutations/admin/exam/addMaxQuestionsAndPublish/addMaxQuestions.generated';

function PublishExamDialog({
  examId,
  examType,
  duration,
  startDate,
  endDate,
  questionsLength,
}: PublishDialogProps) {
  const matches = useMediaQuery('(max-width:600px)');

  const [openPublishExam, setOpenPublishExam] = useState(false);
  const [openUnsaveModal, setOpenUnsaveModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);

  const [maxQuestionsCount, setMaxQuestionsCount] = useState(0);

  const [updatePublishStatusAndMaxCount] = useMutation<
    UpdatePublishStatusAndMaxQuestionsCountMutation,
    UpdatePublishStatusAndMaxQuestionsCountMutationVariables
  >(UPDATE_PUBLISH_STATUS_AND_MAX_QUESTION_COUNT_MUTATION);

  const updateExam = () => {
    switch (examType) {
      case ExamType.Fixed:
        updatePublishStatusAndMaxCount({
          variables: {
            input: {
              id: examId,
              examType: examType,
              isPublished: true,
              maxQuestionCount: Number(maxQuestionsCount),
              startDate: startDate as InputMaybe<string>,
              endDate: endDate as InputMaybe<string>,
            },
          },
        });
        break;
      case ExamType.Flexible:
        updatePublishStatusAndMaxCount({
          variables: {
            input: {
              id: examId,
              examType: examType,
              isPublished: true,
              maxQuestionCount: Number(maxQuestionsCount),
              duration: duration as number,
            },
          },
        });
        break;
      case ExamType.Free:
        updatePublishStatusAndMaxCount({
          variables: {
            input: {
              id: examId,
              examType: examType,
              isPublished: true,
              maxQuestionCount: Number(maxQuestionsCount),
            },
          },
        });
        break;
      default:
        break;
    }
  };

  const handleMaxQuestionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMaxQuestionsCount(Number(event.target.value));
  };

  const handleOpenSave = () => {
    if (
      maxQuestionsCount !== 0 &&
      questionsLength &&
      maxQuestionsCount <= questionsLength
    ) {
      setOpenSaveModal(true);
    }
  };

  const handleCloseSave = () => {
    setOpenSaveModal(false);
  };

  const handleOpenUnsave = () => {
    setOpenUnsaveModal(true);
  };

  const handleCloseUnsave = () => {
    setOpenUnsaveModal(false);
  };

  const handleOpenPublishExam = () => {
    setOpenPublishExam(true);
  };

  const handleClosePublish = () => {
    handleOpenUnsave();
  };

  const handleConfirmSave = () => {
    updateExam();
    setOpenPublishExam(false);
    setOpenSaveModal(false);
  };

  const handleConfirmUnsave = () => {
    setOpenPublishExam(false);
    setOpenUnsaveModal(false);
  };

  const handleMaxClick = () => {
    setMaxQuestionsCount(Number(questionsLength));
  };

  return (
    <>
      <Button
        variant="contained"
        disableElevation
        onClick={handleOpenPublishExam}
        fullWidth={matches}
        data-testid="publish-button"
        sx={{
          color: '#FFFFFF',
          textTransform: 'none',
          boxShadow: 0,
          backgroundColor: '#539536',
        }}
      >
        Publish
      </Button>
      <Dialog
        open={openPublishExam}
        onClose={handleClosePublish}
        fullWidth
        fullScreen={matches}
        data-testid="publish-dialog"
      >
        <CustomDialogTitle
          text="Publish Exam"
          handleClose={handleClosePublish}
        />
        <DialogContent>
          <Box sx={{ height: '30px' }} />
          <DivMaxQuestionStyle>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              marginY={1}
            >
              <Typography
                sx={{ fontSize: 16 }}
                data-testid="max-questions-label"
              >
                No. of questions
              </Typography>
              <TextField
                type="number"
                label="Enter max questions the students will answer"
                required
                variant="outlined"
                sx={{ width: 400 }}
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: questionsLength as number,
                    'data-testid': 'max-questions',
                  },
                }}
                onChange={handleMaxQuestionChange}
                value={maxQuestionsCount}
              />
              <DivPublishMax onClick={handleMaxClick}>max</DivPublishMax>
            </Stack>
          </DivMaxQuestionStyle>
        </DialogContent>
        <DialogActions>
          <DivStyle>
            <Button
              variant="contained"
              disableElevation
              onClick={handleOpenSave}
              fullWidth={matches}
              data-testid="save-button"
              sx={{
                color: '#FFFFFF',
                textTransform: 'none',
                boxShadow: 0,
              }}
            >
              Publish
            </Button>
          </DivStyle>
        </DialogActions>
      </Dialog>
      <CustomDialog
        isOpen={openSaveModal}
        handleClose={handleCloseSave}
        handleConfirm={handleConfirmSave}
        title={message.publishExam.title}
        content={message.publishExam.content}
      />
      <CustomDialog
        isOpen={openUnsaveModal}
        handleClose={handleCloseUnsave}
        handleConfirm={handleConfirmUnsave}
        title={message.unsaveExam.title}
        content={message.unsaveExam.content}
      />
    </>
  );
}

export default PublishExamDialog;
