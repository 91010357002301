import { styled } from '@mui/material/styles';
import theme from '../../themes/theme';

export const ResourcesPageContainer = styled('div')(() => ({
  margin: '20px 200px 20px 200px',
  [theme.breakpoints.down('md')]: {
    margin: '20px 50px 20px 50px',
  },
}));

export const LogoImage = styled('img')(() => ({
  maxHeight: '150px',
  // maxWidth: '150px',
}));
