import { ExamAnswerableQuestionInterface } from '../pages/student/exam/examProps';

export interface AnswerSheetInterface {
  questionId: string;
  option?: string;
  choices?: string[];
}

export function generateAnswerSheet(
  questions: ExamAnswerableQuestionInterface[]
) {
  const answerSheet: AnswerSheetInterface[] = [];
  questions.forEach((question) => {
    switch (question.questionType) {
      case 'MULTIPLE_CHOICE_QUESTION':
        answerSheet.push({
          questionId: question.id,
          option: '',
        });
        break;
      case 'MULTIPLE_SELECTION_QUESTION':
        answerSheet.push({
          questionId: question.id,
          choices: [],
        });
        break;
      default:
        break;
    }
  });

  return answerSheet;
}
