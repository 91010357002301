import React from 'react';
import { Stack } from '@mui/material';
import { ResourcesPageContainer } from '../../styles/styledComponents/ResourcesPageStyles';
import ResourceHeader from '../../components/resources/ResourceHeader';
import ResourceSection from '../../components/resources/ResourceSection';
import data from './resourcesPagesTexts.json';

const PrivacyPolicyPage = () => {
  const introduction = data.texts[3].privacyPolicy?.introduction as string[];
  const digitalLogs = data.texts[3].privacyPolicy?.digitalLogs as string[];
  const cookieUse = data.texts[3].privacyPolicy?.cookieUse as string[];
  const sslEncryption = data.texts[3].privacyPolicy?.sslEncryption as string[];
  const optingOutOfCommunications = data.texts[3].privacyPolicy
    ?.optingOutOfCommunications as string[];
  const socialMedia = data.texts[3].privacyPolicy?.socialMedia as string[];
  const reTargetingAdvertising = data.texts[3].privacyPolicy
    ?.reTargetingAdvertising as string[];
  const publicForums = data.texts[3].privacyPolicy?.publicForums as string[];
  const copyrightNotice = data.texts[3].privacyPolicy
    ?.copyrightNotice as string[];
  const disclaimer = data.texts[3].privacyPolicy?.disclaimer as string[];
  const googleUserData = data.texts[3].privacyPolicy?.google as string[];

  return (
    <ResourcesPageContainer>
      <Stack direction="column" spacing={4}>
        <ResourceHeader title="Privacy Policy" />
        <ResourceSection title="" contents={introduction} />
        <ResourceSection
          title="Digital Logs And Analytics"
          contents={digitalLogs}
        />
        <ResourceSection title="Cookie Use" contents={cookieUse} />
        <ResourceSection
          title="Secure Socket Layer (SSL) Encryption"
          contents={sslEncryption}
        />
        <ResourceSection
          title="Opting Out Of Communications"
          contents={optingOutOfCommunications}
        />
        <ResourceSection title="Social Media" contents={socialMedia} />
        <ResourceSection
          title="Re-targeting Advertising"
          contents={reTargetingAdvertising}
        />
        <ResourceSection title="Public Forums" contents={publicForums} />
        <ResourceSection title="Copyright Notice" contents={copyrightNotice} />
        <ResourceSection title="Disclaimer" contents={disclaimer} />
        <ResourceSection title="Google User Data" contents={googleUserData} />
      </Stack>
    </ResourcesPageContainer>
  );
};

export default PrivacyPolicyPage;
