import { gql } from '@apollo/client';

export const GOOGLE_SIGN_IN_MUTATION = gql`
  mutation GoogleSignIn($input: GoogleSignInInput) {
    googleSignIn(input: $input) {
      firstName
      lastName
      email
      roles
    }
  }
`;

export default GOOGLE_SIGN_IN_MUTATION;
