import React from 'react';
import { useGetUserQuery } from '../../../graphql/queries/user/user.generated';
import ErrorPage from '../../states/ErrorPage';
import LoadingPage from '../../states/LoadingPage';

const getExamAuthor = (authorId: string) => {
  const {
    data: author,
    loading: loadingAuthor,
    error: errorAuthor,
  } = useGetUserQuery({
    variables: {
      id: authorId,
    },
  });

  if (loadingAuthor) return <LoadingPage />;
  if (errorAuthor) return <ErrorPage errorMessage={errorAuthor.message} />;

  const authorFirstName = author?.user?.firstName as string;
  const authorLastName = author?.user?.lastName as string;

  return `${authorFirstName} ${authorLastName}`;
};

export default getExamAuthor;
