import React, { useState } from 'react';
import { Divider, IconButton, MenuItem, Typography } from '@mui/material';
import { ExamHeaderDiv } from '../../../styles/admin/ViewExamStyles';
import { formatProgramText } from '../../../utils/wordFormatter';
import { ExamHeaderProps } from './viewExamComponentsProps';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CustomDialog from '../../dialog/CustomDialog';
import message from '../../dialog/message.json';
import PublishExamDialog from '../../modal/admin/PublishExam/PublishExamDialog';
import UnpublishExamDialog from '../../modal/admin/PublishExam/UnpublishExamDialog';
import { ExamType } from '../../../types.generated';
import Tag from '../../common/ChipTags';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LinkIcon from '@mui/icons-material/Link';
import ExamLinkModal from '../../modal/admin/ExamLink/ExamLinkModal';
import generateExamLink from '../../../utils/examLinkGenerator';
import { StyledMenu } from '../../../styles/styledComponents/MenuStyles';

function ExamHeader({
  title,
  examId,
  program,
  module,
  year,
  quarter,
  questionsLength,
  isPublished,
  examType,
  startDate,
  endDate,
  duration,
  handleEdit,
  handleDelete,
}: ExamHeaderProps) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDelete = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDelete = () => {
    setOpenDeleteModal(false);
  };

  return (
    <>
      <ExamHeaderDiv>
        <ExamHeaderDiv>
          <Typography
            color="secondary.main"
            variant="h6"
            fontWeight="fontWeightBold"
          >
            {title}
          </Typography>
          <Tag label={formatProgramText(program)} mRight={1} mLeft={3} />
          <Tag label={module} mRight={1} mLeft={0} />
          <Tag label={year.toString() + quarter} mRight={0} mLeft={0} />
        </ExamHeaderDiv>
        <ExamHeaderDiv>
          {isPublished ? (
            <UnpublishExamDialog
              examId={examId}
              examType={examType as ExamType}
              duration={duration as number}
              startDate={startDate as number}
              endDate={endDate as number}
            />
          ) : (
            <PublishExamDialog
              questionsLength={questionsLength}
              examId={examId}
              examType={examType as ExamType}
              duration={duration as number}
              startDate={startDate as number}
              endDate={endDate as number}
            />
          )}
          <div>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <StyledMenu
              id="customized-menu"
              MenuListProps={{
                'aria-labelledby': 'customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem href="" disableRipple onClick={handleEdit}>
                <EditOutlinedIcon />
                Edit
              </MenuItem>
              <MenuItem
                href=""
                disableRipple
                onClick={() => {
                  handleOpenDelete();
                  handleClose();
                }}
              >
                <DeleteOutlineIcon />
                Delete
              </MenuItem>
              <Divider sx={{ my: 0.5 }} />
              <MenuItem
                disableRipple
                onClick={() => {
                  setOpenLinkModal(true);
                  handleClose();
                }}
              >
                <LinkIcon />
                Get link
              </MenuItem>
            </StyledMenu>
          </div>
          <ExamLinkModal
            isOpen={openLinkModal}
            handleClose={() => setOpenLinkModal(false)}
            examLink={generateExamLink(examId)}
          />
        </ExamHeaderDiv>
      </ExamHeaderDiv>
      <CustomDialog
        isOpen={openDeleteModal}
        handleClose={handleCloseDelete}
        handleConfirm={handleDelete}
        title={message.deleteExam.title}
        content={message.deleteExam.content}
      />
    </>
  );
}

export default ExamHeader;
