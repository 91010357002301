/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
  TableRow,
} from '@mui/material';
import ExamsListRow from './ExamsListRow';
import { StyledTableCell } from '../../../styles/admin/ExamsListStyles';
import EmptyDataComponent from '../../states/componentStates/EmptyDataComponent';
import TablePagination from '../../common/TablePagination';
import { ExamsListInterface } from './examsListInterface';

interface ExamsListType {
  exams: Array<ExamsListInterface>;
  examTableTitle: string;
  pageNum: number;
  onNext: React.MouseEventHandler<HTMLButtonElement>;
  onPrev: React.MouseEventHandler<HTMLButtonElement>;
  nextDisabled: boolean;
}

const ExamsListTable = (props: ExamsListType) => {
  const { exams, examTableTitle, pageNum, onNext, onPrev, nextDisabled } =
    props;

  return (
    <Paper sx={{ boxShadow: 'none' }}>
      <TableContainer sx={{ marginTop: 2 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <StyledTableCell>{examTableTitle}</StyledTableCell>
            </TableRow>
          </TableHead>
          {exams.length > 0 ? (
            <TableBody sx={{ backgroundColor: '#ffffff' }}>
              {exams.map((exam: ExamsListInterface) => (
                <ExamsListRow key={exam.id} exam={exam} />
              ))}
            </TableBody>
          ) : (
            <EmptyDataComponent message="No exams have been added" />
          )}
        </Table>
      </TableContainer>
      <TablePagination
        pageNum={pageNum}
        buttonDisabled={nextDisabled}
        handleNext={onNext}
        handlePrev={onPrev}
      />
    </Paper>
  );
};

export default ExamsListTable;
