import { styled } from '@mui/material';
import theme from '../../themes/theme';

export const WelcomeBackContainer = styled('div')(() => ({
  alignItems: 'center',
  justifyContent: 'start',
  display: 'flex',
  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column',
    alignItems: 'start',
  },
}));
