import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Dialog,
  DialogContent,
  DialogActions,
  useMediaQuery,
  Grid,
  FormControl,
  FormGroup,
  FormControlLabel,
  Typography,
  Box,
  Button,
  Checkbox,
} from '@mui/material';
import QuestionTypeHeader from '../../common/QuestionTypeHeader';
import EditQuestionTextField from '../components/EditQuestionTextField';
import EditAnswerTextField from '../components/EditAnswerTextField';
import EditProgram from '../components/EditProgram';
import EditModuleTextField from '../components/EditModuleTextField';
import NewAnswerTextField from '../components/NewAnswerTextField';
import CustomDialog from '../../../../dialog/CustomDialog';
import CustomDialogTitle from '../../common/CustomDialogTitle';
import QuestionActionButton from '../../common/QuestionActionButton';
import {
  DivStyle,
  ModuleProgramDivStyle,
  StyledDiv,
  StyledGridItem,
  StyledOptionDiv,
} from '../../../../../styles/admin/QuestionsPageStyles';
import { EditDialogProps } from '../editQuestionProps';
import { QuestionAnswerInterface } from '../../../../../pages/admin/questionBank/QuestionInterface';
import generateId from '../../../../../utils/idGenerator';
import message from '../../../../dialog/message.json';
import { BottomScrollBox } from '../../../../../styles/styledComponents/ModalStyles';
import { UPDATE_MS_QUESTION_MUTATION } from '../../../../../graphql/mutations/admin/multipleSelection/question/updateMultipleSelectionQuestion';
import {
  UpdateMultipleSelectionQuestionMutation,
  UpdateMultipleSelectionQuestionMutationVariables,
} from '../../../../../graphql/mutations/admin/multipleSelection/question/updateMultipleSelectionQuestion.generated';
import { UPDATE_MS_CHOICE_MUTATION } from '../../../../../graphql/mutations/admin/multipleSelection/choice/updateMultipleSeectionChoice';
import {
  UpdateMultipleSelectionChoiceMutation,
  UpdateMultipleSelectionChoiceMutationVariables,
} from '../../../../../graphql/mutations/admin/multipleSelection/choice/updateMultipleSeectionChoice.generated';
import { DELETE_MS_CHOICE_MUTATION } from '../../../../../graphql/mutations/admin/multipleSelection/choice/deleteMultipleSelectionChoice';
import {
  DeleteMultipleSelectionChoiceMutation,
  DeleteMultipleSelectionChoiceMutationVariables,
} from '../../../../../graphql/mutations/admin/multipleSelection/choice/deleteMultipleSelectionChoice.generated';
import { GetMultipleSelectionQuestionQuery } from '../../../../../graphql/queries/admin/multipleSelection/getMultipleSelectionQuestion.generated';
import { GET_MS_QUESTION_QUERY } from '../../../../../graphql/queries/admin/multipleSelection/getMultipleSelectionQuestion';
import {
  CreateMultipleSelectionChoiceMutation,
  CreateMultipleSelectionChoiceMutationVariables,
} from '../../../../../graphql/mutations/admin/multipleSelection/choice/createMultipleSelectionChoice.generated';
import { CREATE_MS_CHOICE_MUTATION } from '../../../../../graphql/mutations/admin/multipleSelection/choice/createMultipleSelectionChoice';
import { programs } from '../../../../../constants';
import { Program } from '../../../../../types.generated';

let mockIds: string[] = [];
const deletedIds: string[] = [];

function EditMSQModal({
  program,
  module,
  questionId,
  questionType,
  question,
  possibleAnswers,
}: EditDialogProps) {
  const matches = useMediaQuery('(max-width:600px)');

  const [programValue, setProgramValue] = useState(program as string);
  const [moduleValue, setModuleValue] = useState(module);
  const [questionValue, setQuestionValue] = useState(question);
  const [newChoice, setNewChoice] = useState('');
  const [choices, setChoices] = useState(possibleAnswers);

  const [openEditQuestion, setOpenEditQuestion] = useState(false);
  const [openUnsaveModal, setOpenUnsaveModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);

  const handleOpenUnsave = () => {
    setOpenUnsaveModal(true);
  };

  const handleCloseUnsave = () => {
    setOpenUnsaveModal(false);
  };

  const handleOpenQuestion = () => {
    setOpenEditQuestion(true);
  };

  const handleCloseEdit = () => {
    handleOpenUnsave();
  };

  const handleConfirmUnsave = () => {
    setModuleValue(module);
    setProgramValue(program);
    setQuestionValue(question);
    setNewChoice('');
    setChoices(possibleAnswers);
    setOpenEditQuestion(false);
    setOpenUnsaveModal(false);
    if (deletedIds.length > 0) deletedIds.splice(0, deletedIds.length);
    if (mockIds.length > 0) mockIds.splice(0, mockIds.length);
  };

  const handleOpenSave = () => {
    setOpenSaveModal(true);
  };

  const handleCloseSave = () => {
    setOpenSaveModal(false);
  };

  const handleProgramChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProgramValue(event.target.value);
  };

  const handleModuleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModuleValue(e.target.value);
  };

  const handleQuestionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuestionValue(e.target.value);
  };

  const handleNewChoice = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewChoice(event.target.value);
  };

  const resetNewchoiceField = () => {
    setNewChoice('');
  };

  // handle each choice edit
  const handleChoiceTextChange =
    (changedChoice: QuestionAnswerInterface) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const forUpdatedList = choices.map((choice: QuestionAnswerInterface) => {
        return choice.id === changedChoice.id
          ? { ...choice, description: e.target.value }
          : choice;
      });

      setChoices(forUpdatedList);
    };

  const removeChoice = (removedId: string) => {
    const forUpdatedList = choices.filter((choice: QuestionAnswerInterface) => {
      return choice.id !== removedId;
    });

    setChoices(forUpdatedList);

    // filter list to check which choices from the original list are deleted
    const removedFromList = choices.filter(
      (choice: QuestionAnswerInterface) => {
        return choice.id === removedId && !mockIds.includes(choice.id);
      }
    );

    // push the id of the deleted choice
    if (removedFromList.length > 0)
      deletedIds.push(removedFromList[0].id as string);

    // remove new added choice mock id
    mockIds = mockIds.filter((id) => id !== removedId);
  };

  const chooseCorrectAnswer =
    (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;

      const forUpdatedList = choices.map((choice: QuestionAnswerInterface) => {
        return id === choice.id ? { ...choice, isCorrect: isChecked } : choice;
      });

      setChoices(forUpdatedList);
    };

  const [updateMSQuestion] = useMutation<
    UpdateMultipleSelectionQuestionMutation,
    UpdateMultipleSelectionQuestionMutationVariables
  >(UPDATE_MS_QUESTION_MUTATION);

  const updateQuestion = () => {
    updateMSQuestion({
      variables: {
        input: {
          id: questionId,
          program: programValue as Program,
          module: moduleValue,
          question: questionValue,
        },
      },
    });
  };

  const [updateMSChoice] = useMutation<
    UpdateMultipleSelectionChoiceMutation,
    UpdateMultipleSelectionChoiceMutationVariables
  >(UPDATE_MS_CHOICE_MUTATION);

  const updateChoiceList = () => {
    const updatedFromOldList = choices.filter(
      (choice: QuestionAnswerInterface) => {
        return !mockIds.includes(choice.id as string);
      }
    );

    for (const updatedChoice of updatedFromOldList) {
      updateMSChoice({
        variables: {
          input: {
            id: updatedChoice.id as string,
            description: updatedChoice.description as string,
            isCorrect: updatedChoice.isCorrect as boolean,
          },
        },
      });
    }
  };

  const [deleteMSChoice] = useMutation<
    DeleteMultipleSelectionChoiceMutation,
    DeleteMultipleSelectionChoiceMutationVariables
  >(DELETE_MS_CHOICE_MUTATION, {
    update(cache) {
      const existingChoices =
        cache.readQuery<GetMultipleSelectionQuestionQuery>({
          query: GET_MS_QUESTION_QUERY,
        });
      if (
        existingChoices &&
        existingChoices.multipleSelectionQuestion &&
        existingChoices.multipleSelectionQuestion?.choices
      ) {
        const newChoices =
          existingChoices.multipleSelectionQuestion?.choices.filter(
            (existingChoice) =>
              deletedIds.forEach((o) => existingChoice?.id !== o)
          );
        cache.writeQuery<GetMultipleSelectionQuestionQuery>({
          query: GET_MS_QUESTION_QUERY,
          data: {
            multipleSelectionQuestion: {
              __typename: 'MultipleSelectionQuestion',
              id: questionId,
              question: question,
              choices: newChoices,
            },
          },
        });
      }
    },
  });

  const deleteChoicesList = () => {
    if (deletedIds.length > 0) {
      for (const id of deletedIds) {
        deleteMSChoice({
          variables: {
            input: {
              id,
            },
          },
        });
      }
    }
  };

  const [createMSChoice] = useMutation<
    CreateMultipleSelectionChoiceMutation,
    CreateMultipleSelectionChoiceMutationVariables
  >(CREATE_MS_CHOICE_MUTATION);

  const createNewChoiceList = () => {
    const addedChoices = choices.filter((choice: QuestionAnswerInterface) => {
      return mockIds.includes(choice.id as string);
    });

    for (const choice of addedChoices) {
      createMSChoice({
        variables: {
          input: {
            multipleSelectionQuestionId: questionId,
            description: choice.description as string,
            isCorrect: choice.isCorrect as boolean,
          },
        },
      });
    }
  };

  const handleConfirmSave = () => {
    setOpenEditQuestion(false);
    setOpenSaveModal(false);
    updateQuestion();
    updateChoiceList();
    deleteChoicesList();
    createNewChoiceList();
  };

  return (
    <>
      <QuestionActionButton
        handleClick={handleOpenQuestion}
        isMatch={matches}
        label="Edit Question"
      />
      <Dialog
        open={openEditQuestion}
        onClose={handleCloseEdit}
        maxWidth={matches ? 'sm' : 'md'}
        fullWidth
        fullScreen={matches}
        data-testid="edit-msq"
      >
        <CustomDialogTitle text="Edit Question" handleClose={handleCloseEdit} />
        <DialogContent>
          <Box sx={{ height: '30px' }} />
          <QuestionTypeHeader questionType={questionType} />
          <ModuleProgramDivStyle>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <EditProgram
                  program={programValue}
                  programs={programs}
                  onChange={handleProgramChange}
                />
              </Grid>
              <Grid item xs={6}>
                <EditModuleTextField
                  textValue={moduleValue}
                  onChange={handleModuleChange}
                />
              </Grid>
            </Grid>
          </ModuleProgramDivStyle>
          <DivStyle>
            <EditQuestionTextField
              textValue={question}
              onChange={handleQuestionChange}
            />
          </DivStyle>
          <StyledDiv>
            <Grid container>
              <StyledGridItem item sm={12} md={12} container>
                <Typography
                  sx={{ mt: 2, fontSize: matches ? 14 : 16, fontWeight: 600 }}
                >
                  Choices
                </Typography>
              </StyledGridItem>
              <StyledGridItem item sm={12} md={12}>
                <FormControl>
                  <FormGroup>
                    <BottomScrollBox>
                      <div>
                        {choices?.map(
                          (answer: QuestionAnswerInterface, index: number) => {
                            return (
                              <StyledOptionDiv key={answer.id}>
                                <FormControlLabel
                                  data-testid={`checkbox-${index + 1}`}
                                  control={
                                    <Checkbox
                                      checked={answer.isCorrect as boolean}
                                      onChange={chooseCorrectAnswer(
                                        answer.id as string
                                      )}
                                    />
                                  }
                                  label={
                                    <EditAnswerTextField
                                      textValue={answer?.description as string}
                                      onChange={handleChoiceTextChange(answer)}
                                      removeFunction={() => {
                                        removeChoice(answer.id as string);
                                      }}
                                    />
                                  }
                                />
                              </StyledOptionDiv>
                            );
                          }
                        )}
                      </div>
                    </BottomScrollBox>
                  </FormGroup>
                </FormControl>
              </StyledGridItem>
            </Grid>
          </StyledDiv>
          <StyledDiv>
            <NewAnswerTextField
              optionChange={handleNewChoice}
              optionValue={newChoice}
              handleClick={() => {
                const mockId: string = generateId();
                mockIds.push(mockId);

                const newList = choices.concat({
                  id: mockId,
                  description: newChoice,
                  isCorrect: false,
                });

                setChoices(newList);

                resetNewchoiceField();
              }}
            />
          </StyledDiv>
        </DialogContent>
        <DialogActions>
          <DivStyle>
            <Button
              variant="contained"
              disableElevation
              onClick={handleOpenSave}
              fullWidth={matches}
              data-testid="save-button"
              sx={{
                color: '#FFFFFF',
                textTransform: 'none',
                boxShadow: 0,
              }}
            >
              Save Question
            </Button>
            <CustomDialog
              isOpen={openSaveModal}
              handleClose={handleCloseSave}
              handleConfirm={handleConfirmSave}
              title={message.save.title}
              content={message.save.content}
            />
          </DivStyle>
          <CustomDialog
            isOpen={openUnsaveModal}
            handleClose={handleCloseUnsave}
            handleConfirm={handleConfirmUnsave}
            title={message.unsave.title}
            content={message.unsave.content}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditMSQModal;

EditMSQModal.propTypes = {
  possibleAnswers: PropTypes.array,
};
