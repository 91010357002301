import React from 'react';
import QuestionModal from '../QuestionModal';
import { MCDialogProps } from '../questionModalProps';
import {
  QuestionAnswerInterface,
  UserInterface,
} from '../../../../../pages/admin/questionBank/QuestionInterface';
import { Program } from '../../../../../types.generated';

function MCQuestionModal({ data, author }: MCDialogProps) {
  const id: string = data?.multipleChoiceQuestion?.id ?? '';
  const program: Program = data?.multipleChoiceQuestion?.program as Program;
  const module: string = data?.multipleChoiceQuestion?.module as string;
  const questionType =
    data?.multipleChoiceQuestion?.__typename ?? 'MultipleChoiceQuestion';
  const question: string = data?.multipleChoiceQuestion?.question ?? '';
  const questionOptions: QuestionAnswerInterface[] = data
    ?.multipleChoiceQuestion?.options as QuestionAnswerInterface[];
  const createdAt: Date = data?.multipleChoiceQuestion?.createdAt as Date;

  const user: UserInterface = author.user as UserInterface;
  const firstName: string = user.firstName;
  const lastName: string = user.lastName;
  const createdBy = `${firstName} ${lastName}`;

  return (
    <QuestionModal
      questionId={id}
      program={program}
      module={module}
      questionType={questionType}
      question={question}
      possibleAnswers={questionOptions}
      author={createdBy}
      createdAt={createdAt}
    />
  );
}
export default MCQuestionModal;
