import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  CssBaseline,
  Fab,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useScrollTrigger,
  Zoom,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { LoadingButton } from '@mui/lab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HeaderButton from '../../components/header/HeaderButton';
import { ResourceType, ScrollType } from './headerTypes';
import {
  AppBarStyle,
  LogoStyle,
} from '../../styles/styledComponents/HeaderStyles';
import data from './menuData.json';
import headerLogo from '../../assets/merkle_trees_full_color.png';
import { useMutation } from '@apollo/client';
import SIGN_OUT_MUTATION from '../../graphql/mutations/auth/signOut';
import {
  getUser,
  getUserName,
  getUserRoles,
  removeUser,
} from '../../routes/userInfo/user';

const ScrollTop = (props: ScrollType) => {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector('#back-to-top-anchor');

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
};

const Logo = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <Button
        disableRipple
        sx={{
          textTransform: 'none',
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent',
          },
        }}
        onClick={() => navigate('/')}
      >
        <LogoStyle src={headerLogo} alt="logo" />
      </Button>
    </Box>
  );
};

const ResourceMenu = () => {
  const navigate = useNavigate();
  const resourcesMenu: ResourceType[] = data.menu as ResourceType[];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (link: string) => {
    navigate(link);
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
      <Button
        sx={{
          color: '#9F9F9F',
          textTransform: 'none',
        }}
        onClick={handleClick}
        data-testid="resources-button"
      >
        Resources
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={1}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {resourcesMenu.map((resource, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => handleMenu(resource.path)}
              className="menu-label"
            >
              {resource.label}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

const MobileView = () => {
  const navigate = useNavigate();
  const resourcesMenu: ResourceType[] = data.menu as ResourceType[];
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenu = (link: string) => {
    navigate(link);
    setAnchorElNav(null);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: { xs: 'flex', md: 'none' },
        justifyContent: 'end',
      }}
    >
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: 'block', md: 'none' },
        }}
      >
        {resourcesMenu.map((resource, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => handleMenu(resource.path)}
              className="menu-label"
            >
              {resource.label}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

function Header() {
  const navigate = useNavigate();
  const isAuthenticated = getUser();
  const roles = getUserRoles();
  const name = getUserName();

  const [signOutUser] = useMutation(SIGN_OUT_MUTATION);

  const [loading, setLoading] = useState(false);

  const handleSignOut = () => {
    setLoading(true);
    setTimeout(() => {
      signOutUser();
      removeUser();
      navigate('/');
    }, 2000);
  };

  return (
    <>
      <CssBaseline />
      <AppBarStyle position="fixed" elevation={0}>
        <Toolbar
          sx={{
            paddingX: 0,
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            height: '64px',
            alignItems: 'center',
          }}
        >
          <Box sx={{ width: '30%' }}>
            <Logo />
          </Box>
          {isAuthenticated ? (
            <>
              <Box
                sx={{
                  width: '30%',
                  display: { xs: 'none', md: 'flex' },
                  justifyContent: 'center',
                }}
              >
                <HeaderButton
                  isMainMenu={true}
                  label="Home"
                  handleClick={() => navigate('/')}
                  testId="home-menu-button"
                />
                <HeaderButton
                  isMainMenu={true}
                  label="Dashboard"
                  handleClick={() => navigate('/candidatedashboard')}
                  testId="dashboard-menu-button"
                />
              </Box>
              <Box
                sx={{
                  width: '30%',
                  display: 'flex',
                  justifyContent: 'end',
                  gap: '8px',
                }}
              >
                <Box
                  sx={{
                    paddingRight: '8px',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      textAlign: 'end',
                    }}
                  >
                    {name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      textTransform: 'lowercase',
                      color: '#808080',
                      textAlign: 'end',
                    }}
                  >
                    {roles?.join('-')}
                  </Typography>
                </Box>
                <MobileView />

                <LoadingButton
                  variant="outlined"
                  sx={{
                    textTransform: 'none',
                    lineHeight: '20px',
                    display: { xs: 'none', md: 'flex' },
                  }}
                  loading={loading}
                  onClick={() => {
                    handleSignOut();
                  }}
                >
                  Logout
                </LoadingButton>
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  width: '30%',
                  display: 'flex',
                  justifyContent: 'end',
                  gap: '4px',
                }}
              >
                <MobileView />
                <ResourceMenu />
                <HeaderButton
                  label="Sign In"
                  handleClick={() => {}}
                  redirectLink="/signin"
                  testId="login-button"
                />
              </Box>
            </>
          )}
        </Toolbar>
      </AppBarStyle>
      <Toolbar id="back-to-top-anchor" />
      <ScrollTop>
        <Fab color="primary" size="small">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </>
  );
}

export default Header;
