/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from 'react';
import AdminsListTable from './AdminsListTable';
import { useAllAdminsQuery } from '../../../graphql/queries/admin/admin/getPaginatedAdmins.generated';
import TableLoadingState from '../../states/componentStates/TableLoadingState';
import ErrorPage from '../../states/ErrorPage';
import { RefetchContext } from './adminManagementContext';
import { Grid } from '@mui/material';
import AddAdminForm from './AddAdminForm';

export interface AdminsInterface {
  id: string;
  email: string;
}

const AdminsList = () => {
  const {
    data: paginatedAdmins,
    loading: paginatedAdminsLoading,
    error: paginatedAdminsError,
    fetchMore,
    refetch,
  } = useAllAdminsQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      first: 10,
      last: null,
      after: null,
      before: null,
    },
  });
  const [page, setPage] = useState(1);

  const endCursor = paginatedAdmins?.adminsConnection?.pageInfo.endCursor;
  const startCursor = paginatedAdmins?.adminsConnection?.pageInfo.startCursor;
  const hasPrevPage =
    paginatedAdmins?.adminsConnection?.pageInfo.hasPreviousPage;
  const hasNextPage = paginatedAdmins?.adminsConnection?.pageInfo.hasNextPage;

  const moreResults = () => {
    fetchMore({
      variables: {
        first: 10,
        last: null,
        after: endCursor,
        before: null,
      },
    });
  };

  const prevResults = () => {
    fetchMore({
      variables: {
        first: null,
        last: 10,
        after: null,
        before: startCursor,
      },
    });
  };

  const [disableButton, setDisableButton] = useState<boolean>(!hasNextPage);

  useEffect(() => {
    if (hasNextPage) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [hasNextPage]);

  const handleClickNext = () => {
    if (hasNextPage) {
      moreResults();
    }
    setPage(page + 1);
  };

  const handleClickPrev = () => {
    if (hasPrevPage) {
      prevResults();
      setPage(page - 1);
      setDisableButton(false);
    }
  };

  useEffect(() => {
    if (!hasPrevPage && page !== 1) {
      setPage(1);
    }
  }, [paginatedAdmins]);

  if (paginatedAdminsLoading) return <TableLoadingState />;
  if (paginatedAdminsError)
    return <ErrorPage errorMessage={paginatedAdminsError.message} />;

  const admins: AdminsInterface[] = paginatedAdmins?.adminsConnection
    ?.nodes as unknown as AdminsInterface[];

  return (
    <RefetchContext.Provider value={refetch}>
      <Grid item xs={12} md={6}>
        <AdminsListTable
          admins={admins}
          adminTableTitle="Admins"
          pageNum={page}
          onNext={handleClickNext}
          onPrev={handleClickPrev}
          nextDisabled={disableButton}
        />
      </Grid>
      <Grid item justifyContent="flex-start">
        <AddAdminForm />
      </Grid>
    </RefetchContext.Provider>
  );
};

export default AdminsList;
