import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IsExamLinkValidMutationVariables = Types.Exact<{
  input: Types.CheckExamInput;
}>;


export type IsExamLinkValidMutation = { __typename?: 'Mutation', isExamLinkValid?: boolean | null };


export const IsExamLinkValidDocument = gql`
    mutation IsExamLinkValid($input: CheckExamInput!) {
  isExamLinkValid(input: $input)
}
    `;
export type IsExamLinkValidMutationFn = Apollo.MutationFunction<IsExamLinkValidMutation, IsExamLinkValidMutationVariables>;

/**
 * __useIsExamLinkValidMutation__
 *
 * To run a mutation, you first call `useIsExamLinkValidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIsExamLinkValidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [isExamLinkValidMutation, { data, loading, error }] = useIsExamLinkValidMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIsExamLinkValidMutation(baseOptions?: Apollo.MutationHookOptions<IsExamLinkValidMutation, IsExamLinkValidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IsExamLinkValidMutation, IsExamLinkValidMutationVariables>(IsExamLinkValidDocument, options);
      }
export type IsExamLinkValidMutationHookResult = ReturnType<typeof useIsExamLinkValidMutation>;
export type IsExamLinkValidMutationResult = Apollo.MutationResult<IsExamLinkValidMutation>;
export type IsExamLinkValidMutationOptions = Apollo.BaseMutationOptions<IsExamLinkValidMutation, IsExamLinkValidMutationVariables>;