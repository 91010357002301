import React from 'react';
import { Stack, Typography } from '@mui/material';
import Countdown, { CountdownApi } from 'react-countdown';

const timerTextStyle = { fontSize: 16 };

const ExamTimer = (props: { duration: number; openedAt: Date }) => {
  const { duration, openedAt } = props;
  const day = new Date(openedAt).getTime();
  const ONE_MIN_IN_MSEC = 60000;
  const durationInMsec = duration * ONE_MIN_IN_MSEC;

  const OnComplete = () => (
    <Typography
      fontWeight="fontWeightMedium"
      sx={{ fontSize: 16, color: '#ef5350' }}
    >
      Time is up!
    </Typography>
  );

  const renderer = (timer: {
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
    api: CountdownApi;
  }) => {
    if (timer.completed) {
      return <OnComplete />;
    } else {
      return (
        <Stack direction="row" spacing={0.5}>
          {timer.hours !== 0 && (
            <>
              <Typography fontWeight="fontWeightMedium" sx={timerTextStyle}>
                {timer.hours}
              </Typography>
              <Typography sx={timerTextStyle}>
                {timer.hours <= 1 ? 'hr' : 'hrs'}
              </Typography>
            </>
          )}
          <Typography fontWeight="fontWeightMedium" sx={timerTextStyle}>
            {timer.minutes}
          </Typography>
          <Typography sx={timerTextStyle}>
            {timer.minutes <= 1 ? 'min' : 'mins'}
          </Typography>
          <Typography fontWeight="fontWeightMedium" sx={timerTextStyle}>
            {timer.seconds}
          </Typography>
          <Typography sx={timerTextStyle}>
            {timer.seconds <= 1 ? 'sec' : 'secs'}
          </Typography>
        </Stack>
      );
    }
  };

  return <Countdown date={day + durationInMsec} renderer={renderer} />;
};

export default ExamTimer;
