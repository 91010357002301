/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from 'react';
import { useAllExamsQuery } from '../../../graphql/queries/admin/exam/getPaginatedExams.generated';
import { ExamsListInterface } from './examsListInterface';
import ExamsListTable from './ExamsListTable';
import TableLoadingState from '../../states/componentStates/TableLoadingState';
import ErrorPage from '../../states/ErrorPage';

const ExamBank = () => {
  const {
    data: paginatedExams,
    loading: paginatedExamsLoading,
    error: paginatedExamsError,
    fetchMore,
  } = useAllExamsQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      first: 10,
      last: null,
      after: null,
      before: null,
    },
    pollInterval: 2000,
  });
  const [page, setPage] = useState(1);

  const endCursor = paginatedExams?.examsConnection?.pageInfo.endCursor;
  const startCursor = paginatedExams?.examsConnection?.pageInfo.startCursor;
  const hasPrevPage = paginatedExams?.examsConnection?.pageInfo.hasPreviousPage;
  const hasNextPage = paginatedExams?.examsConnection?.pageInfo.hasNextPage;

  const moreResults = () => {
    fetchMore({
      variables: {
        first: 10,
        last: null,
        after: endCursor,
        before: null,
      },
    });
  };

  const prevResults = () => {
    fetchMore({
      variables: {
        first: null,
        last: 10,
        after: null,
        before: startCursor,
      },
    });
  };

  const [disableButton, setDisableButton] = useState<boolean>(!hasNextPage);

  useEffect(() => {
    if (hasNextPage) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [hasNextPage]);

  const handleClickNext = () => {
    if (hasNextPage) {
      moreResults();
    }
    setPage(page + 1);
  };

  const handleClickPrev = () => {
    if (hasPrevPage) {
      prevResults();
      setPage(page - 1);
      setDisableButton(false);
    }
  };

  useEffect(() => {
    if (!hasPrevPage && page !== 1) {
      setPage(1);
    }
  }, [paginatedExams]);

  if (paginatedExamsLoading) return <TableLoadingState />;
  if (paginatedExamsError)
    return <ErrorPage errorMessage={paginatedExamsError.message} />;

  const exams: ExamsListInterface[] = paginatedExams?.examsConnection
    ?.nodes as unknown as ExamsListInterface[];

  return (
    <ExamsListTable
      exams={exams}
      examTableTitle="Exams"
      pageNum={page}
      onNext={handleClickNext}
      onPrev={handleClickPrev}
      nextDisabled={disableButton}
    />
  );
};

export default ExamBank;
