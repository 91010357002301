import React from 'react';
import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { QuestionComponentDetailsProps } from '../viewExamComponentsProps';

function QuestionComponentDetails({
  questionContent,
  details,
}: QuestionComponentDetailsProps) {
  return (
    <div style={{ paddingTop: '30px' }}>
      <Card
        elevation={0}
        sx={{
          width: '100%',
          border: '1px solid #DBD6D6',
        }}
      >
        <CardHeader
          title={
            <Typography
              component="pre"
              sx={{
                fontSize: '14px',
                color: 'secondary.main',
                fontWeight: 'bold',
              }}
            >
              {questionContent}
            </Typography>
          }
          sx={{
            paddingTop: '5px',
            paddingBottom: '5px',
          }}
        />
        <CardContent
          sx={{
            backgroundColor: 'white',
            paddingBottom: '5px',
          }}
        >
          {details}
        </CardContent>
      </Card>
    </div>
  );
}

export default QuestionComponentDetails;
