import React from 'react';
import { Button, Stack, TextField } from '@mui/material';
import { NewAnswerTextFieldProps } from '../editQuestionProps';
import { StyledAddOptionBox } from '../../../../../styles/admin/QuestionsPageStyles';

function NewAnswerTextField({
  optionChange,
  optionValue,
  handleClick,
}: NewAnswerTextFieldProps) {
  return (
    <StyledAddOptionBox>
      <Stack direction="row">
        <TextField
          variant="standard"
          placeholder="Add option"
          onChange={optionChange}
          value={optionValue}
          sx={{ width: '100%' }}
          inputProps={{ 'data-testid': 'add-new-answer' }}
        />
        <Button onClick={handleClick}>Add</Button>
      </Stack>
    </StyledAddOptionBox>
  );
}

export default NewAnswerTextField;
