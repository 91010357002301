import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetStudentExamQueryVariables = Types.Exact<{
  studentId: Types.Scalars['String'];
  examId: Types.Scalars['String'];
}>;


export type GetStudentExamQuery = { __typename?: 'Query', studentExam?: { __typename?: 'StudentExam', id?: string | null, score?: number | null, submittedAt?: any | null, openedAt?: any | null, student?: { __typename?: 'User', id?: string | null, firstName?: string | null, lastName?: string | null, email?: string | null } | null, examDetails?: { __typename?: 'ExamDetails', id?: string | null, title?: string | null, maxQuestionCount?: number | null, duration?: number | null } | null, examSummary?: Array<{ __typename?: 'QuestionOutput', points: number, question?: { __typename?: 'MultipleChoiceQuestion', id?: string | null, questionType?: Types.QuestionType | null, question?: string | null } | { __typename?: 'MultipleSelectionQuestion', id?: string | null, questionType?: Types.QuestionType | null, question?: string | null } | null, studentAnswer?: { __typename?: 'MultipleChoiceAnswer', questionId: string, option?: string | null } | { __typename?: 'MultipleSelectionAnswer', questionId: string, choices?: Array<string> | null } | null, output?: Array<{ __typename?: 'MultipleChoiceQuestionOutput', selected?: boolean | null, option?: { __typename?: 'Option', id?: string | null, description?: string | null, isCorrect?: boolean | null } | null } | { __typename?: 'MultipleSelectionQuestionOutput', selected?: boolean | null, choice?: { __typename?: 'Choice', id?: string | null, description?: string | null, isCorrect?: boolean | null } | null } | null> | null } | null> | null } | null };


export const GetStudentExamDocument = gql`
    query GetStudentExam($studentId: String!, $examId: String!) {
  studentExam(studentId: $studentId, examId: $examId) {
    id
    student {
      id
      firstName
      lastName
      email
    }
    examDetails {
      id
      title
      maxQuestionCount
      duration
    }
    examSummary {
      question {
        id
        questionType
        question
      }
      studentAnswer {
        ... on MultipleChoiceAnswer {
          questionId
          option
        }
        ... on MultipleSelectionAnswer {
          questionId
          choices
        }
      }
      points
      output {
        ... on MultipleChoiceQuestionOutput {
          option {
            id
            description
            isCorrect
          }
          selected
        }
        ... on MultipleSelectionQuestionOutput {
          choice {
            id
            description
            isCorrect
          }
          selected
        }
      }
    }
    score
    submittedAt
    openedAt
  }
}
    `;

/**
 * __useGetStudentExamQuery__
 *
 * To run a query within a React component, call `useGetStudentExamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentExamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentExamQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      examId: // value for 'examId'
 *   },
 * });
 */
export function useGetStudentExamQuery(baseOptions: Apollo.QueryHookOptions<GetStudentExamQuery, GetStudentExamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentExamQuery, GetStudentExamQueryVariables>(GetStudentExamDocument, options);
      }
export function useGetStudentExamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentExamQuery, GetStudentExamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentExamQuery, GetStudentExamQueryVariables>(GetStudentExamDocument, options);
        }
export type GetStudentExamQueryHookResult = ReturnType<typeof useGetStudentExamQuery>;
export type GetStudentExamLazyQueryHookResult = ReturnType<typeof useGetStudentExamLazyQuery>;
export type GetStudentExamQueryResult = Apollo.QueryResult<GetStudentExamQuery, GetStudentExamQueryVariables>;