import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllAdminsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AllAdminsQuery = { __typename?: 'Query', adminsConnection?: { __typename?: 'AdminsConnection', nodes?: Array<{ __typename?: 'Admin', email?: string | null, id?: string | null } | null> | null, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, startCursor?: string | null } } | null };


export const AllAdminsDocument = gql`
    query AllAdmins($first: Int, $last: Int, $after: String, $before: String) {
  adminsConnection(first: $first, last: $last, after: $after, before: $before) {
    nodes {
      email
      id
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
  }
}
    `;

/**
 * __useAllAdminsQuery__
 *
 * To run a query within a React component, call `useAllAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAdminsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useAllAdminsQuery(baseOptions?: Apollo.QueryHookOptions<AllAdminsQuery, AllAdminsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllAdminsQuery, AllAdminsQueryVariables>(AllAdminsDocument, options);
      }
export function useAllAdminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllAdminsQuery, AllAdminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllAdminsQuery, AllAdminsQueryVariables>(AllAdminsDocument, options);
        }
export type AllAdminsQueryHookResult = ReturnType<typeof useAllAdminsQuery>;
export type AllAdminsLazyQueryHookResult = ReturnType<typeof useAllAdminsLazyQuery>;
export type AllAdminsQueryResult = Apollo.QueryResult<AllAdminsQuery, AllAdminsQueryVariables>;