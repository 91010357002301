/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useState } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  IconButtonProps,
  styled,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { QuestionCardProps } from './displayProps';
import { formatQuestionType } from '../../../utils/wordFormatter';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function QuestionCard({
  questionType,
  content,
  details,
  showDetails,
  handleDelete,
}: QuestionCardProps) {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Card
        elevation={0}
        sx={{
          maxWidth: '100%',
          border: '1px solid #DBD6D6',
        }}
      >
        <CardHeader
          action={
            <IconButton
              aria-label="remove"
              onClick={handleDelete}
              disableRipple
            >
              <DeleteOutlineIcon />
            </IconButton>
          }
          title={
            <Typography
              sx={{
                fontSize: '16px',
                color: 'secondary.main',
                fontWeight: 'bold',
              }}
            >
              {formatQuestionType(questionType)}
            </Typography>
          }
          sx={{
            paddingTop: '5px',
            paddingBottom: '5px',
          }}
        />
        <CardContent
          sx={{
            backgroundColor: 'white',
            paddingBottom: '5px',
          }}
        >
          {content}
        </CardContent>
        <CardActions
          disableSpacing
          sx={{
            backgroundColor: 'white',
            paddingTop: '5px',
            paddingBottom: '5px',
          }}
        >
          <ExpandMore
            expand={showDetails ? showDetails : expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse
          in={showDetails ? showDetails : expanded}
          timeout="auto"
          unmountOnExit
        >
          <CardContent
            sx={{
              backgroundColor: 'white',
              paddingTop: '5px',
              paddingBottom: '5px',
            }}
          >
            {details}
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
}

export default QuestionCard;
