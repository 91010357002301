import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import PageNotFound from '../components/states/PageNotFound';
import Header from '../layouts/header/Header';
import { getUser, getUserRoles } from './userInfo/user';
import PublicRoute from './PublicRoute';
import Footer from '../layouts/footer/Footer';

const StudentPrivateRouteLayout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

function ProtectedStudentRoute(props: { allowedRole: string }) {
  const isAuthenticated = getUser();
  const roles = getUserRoles();

  const params = useParams();

  if (params.examId) {
    localStorage.setItem('link', `/take/${params.examId}`);
  }

  return !isAuthenticated ? (
    <PublicRoute role="ADMIN" />
  ) : roles.includes(props.allowedRole) ? (
    <StudentPrivateRouteLayout />
  ) : (
    <PageNotFound redirectTo="/" />
  );
}

export default ProtectedStudentRoute;
