import React, { useState } from 'react';
import { Typography, Grid, Modal, Box, Button } from '@mui/material';
import { useMutation } from '@apollo/client';
import SIGN_OUT_MUTATION from '../../../graphql/mutations/auth/signOut';
import { removeUser } from '../../../routes/userInfo/user';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'whitesmoke',
  boxShadow: 24,
  borderRadius: '12px',
  p: 3,
};

const SessionExpired = () => {
  const [open, setOpen] = useState(true);

  const [signOutUser] = useMutation(SIGN_OUT_MUTATION);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      signOutUser();
      removeUser();
      window.location.href = '/signin';
    }, 500);
  };

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Typography
              id="modal-modal-title"
              sx={{
                color: '#444444',
                fontWeight: 'medium',
                fontSize: 21,
              }}
            >
              Your session has expired
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{
                mb: 2,
                color: '#808080',
                fontWeight: 'regular',
                fontSize: 13,
              }}
            >
              Please sign in again
            </Typography>
            <Button
              style={{
                color: 'whitesmoke',
                backgroundColor: '#374EA2',
                fontSize: '12px',
              }}
              onClick={handleClose}
            >
              Sign in
            </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default SessionExpired;
