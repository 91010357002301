import { gql } from '@apollo/client';

const UPDATE_PUBLISH_STATUS_AND_MAX_QUESTION_COUNT_MUTATION = gql`
  mutation UpdatePublishStatusAndMaxQuestionsCount($input: UpdateExamInput!) {
    updateExam(input: $input) {
      isPublished
      maxQuestionCount
    }
  }
`;

export default UPDATE_PUBLISH_STATUS_AND_MAX_QUESTION_COUNT_MUTATION;
