/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from 'react';
import {
  Typography,
  Stack,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Button,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  FilterSliderProps,
  FilterSliderComponent,
  FilterSelectOption,
} from './filterSliderProps';

// Since this component is positioned absolute, make sure the parent div housing this is positioned relative
const FilterSlider = ({
  isOpen,
  handleClose,
  filterSchema,
  onSubmit,
}: FilterSliderProps) => {
  const FilterItems = () => {
    const getInputComponent = (item: FilterSliderComponent) => {
      if (item.options) {
        return (
          <FormControl sx={{ width: 215 }}>
            <Select
              onChange={(e) => item.onChange(e.target.value)}
              sx={{ height: 35 }}
              defaultValue={item.options[0].value}
            >
              {item.options.map((filterOption: FilterSelectOption) => {
                return (
                  <MenuItem
                    data-testid="select-option"
                    key={filterOption.value}
                    value={filterOption.value}
                  >
                    {filterOption.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      } else {
        return (
          <TextField
            size="small"
            onChange={(e) => item.onChange(e.target.value)}
            sx={{ width: 215 }}
          />
        );
      }
    };

    return filterSchema.map((item: FilterSliderComponent) => {
      return (
        <Stack key={item.filterFor} direction="column">
          <Typography id="question-type-label" sx={{ mt: 2, fontSize: 14 }}>
            {item.label}
          </Typography>
          {getInputComponent(item)}
        </Stack>
      );
    });
  };

  return (
    <div
      style={{
        position: 'absolute',
        height: '99.5%',
        width: '250px',
        right: isOpen ? 0 : '-260px',
        background: 'white',
        border: '1px solid rgba(224, 224, 224, 1)',
        transition: '0.2s all',
        zIndex: 2,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingBottom: '7px',
          paddingTop: '7px',
          paddingLeft: '12px',
          fontSize: '18px',
          fontWeight: 'bolder',
          background: '#374EA2',
          color: '#fff',
        }}
      >
        <ArrowForwardIosIcon
          sx={{ fontSize: '16px', marginRight: '9px', cursor: 'pointer' }}
          onClick={handleClose}
        />
        Filters
      </div>
      <div style={{ padding: '18px' }}>
        {FilterItems()}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          <Button
            data-testid="add-question"
            variant="contained"
            disableElevation
            onClick={onSubmit}
            sx={{
              color: '#FFFFFF',
              textTransform: 'none',
              boxShadow: 0,
              height: '40px',
              marginTop: '16px',
            }}
          >
            Filter Table
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterSlider;
