import React from 'react';
import { Typography, Grid } from '@mui/material';
import { DashboardContainer } from '../../../styles/admin/DashboardStyles';
import DashboardListTable from '../../../components/admin/dashboard/DashboardListTable';
import ExamBank from '../../../components/admin/examsList/ExamBank';

const DashboardPage = () => {
  return (
    <DashboardContainer>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12} md={9}>
          <Typography
            color="secondary.main"
            variant="h5"
            fontWeight="fontWeightBold"
          >
            Admin Dashboard
          </Typography>
          <ExamBank />
          {/* <DashboardListTable /> */}
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          {/* Add something here */}
        </Grid>
      </Grid>
    </DashboardContainer>
  );
};

export default DashboardPage;
