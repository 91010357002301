import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateCertificateMutationVariables = Types.Exact<{
  studentExamId: Types.Scalars['String'];
}>;


export type GenerateCertificateMutation = { __typename?: 'Mutation', generateCertificate?: any | null };


export const GenerateCertificateDocument = gql`
    mutation GenerateCertificate($studentExamId: String!) {
  generateCertificate(studentExamId: $studentExamId)
}
    `;
export type GenerateCertificateMutationFn = Apollo.MutationFunction<GenerateCertificateMutation, GenerateCertificateMutationVariables>;

/**
 * __useGenerateCertificateMutation__
 *
 * To run a mutation, you first call `useGenerateCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCertificateMutation, { data, loading, error }] = useGenerateCertificateMutation({
 *   variables: {
 *      studentExamId: // value for 'studentExamId'
 *   },
 * });
 */
export function useGenerateCertificateMutation(baseOptions?: Apollo.MutationHookOptions<GenerateCertificateMutation, GenerateCertificateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateCertificateMutation, GenerateCertificateMutationVariables>(GenerateCertificateDocument, options);
      }
export type GenerateCertificateMutationHookResult = ReturnType<typeof useGenerateCertificateMutation>;
export type GenerateCertificateMutationResult = Apollo.MutationResult<GenerateCertificateMutation>;
export type GenerateCertificateMutationOptions = Apollo.BaseMutationOptions<GenerateCertificateMutation, GenerateCertificateMutationVariables>;