import React from 'react';
import {
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CustomDialogTitleProps } from './commonProps';

function CustomDialogTitle({ text, handleClose }: CustomDialogTitleProps) {
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <DialogTitle
      sx={{ boxShadow: 1, backgroundColor: '#374EA2', color: '#FFFFFF' }}
    >
      <Grid container>
        <Grid
          item
          xs={6}
          container
          direction="row"
          alignItems="center"
          justifyContent="start"
        >
          <Typography
            sx={{
              color: '#FFFFFF',
              fontWeight: 600,
              fontSize: matches ? 18 : 14,
            }}
          >
            {text}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon sx={{ color: '#FFFFFF' }} />
          </IconButton>
        </Grid>
      </Grid>
    </DialogTitle>
  );
}

export default CustomDialogTitle;
