import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useParams } from 'react-router-dom';
import { useGenerateCertificateMutation } from '../../../../graphql/mutations/student/certificate/generateCertificate.generated';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { OpenInNew, Download } from '@mui/icons-material';

export default function ExamCertificate() {
  const params = useParams();
  const studentExamId = String(params.studentExamId);

  const [pageNumber, setPageNumber] = useState(1);
  const [pdfLoaded, setPdfLoaded] = useState(false);

  const [pdfUrl, setPdfUrl] = useState('');
  const [loadingMessage, setLoadingMessage] = useState(
    'Preparing your certificate...'
  );

  const [generateCertificate] = useGenerateCertificateMutation();

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    generateCertificate({
      variables: {
        studentExamId,
      },
      onCompleted: (response) => {
        if (response) {
          const buffer = response?.generateCertificate
            ?.data as Iterable<number>;

          const url = window.URL.createObjectURL(
            new Blob([new Uint8Array(buffer)], {
              type: 'application/pdf',
            })
          );

          setPdfUrl(url);
        }
      },
      onError: (error) => {
        console.log(error);
        if (error.message === 'Authorization error') {
          setLoadingMessage('Invalid link');
        }
      },
    });
  }, []);

  const handleDownload = () => {
    if (pdfUrl) {
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.setAttribute('download', 'merkle_tree_certificate.pdf');
      link.click();
    }
  };

  const handleOpenPdf = () => {
    window.open(pdfUrl, '_blank');
  };

  return (
    <>
      {pdfLoaded && (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="fixed">
            <Toolbar sx={{ background: '#6A6A6A' }} variant="dense">
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: 'flex' }, gap: '16px' }}>
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={handleDownload}
                >
                  <Download />
                </IconButton>
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={handleOpenPdf}
                >
                  <OpenInNew />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '50px',
        }}
      >
        <Document
          file={{ url: pdfUrl }}
          loading={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80vh',
                fontSize: '20px',
                color: '#808080',
              }}
            >
              {loadingMessage}
            </div>
          }
          onLoadSuccess={() => {
            setPdfLoaded(true);
          }}
        >
          <Page
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
          />
        </Document>
      </div>
    </>
  );
}
