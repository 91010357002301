import React, { SyntheticEvent } from 'react';
import { Snackbar, SnackbarCloseReason } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface CustomAlertProps {
  message: string;
  open: boolean;
  handleClose:
    | ((
        event: Event | SyntheticEvent<any, Event>,
        reason: SnackbarCloseReason
      ) => void)
    | undefined;
  durationInMs?: number;
}

const CustomAlert = (alertProps: CustomAlertProps) => {
  const { message, open, handleClose, durationInMs = 2000 } = alertProps;

  return (
    <span>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={durationInMs}
        onClose={handleClose}
      >
        <Alert
          severity="success"
          sx={{ width: '90%', backgroundColor: '#374EA2', fontSize: 12 }}
        >
          {message}
        </Alert>
      </Snackbar>
    </span>
  );
};

export default CustomAlert;
