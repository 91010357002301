import React from 'react';
import { Typography } from '@mui/material';
import { DivStyle } from '../../../../styles/admin/QuestionsPageStyles';
import { QuestionTypeHeaderProps } from './commonProps';
import { formatQuestionType } from '../../../../utils/wordFormatter';

function QuestionTypeHeader({ questionType }: QuestionTypeHeaderProps) {
  return (
    <>
      <DivStyle>
        <Typography
          component="span"
          sx={{
            fontSize: 14,
            fontWeight: 600,
            paddingRight: '5px',
            color: '#212427',
          }}
          data-testid={questionType}
        >
          Question Type:{' '}
        </Typography>
        <Typography component="span" sx={{ fontSize: 14 }}>
          {formatQuestionType(questionType)}
        </Typography>
      </DivStyle>
    </>
  );
}

export default QuestionTypeHeader;
