import React from 'react';
import { Stack, Typography } from '@mui/material';

const ResourceSection = (props: { title: string; contents: string[] }) => {
  return (
    <Stack direction="column" spacing={2}>
      <Typography
        fontWeight="fontWeightMedium"
        variant="h6"
        color="primary.main"
      >
        {props.title}
      </Typography>
      <Stack direction="column" spacing={2}>
        {props.contents.map((content, index) => (
          <Typography key={index} color="secondary.main">
            {content}
          </Typography>
        ))}
      </Stack>
    </Stack>
  );
};

export default ResourceSection;
