import React from 'react';
import { Typography, useMediaQuery, Grid, TextField } from '@mui/material';
import { EditTextFieldProps } from '../editQuestionProps';

function EditModuleTextField({ textValue, onChange }: EditTextFieldProps) {
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <>
      <Grid container>
        <Grid item xs={12} container sx={{ paddingBottom: '10px' }}>
          <Typography
            sx={{ mt: 2, fontSize: matches ? 16 : 14, fontWeight: 600 }}
          >
            Module
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            inputProps={{ 'data-testid': 'module-textfield' }}
            variant="outlined"
            onChange={onChange}
            defaultValue={textValue}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default EditModuleTextField;
