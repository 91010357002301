import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Stack, Grid, Button } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

type PageNotFoundProps = {
  redirectTo: string;
};

const PageNotFound = (props: PageNotFoundProps) => {
  const { redirectTo } = props;
  const navigate = useNavigate();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Stack direction="column" spacing={0.5} marginY={3}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <ErrorIcon sx={{ color: '#e57373', fontSize: '40px' }} />
          <Typography
            fontWeight="fontWeightBold"
            variant="h4"
            sx={{ color: '#374EA2' }}
          >
            404 Error
          </Typography>
        </Stack>
        <Typography variant="h6" sx={{ color: '#BEBEBE' }}>
          Sorry, page not found :(
        </Typography>
      </Stack>
      <Button
        variant="contained"
        disableElevation
        onClick={() => {
          navigate(redirectTo);
        }}
        sx={{ textTransform: 'none', boxShadow: 0 }}
      >
        Go Home
      </Button>
    </Grid>
  );
};

export default PageNotFound;
