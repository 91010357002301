import React from 'react';
import { Stack } from '@mui/material';
import { useGetMultipleChoiceQuestionQuery } from '../../../../graphql/queries/admin/multipleChoice/getMultipleChoiceQuestion.generated';
import { QuestionAnswerInterface } from '../../../../pages/admin/questionBank/QuestionInterface';
import { QuestionComponentDetailsProps } from '../viewExamComponentsProps';
import AnswerItemDisplay from '../../../card/ExamQuestionsView/components/AnswerItemDisplay';
import QuestionComponent from './QuestionComponentDetails';

function MCQuestionComponent({
  id,
  studentAnswer,
}: QuestionComponentDetailsProps) {
  const { data, loading, error } = useGetMultipleChoiceQuestionQuery({
    variables: {
      id: id as string,
    },
    pollInterval: 2000,
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error...</div>;

  const question: string = data?.multipleChoiceQuestion?.question as string;
  const questionType =
    data?.multipleChoiceQuestion?.__typename ?? 'MultipleChoiceQuestion';
  const options: QuestionAnswerInterface[] = data?.multipleChoiceQuestion
    ?.options as QuestionAnswerInterface[];

  return (
    <>
      <QuestionComponent
        questionContent={question}
        details={
          <Stack direction="column" paddingLeft={3.6} paddingRight={3.6}>
            {options?.map((option, index) => {
              return (
                <AnswerItemDisplay
                  key={option?.id as string}
                  optionId={option?.id as string}
                  questionType={questionType}
                  textValue={option?.description ?? ''}
                  testId={`possible-answer-${index + 1}`}
                  isCorrectAnswer={option?.isCorrect as boolean}
                  studentAnswer={studentAnswer}
                />
              );
            })}
          </Stack>
        }
      />
    </>
  );
}

export default MCQuestionComponent;
