import React, { useState } from 'react';
import { Button, useMediaQuery } from '@mui/material';
import CustomDialog from '../../../dialog/CustomDialog';
import message from '../../../dialog/message.json';
import { PublishDialogProps } from './publishExamProps';
import { useMutation } from '@apollo/client';
import UPDATE_PUBLISH_MUTATION from '../../../../graphql/mutations/admin/exam/updatePublishStatus/updatePublishStatus';
import {
  UpdateExamPublishStatusMutation,
  UpdateExamPublishStatusMutationVariables,
} from '../../../../graphql/mutations/admin/exam/updatePublishStatus/updatePublishStatus.generated';
import { ExamType, InputMaybe } from '../../../../types.generated';

function UnpublishExamDialog({
  examId,
  examType,
  duration,
  startDate,
  endDate,
}: PublishDialogProps) {
  const matches = useMediaQuery('(max-width:600px)');

  const [openUnpublishExam, setOpenUnpublishExam] = useState(false);

  const [updatePublishStatus] = useMutation<
    UpdateExamPublishStatusMutation,
    UpdateExamPublishStatusMutationVariables
  >(UPDATE_PUBLISH_MUTATION);

  const updateExamPublishStatus = () => {
    switch (examType) {
      case ExamType.Fixed:
        updatePublishStatus({
          variables: {
            input: {
              id: examId,
              examType: examType,
              isPublished: false,
              startDate: startDate as InputMaybe<string>,
              endDate: endDate as InputMaybe<string>,
            },
          },
        });
        break;
      case ExamType.Flexible:
        updatePublishStatus({
          variables: {
            input: {
              id: examId,
              examType: examType,
              isPublished: false,
              duration: duration as number,
            },
          },
        });
        break;
      case ExamType.Free:
        updatePublishStatus({
          variables: {
            input: {
              id: examId,
              examType: examType,
              isPublished: false,
            },
          },
        });
        break;
      default:
        break;
    }
  };

  const handleOpenPublishExam = () => {
    setOpenUnpublishExam(true);
  };

  const handleCloseUnpublish = () => {
    setOpenUnpublishExam(false);
  };

  const handleConfirmSave = () => {
    updateExamPublishStatus();
    setOpenUnpublishExam(false);
  };

  return (
    <>
      <Button
        variant="contained"
        disableElevation
        onClick={handleOpenPublishExam}
        fullWidth={matches}
        data-testid="unpublish-button"
        color="error"
        sx={{
          color: '#FFFFFF',
          textTransform: 'none',
          boxShadow: 0,
        }}
      >
        Unpublish
      </Button>
      <CustomDialog
        isOpen={openUnpublishExam}
        handleClose={handleCloseUnpublish}
        handleConfirm={handleConfirmSave}
        title={message.unpublishExam.title}
        content={message.unpublishExam.content}
      />
    </>
  );
}

export default UnpublishExamDialog;
