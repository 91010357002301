export const timeConverter = (time: number) => {
  const hours = time / 60;
  const roundedHours = Math.floor(hours);
  const minutes = (hours - roundedHours) * 60;
  const roundedMinutes = Math.round(minutes);

  const hr = roundedHours > 1 ? 'hours' : 'hour';
  const min = roundedMinutes > 1 ? 'minutes' : 'minute';

  const format =
    roundedMinutes > 0
      ? `${roundedHours} ${hr} and ${roundedMinutes} ${min}`
      : `${roundedHours} ${hr}`;

  if (time < 60) {
    return time > 1 ? `${time} minutes` : `${time} minute`;
  } else {
    return format;
  }
};
