import { Box, styled } from '@mui/material';

export const StyledDialogButtonBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  paddingBottom: '8px',
  paddingTop: '20px',
  paddingRight: '8px',
}));
