import React from 'react';
import { Button, Box } from '@mui/material';
import { QButtonProps } from './commonProps';

function QuestionActionButton({ handleClick, isMatch, label }: QButtonProps) {
  return (
    <Box sx={{ paddingTop: '50px' }}>
      <Button
        variant="contained"
        disableElevation
        onClick={handleClick}
        fullWidth={isMatch}
        data-testid="action-button"
        sx={{
          color: '#FFFFFF',
          textTransform: 'none',
          boxShadow: 0,
        }}
      >
        {label}
      </Button>
    </Box>
  );
}

export default QuestionActionButton;
