import React from 'react';
import { Chip } from '@mui/material';

function Tag(props: {
  label: string;
  mRight?: number;
  mLeft?: number;
  bg?: string;
  minWidth?: string;
}) {
  return (
    <>
      <Chip
        label={props.label}
        size="small"
        sx={{
          minWidth: props.minWidth ?? '0px',
          fontSize: '11px',
          backgroundColor: props.bg ? props.bg : '#f5f5f5',
          marginRight: props.mRight,
          marginLeft: props.mLeft,
        }}
      />
    </>
  );
}

export default Tag;
