import React from 'react';
import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { StyledEmptyDataMessage } from '../../../styles/admin/QuestionsPageStyles';

type EmptySearchProps = {
  searchValue?: string;
};

function EmptySearchResultsComponent(props: EmptySearchProps) {
  const { searchValue } = props;

  return (
    <>
      <TableBody>
        <TableRow>
          <TableCell>
            <StyledEmptyDataMessage>
              <Typography
                variant="h5"
                style={{
                  color: '#BEBEBE',
                  textAlign: 'center',
                }}
              >
                {searchValue ? (
                  <>
                    We couldn&apos;t find any results for{' '}
                    <b>&quot;{searchValue}&quot;</b>
                  </>
                ) : (
                  'No Results Found'
                )}
              </Typography>
            </StyledEmptyDataMessage>
          </TableCell>
        </TableRow>
      </TableBody>
    </>
  );
}

export default EmptySearchResultsComponent;
