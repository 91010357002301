/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Link,
  Stack,
  TableHead,
} from '@mui/material';
import { getDate } from '../../../utils/dateConverter';
import PublishedIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LinkIcon from '@mui/icons-material/Link';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import {
  RowContainer,
  ExamContainer,
  ExamStatusContainer,
  StyledTableCell,
  ExamDetailsText,
} from '../../../styles/admin/ExamsListStyles';
import { ExamsListRowInterface, StudentExam } from './examsListInterface';
import getExamAuthor from './getExamAuthor';
import { formatProgramText } from '../../../utils/wordFormatter';
import Tag from '../../common/ChipTags';
import ExamLinkModal from '../../modal/admin/ExamLink/ExamLinkModal';
// import generateExamLink from '../../../utils/examLinkGenerator';
import { useSubscriptionQuery } from '../../../graphql/queries/admin/exam/subscription/getSubscription.generated';
import { useSubscribeToExamMutation } from '../../../graphql/mutations/admin/exam/subscription/subscribeToExam.generated';
import { useUnsubscribeFromExamMutation } from '../../../graphql/mutations/admin/exam/subscription/unsubscribeFromExam.generated';
import { useGenerateExamLinkMutation } from '../../../graphql/mutations/student/examLink/generateExamLink.generated';

const examDetailsStyles = { fontSize: '14px', py: 2, pl: 5 };

const ExamsListRow = (props: ExamsListRowInterface) => {
  const { exam } = props;
  const [open, setOpen] = useState(false);

  const [subscribed, setSubscribed] = useState(false); //check if admin is subscribed to the exam
  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [examLink, setExamLink] = useState('');

  const examId = exam.id;
  const navigate = useNavigate();
  const location = useLocation();
  const authorId = exam.createdById;

  const [subscribe] = useSubscribeToExamMutation();
  const [unsubscribe] = useUnsubscribeFromExamMutation();
  const [generateExamLink] = useGenerateExamLinkMutation();

  const { data, refetch } = useSubscriptionQuery({
    variables: {
      examId: examId,
    },
    onCompleted: (subscriptionData) => {
      if (subscriptionData.subscription) {
        setSubscribed(true);
      } else {
        setSubscribed(false);
      }
    },
  });

  useEffect(() => {
    refetch();
  }, [subscribed]);

  const getExamLink = async () => {
    const response = await generateExamLink();
    setExamLink(response.data?.generateExamLink ?? '');
  };

  const handleSubscribe = () => {
    subscribe({
      variables: {
        examId: examId,
      },
      onCompleted: () => {
        setSubscribed(true);
      },
    });
  };

  const handleUnsubscribe = () => {
    unsubscribe({
      variables: {
        examId: examId,
      },
      onCompleted: () => {
        setSubscribed(false);
      },
    });
  };

  return (
    <>
      <TableRow>
        <StyledTableCell>
          <RowContainer>
            <ExamContainer>
              <ExamStatusContainer>
                {exam.isPublished ? (
                  <PublishedIcon sx={{ color: '#66bb6a', fontSize: '16px' }} />
                ) : (
                  <UnpublishedIcon
                    sx={{ color: '#a9a9a9', fontSize: '16px' }}
                  />
                )}
              </ExamStatusContainer>
              <Link
                component="button"
                variant="body2"
                onClick={() =>
                  navigate(`/exam/${exam?.id}`, {
                    state: { prevPath: location.pathname },
                  })
                }
                underline="hover"
                color="#212427"
                sx={{ fontSize: '14px', marginRight: 2 }}
              >
                {exam.title}
              </Link>
              <Tag
                label={formatProgramText(exam.program)}
                mRight={1}
                mLeft={0}
              />
              <Tag label={exam.module} mRight={1} mLeft={0} />
              <Tag
                label={exam.year.toString() + exam.quarter}
                mRight={0}
                mLeft={0}
              />
            </ExamContainer>
            <Stack
              direction="row"
              component="span"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              spacing={0.2}
            >
              {/* {subscribed ? (
                <IconButton onClick={handleUnsubscribe}>
                  <NotificationsActiveIcon
                    sx={{ color: '#24a0ed', fontSize: '18px' }}
                  />
                </IconButton>
              ) : (
                <IconButton onClick={handleSubscribe}>
                  <NotificationsOffIcon
                    sx={{ color: '#a9a9a9', fontSize: '18px' }}
                  />
                </IconButton>
              )} */}
              <IconButton
                onClick={() => {
                  getExamLink();
                  setOpenLinkModal(true);
                }}
              >
                <LinkIcon sx={{ fontSize: '20px' }} />
              </IconButton>
              <ExamLinkModal
                isOpen={openLinkModal}
                handleClose={() => setOpenLinkModal(false)}
                examLink={examLink}
              />
              <IconButton size="small" onClick={() => setOpen(!open)}>
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Stack>
          </RowContainer>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ border: 0, padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableBody style={{ backgroundColor: '#fafafa' }}>
                <TableRow key={exam.id}>
                  <TableCell align="center" sx={examDetailsStyles}>
                    <ExamContainer>
                      <ExamDetailsText>Description</ExamDetailsText>
                      <Typography sx={{ fontSize: '12px' }}>
                        {exam.description}
                      </Typography>
                    </ExamContainer>
                  </TableCell>
                  <TableCell align="center" sx={examDetailsStyles}>
                    <ExamContainer>
                      <ExamDetailsText>Created by</ExamDetailsText>
                      <Typography sx={{ fontSize: '12px' }}>
                        {getExamAuthor(authorId)}
                      </Typography>
                    </ExamContainer>
                  </TableCell>
                  <TableCell align="center" sx={examDetailsStyles}>
                    <ExamContainer>
                      <ExamDetailsText>Created at</ExamDetailsText>
                      <Typography sx={{ fontSize: '12px' }}>
                        {getDate(exam.createdAt)}
                      </Typography>
                    </ExamContainer>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table
              size="small"
              stickyHeader
              sx={{ width: '100%', maxHeight: '50vh', mt: 5 }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell align="center">Score</TableCell>
                  <TableCell align="center">Remarks</TableCell>
                  <TableCell>Submit Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {exam.studentExams.map((studentExam: StudentExam) => (
                  <TableRow
                    key={studentExam.student.id}
                    onClick={() => {
                      navigate(
                        `/viewexam/${studentExam.student.id}/${exam?.id}`,
                        {
                          state: { prevPath: location.pathname },
                        }
                      );
                    }}
                  >
                    <TableCell>
                      {studentExam.student.firstName}{' '}
                      {studentExam.student.lastName}
                    </TableCell>
                    <TableCell>{studentExam.student.email}</TableCell>
                    <TableCell align="center">
                      {studentExam.score?.toFixed(2)}
                    </TableCell>
                    <TableCell align="center">
                      {studentExam.passed != null ? (
                        studentExam.passed ? (
                          <Tag label="Passed" bg="#BDE7BD" />
                        ) : (
                          <Tag label="Failed" bg="#FF9997" />
                        )
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {studentExam.submittedAt
                        ? new Date(studentExam.submittedAt).toLocaleString()
                        : 'In Progress'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ExamsListRow;
