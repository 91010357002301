/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react';
import { Grid } from '@mui/material';
import { ExamDetailsProps } from './viewExamComponentsProps';
import {
  ExamDetailsDiv,
  ExamItemsDiv,
  ExamDetailsTypography,
  ExamDetailsTitleTypography,
} from '../../../styles/admin/ViewExamStyles';
import { ExamType } from '../../../types.generated';
import { getExamDateAndTime } from '../../../utils/dateConverter';
import { timeConverter } from '../../../utils/timeConverter';

function ExamDetails({
  startDate,
  endDate,
  duration,
  numOfItems,
  typeOfExam,
  titleGridSize,
  detailGridSize,
}: ExamDetailsProps) {
  const FixedTypeExamDetails = () => {
    return (
      <>
        <ExamDetailsDiv>
          <Grid container>
            <Grid item xs={titleGridSize}>
              <ExamDetailsTitleTypography data-testid="available">
                Available
              </ExamDetailsTitleTypography>
            </Grid>
            <Grid item xs={detailGridSize}>
              <ExamDetailsTypography>
                {getExamDateAndTime(startDate as number)}
              </ExamDetailsTypography>
            </Grid>
          </Grid>
        </ExamDetailsDiv>
        <ExamDetailsDiv>
          <Grid container>
            <Grid item xs={titleGridSize}>
              <ExamDetailsTitleTypography data-testid="due">
                Due
              </ExamDetailsTitleTypography>
            </Grid>
            <Grid item xs={detailGridSize}>
              <ExamDetailsTypography>
                {getExamDateAndTime(endDate as number)}
              </ExamDetailsTypography>
            </Grid>
          </Grid>
        </ExamDetailsDiv>{' '}
      </>
    );
  };

  const FlexibleTypeExamDetails = () => {
    return (
      <>
        <ExamDetailsDiv>
          <Grid container>
            <Grid item xs={titleGridSize}>
              <ExamDetailsTitleTypography data-testid="duration">
                Duration
              </ExamDetailsTitleTypography>
            </Grid>
            <Grid item xs={detailGridSize}>
              <ExamDetailsTypography data-testid="given-duration">
                {timeConverter(duration as number)}
              </ExamDetailsTypography>
            </Grid>
          </Grid>
        </ExamDetailsDiv>
      </>
    );
  };

  const FreeTypeExamDetails = () => {
    return (
      <>
        <ExamDetailsDiv>
          <Grid container>
            <Grid item xs={titleGridSize}>
              <ExamDetailsTitleTypography data-testid="no-due">
                Due
              </ExamDetailsTitleTypography>
            </Grid>
            <Grid item xs={detailGridSize}>
              <ExamDetailsTypography data-testid="free">
                No due date
              </ExamDetailsTypography>
            </Grid>
          </Grid>
        </ExamDetailsDiv>
      </>
    );
  };

  const Details = () => {
    switch (typeOfExam) {
      case ExamType.Fixed:
        return <FixedTypeExamDetails />;
      case ExamType.Flexible:
        return <FlexibleTypeExamDetails />;
      case ExamType.Free:
        return <FreeTypeExamDetails />;
      default:
        return null;
    }
  };
  return (
    <div style={{ paddingTop: '10px', paddingBottom: '20px' }}>
      <Details />
      <ExamItemsDiv>
        <Grid container>
          <Grid item xs={titleGridSize}>
            <ExamDetailsTitleTypography data-testid="items">
              Items{' '}
            </ExamDetailsTitleTypography>
          </Grid>
          <Grid item xs={detailGridSize}>
            <ExamDetailsTypography>{numOfItems}</ExamDetailsTypography>
          </Grid>
        </Grid>
      </ExamItemsDiv>
    </div>
  );
}

export default ExamDetails;
