import React from 'react';
import { Container, Typography } from '@mui/material';
import {
  ExamsPageContainer,
  ExamsPageHeaderContainer,
} from '../../../styles/admin/ExamsPageStyles';
import ExamBank from '../../../components/admin/examsList/ExamBank';
import AddExamModal from '../../../components/modal/admin/AddExam/AddExamModal';

const ExamsPage = () => {
  return (
    <ExamsPageContainer>
      <Container maxWidth="md">
        <ExamsPageHeaderContainer>
          <Typography
            color="secondary.main"
            variant="h5"
            fontWeight="fontWeightBold"
          >
            Exam Bank
          </Typography>
          <AddExamModal />
        </ExamsPageHeaderContainer>
        <ExamBank />
      </Container>
    </ExamsPageContainer>
  );
};

export default ExamsPage;
