import { gql } from '@apollo/client';

const SUBMIT_EXAM_ANSWERS_MUTATION = gql`
  mutation SubmitStudentExam($input: SubmitStudentExamInput!) {
    submitStudentExam(input: $input) {
      examDetails {
        id
      }
      student {
        id
      }
      score
      passed
    }
  }
`;

export default SUBMIT_EXAM_ANSWERS_MUTATION;
