import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {
  Typography,
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import Image from '../../assets/landing-image1.webp';
import Feature1 from '../../assets/feature1-icon.png';
import Feature2 from '../../assets/feature2-icon.png';
import Feature3 from '../../assets/feature3-icon.png';
import {
  SectionTitle,
  AboutImage,
  FeaturesSectionContainer,
  WelcomeContainer,
  FeaturesIcon,
  FeaturesIconContainer,
} from '../../styles/styledComponents/LandingPageStyles';
import { useCreateCheckoutSessionQueryLazyQuery } from '../../graphql/queries/student/payment/createCheckoutSession.generated';
import { useSubscriptionStatusQuery } from '../../graphql/queries/admin/exam/subscription/getSubscriptionStatus.generated';

const features = [
  {
    title: 'Streamlined Experience',
    icon: <FeaturesIcon src={Feature1} alt="feature1-icon" />,
    description:
      'Your exam taking experience is consistent across platforms and cohorts.',
  },
  {
    title: 'Tailored Assessments',
    icon: <FeaturesIcon src={Feature2} alt="feature2-icon" />,
    description:
      'Custom tools are provided to instructors to create unique exams and accurate assessments.',
  },
  {
    title: '... And more to come!',
    icon: <FeaturesIcon src={Feature3} alt="feature3-icon" />,
    description:
      'We envision that this platform will become a good assessment ground for our candidates and instructors.',
  },
];

const LandingPage = () => {
  const navigate = useNavigate();
  const [createCheckoutSession, { data, loading, error }] =
    useCreateCheckoutSessionQueryLazyQuery();

  const {
    data: statusData,
    loading: statusLoading,
    error: statusError,
  } = useSubscriptionStatusQuery();

  const status = statusData?.subscriptionStatus?.status;
  const examLink = statusData?.subscriptionStatus?.examLink;

  const getStatus = () => {
    switch (status) {
      case 'PASSED':
        return 'View Exam History';
        break;
      case 'PAID':
        return 'Go to Exam Page';
        break;
      case 'FAILED':
        return 'Buy the Exam';
        break;
      default:
        return 'Buy the Exam';
        break;
    }
  };

  const handleBuyExam = async () => {
    const link = await createCheckoutSession();

    if (status === 'PASSED') {
      navigate('/candidatedashboard');
    } else if (status === 'PAID' && examLink) {
      window.open(examLink);
    } else if (link.data?.createCheckoutSession) {
      window.location.replace(link.data.createCheckoutSession);
    } else {
      window.location.href = '/signin';
    }
  };

  return (
    <div>
      <Container maxWidth="sm" sx={{ mt: 10 }}>
        <WelcomeContainer>
          <Typography
            fontWeight="fontWeightBold"
            variant="h4"
            color="secondary.main"
            gutterBottom
            sx={{ mr: 1 }}
          >
            Welcome,
          </Typography>
          <Typography
            variant="h4"
            fontWeight="fontWeightBold"
            color="#539536"
            gutterBottom
          >
            Candidates!
          </Typography>
        </WelcomeContainer>
        <Typography
          fontWeight="fontWeightMedium"
          color="secondary.main"
          align="center"
        >
          This is the assessment platform of Merkle Trees.
        </Typography>
      </Container>
      <Container maxWidth="md" sx={{ mt: 10 }}>
        <Grid container spacing={2} alignItems="center" sx={{ mb: 4 }}>
          <Grid item xs={12} sm={12} md={6}>
            <AboutImage src={Image} alt="about-image" />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <SectionTitle gutterBottom variant="h5">
              About the platform
            </SectionTitle>
            <Typography color="secondary.main" gutterBottom>
              As a valued account member of the Merkletrees Academy, you will
              get access to the most updated certification exams on
              revolutionary Web3 and Blockchain technologies. All exams are
              conducted online and you will earn your competency certificate
              immediately upon completion and passing.
            </Typography>
            <LoadingButton
              variant="contained"
              disableElevation
              sx={{
                color: '#FFFFFF',
                textTransform: 'none',
                boxShadow: 0,
                mt: '4px',
              }}
              loading={loading || statusLoading}
              onClick={() => {
                handleBuyExam();
              }}
            >
              {getStatus()}
            </LoadingButton>
          </Grid>
        </Grid>
      </Container>
      <FeaturesSectionContainer>
        <Container maxWidth="md" sx={{ pt: 4, pb: 4 }}>
          <SectionTitle variant="h5" align="center">
            Features
          </SectionTitle>
          <Grid container spacing={5} alignItems="flex-end">
            {features.map((feature) => (
              <Grid item key={feature.title} xs={12} sm={12} md={4}>
                <Card
                  sx={{
                    borderRadius: '20px',
                    boxShadow: 5,
                    minHeight: '320px',
                  }}
                >
                  <FeaturesIconContainer>{feature.icon}</FeaturesIconContainer>

                  <CardHeader
                    title={feature.title}
                    titleTypographyProps={{
                      align: 'center',
                      fontSize: '18px',
                      fontWeight: 600,
                    }}
                    subheaderTypographyProps={{ align: 'center' }}
                  />
                  <CardContent
                    sx={{
                      backgroundColor: '#539536',
                      minHeight: '150px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      align="center"
                      color="common.white"
                      sx={{ fontSize: '14px' }}
                    >
                      {feature.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </FeaturesSectionContainer>
    </div>
  );
};

export default LandingPage;
