import { styled, Typography } from '@mui/material';

export const ModalHeaderContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 600,
  maxHeight: 500,
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  p: 8,
};

export const AddExamModalHeader = styled(Typography)(() => ({
  fontSize: 30,
  fontWeight: 600,
}));

export const AddExamButtonContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
}));

export const DivAddExamStyle = styled('div')(({ theme }) => ({
  textAlign: 'start',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: '30px',
  paddingLeft: '40px',
  paddingRight: '40px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '10px',
  },
}));

export const AddExamContentStyle = styled('div')(() => ({
  textAlign: 'start',
  alignItems: 'center',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',
}));
