import React from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { SubmissionDetailsProps } from '../submissionDetails';
import {
  SubmissionDetailsContainer,
  SubmissionDetailsDiv,
} from '../../../../../styles/student/StudentExamScorePageStyles';

function SubmissionDetailsComponent({
  examEndTimestamp,
  examStartTimestamp,
  titleGridSize,
  detailGridSize,
}: SubmissionDetailsProps) {
  const Details = () => {
    return (
      <>
        <SubmissionDetailsDiv>
          <Stack>
            <Grid container direction={'column'}>
              <Grid item xs={titleGridSize}>
                <Typography
                  color="rgba(0, 0, 0, 0.85)"
                  fontWeight="fontWeightBold"
                  sx={{ fontSize: 14 }}
                >
                  Exam started:
                </Typography>
              </Grid>
              <Grid item xs={detailGridSize}>
                <Typography color="secondary.main" sx={{ fontSize: 14 }}>
                  {new Date(examStartTimestamp).toLocaleString()}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction={'column'}>
              <Grid item xs={titleGridSize}>
                <Typography
                  color="rgba(0, 0, 0, 0.85)"
                  fontWeight="fontWeightBold"
                  sx={{ fontSize: 14 }}
                >
                  Submitted:
                </Typography>
              </Grid>
              <Grid item xs={detailGridSize}>
                <Typography color="secondary.main" sx={{ fontSize: 14 }}>
                  {new Date(examEndTimestamp).toLocaleString()}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </SubmissionDetailsDiv>
      </>
    );
  };

  return (
    <div>
      <SubmissionDetailsContainer>
        <Typography fontWeight={600} sx={{ fontSize: 16 }}>
          Submission Details:
        </Typography>
        <Box height={20} />
        <Stack direction="column" spacing={1}>
          <Details />
        </Stack>
      </SubmissionDetailsContainer>
    </div>
  );
}

export default SubmissionDetailsComponent;
