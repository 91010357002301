import React from 'react';
import {
  GetMultipleSelectionQuestionQuery,
  useGetMultipleSelectionQuestionQuery,
} from '../../../../../graphql/queries/admin/multipleSelection/getMultipleSelectionQuestion.generated';
import {
  GetUserQuery,
  useGetUserQuery,
} from '../../../../../graphql/queries/user/user.generated';

import { QDialogDataProps } from '../questionModalProps';
import MSQuestionModal from './MSQuestionModal';
import ErrorPage from '../../../../states/ErrorPage';
import TableIndividualRowLoading from '../../../../states/componentStates/TableIndividualRowLoading';

function MSDataPage(props: QDialogDataProps) {
  const { questionId, authorId } = props;

  const {
    data: questionAuthor,
    loading: questionAuthorLoading,
    error: questionAuthorError,
  } = useGetUserQuery({
    variables: {
      id: authorId,
    },
    pollInterval: 2000,
  });

  const {
    data: MSQuestion,
    loading: MSLoading,
    error: MSError,
  } = useGetMultipleSelectionQuestionQuery({
    variables: {
      id: questionId,
    },
    pollInterval: 2000,
  });

  if (MSLoading || questionAuthorLoading) return <TableIndividualRowLoading />;
  if (MSError || questionAuthorError)
    return (
      <ErrorPage
        errorMessage={MSError ? MSError.message : questionAuthorError?.message}
      />
    );

  return (
    <MSQuestionModal
      data={MSQuestion as GetMultipleSelectionQuestionQuery}
      author={questionAuthor as GetUserQuery}
    />
  );
}

export default MSDataPage;
