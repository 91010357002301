import { ExamAnswerableQuestionInterface } from '../pages/student/exam/examProps';

const getRandomQuestions = (
  questions: ExamAnswerableQuestionInterface[],
  size: number
) => {
  const questionsCopy = [...questions];
  const randomQuestions = [
    ...(Array(size) as ExamAnswerableQuestionInterface[]),
  ].map(
    () =>
      questionsCopy.splice(
        Math.floor(Math.random() * questionsCopy.length),
        1
      )[0]
  );

  return randomQuestions;
};

export default getRandomQuestions;
