import { gql } from '@apollo/client';

const CREATE_ADMIN_MUTATION = gql`
  mutation CreateAdmin($input: CreateAdminInput!) {
    createAdmin(input: $input) {
      id
      email
    }
  }
`;

export default CREATE_ADMIN_MUTATION;
