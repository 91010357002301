import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

const drawerWidth = 180;

export const AccountContainer = styled('div')(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  position: 'absolute',
  height: '50px',
  bottom: 0,
  width: '100%',
  backgroundColor: '#539536',
}));

export const Drawer = styled(MuiDrawer)(() => ({
  '& .MuiDrawer-paper': {
    backgroundColor: '#374EA2',
    width: drawerWidth,
    overflow: 'hidden',
  },
}));

export const Logo = styled('img')(() => ({
  marginTop: 20,
  maxHeight: '120px',
  maxWidth: '120px',
  marginBottom: 5,
}));

export const LogoContainer = styled('div')(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  marginTop: 20,
  width: '100%',
  marginBottom: 10,
}));

export const AccountPaper = styled('div')(() => ({
  width: '150px',
  padding: '10px',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
}));

export const ListItemIconContainer = styled('div')(() => ({
  width: '20px',
  margin: '10px',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
}));
