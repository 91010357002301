import { gql } from '@apollo/client';

const ADD_QUESTION_ON_EXAM_MUTATION = gql`
  mutation AddQuestionOnExam($input: QuestionOnExamInput!) {
    addQuestionOnExam(input: $input) {
      examId
      questionId
    }
  }
`;

export default ADD_QUESTION_ON_EXAM_MUTATION;
