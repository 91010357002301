import React, { ChangeEvent } from 'react';
import { Typography, Stack, TextField } from '@mui/material';

type AddExamTextFieldType = {
  title: string;
  placeholder: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const AddExamTextField = (props: AddExamTextFieldType) => {
  const { title, placeholder, onChange } = props;
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      marginY={1}
    >
      <Typography sx={{ fontSize: 16 }}>{title}</Typography>
      <TextField
        required
        variant="outlined"
        placeholder={placeholder}
        sx={{ width: 400 }}
        onChange={onChange}
      />
    </Stack>
  );
};

export default AddExamTextField;
