import * as Types from '../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SendEmailNotificationMutationVariables = Types.Exact<{
  input: Types.SendEmailNotificationInput;
}>;


export type SendEmailNotificationMutation = { __typename?: 'Mutation', sendEmailNotification?: Array<{ __typename?: 'ExamSubscription', userId: string, examId: string } | null> | null };


export const SendEmailNotificationDocument = gql`
    mutation SendEmailNotification($input: SendEmailNotificationInput!) {
  sendEmailNotification(input: $input) {
    userId
    examId
  }
}
    `;
export type SendEmailNotificationMutationFn = Apollo.MutationFunction<SendEmailNotificationMutation, SendEmailNotificationMutationVariables>;

/**
 * __useSendEmailNotificationMutation__
 *
 * To run a mutation, you first call `useSendEmailNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailNotificationMutation, { data, loading, error }] = useSendEmailNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendEmailNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailNotificationMutation, SendEmailNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailNotificationMutation, SendEmailNotificationMutationVariables>(SendEmailNotificationDocument, options);
      }
export type SendEmailNotificationMutationHookResult = ReturnType<typeof useSendEmailNotificationMutation>;
export type SendEmailNotificationMutationResult = Apollo.MutationResult<SendEmailNotificationMutation>;
export type SendEmailNotificationMutationOptions = Apollo.BaseMutationOptions<SendEmailNotificationMutation, SendEmailNotificationMutationVariables>;