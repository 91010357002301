/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import QuestionComponent from '../QuestionComponentDetails';
import { Choice, MSQuestionComponentProps } from './msQuestionComponentProps';

function MSQuestionComponent({
  question,
  choices,
  existingAnswer,
  handleMSAnswerChange,
}: MSQuestionComponentProps) {
  return (
    <>
      <QuestionComponent
        questionContent={question}
        details={
          <FormControl>
            <FormGroup>
              {choices?.map((choice: Choice) => {
                return (
                  <FormControlLabel
                    key={choice.id}
                    control={
                      <Checkbox
                        value={choice.id}
                        defaultChecked={existingAnswer?.choices?.includes(
                          choice.id
                        )}
                        onChange={
                          handleMSAnswerChange as (
                            event: React.ChangeEvent<HTMLInputElement>,
                            checked: boolean
                          ) => void
                        }
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          fontSize: 14,
                        }}
                      >
                        {choice.description}
                      </Typography>
                    }
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        }
      />
    </>
  );
}

export default MSQuestionComponent;
