/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  ApolloClient,
  InMemoryCache,
  from,
  createHttpLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';
import { QuestionAnswerInterface } from './pages/admin/questionBank/QuestionInterface';
import { AllQuestions } from './pages/admin/editExam/editExamProps';

const httpLink = createHttpLink({
  uri: 'https://tzcert-api.merkle-trees.com',
  credentials: 'include',
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          exam: {
            merge: true,
          },
          questionsConnection: relayStylePagination(),
          searchQuestions: relayStylePagination(),
          examsConnection: relayStylePagination(),
          studentExamsConnection: relayStylePagination(),
          studentExamsConnectionByStudentId: relayStylePagination(),
          usersConnection: relayStylePagination(),
          adminsConnection: relayStylePagination(),
          options: {
            merge(
              existing: QuestionAnswerInterface[],
              incoming: QuestionAnswerInterface[]
            ) {
              return incoming;
            },
          },
          choices: {
            merge(
              existing: QuestionAnswerInterface[],
              incoming: QuestionAnswerInterface[]
            ) {
              return incoming;
            },
          },
        },
      },
      Exam: {
        fields: {
          questions: {
            merge(existing: AllQuestions[], incoming: AllQuestions[]) {
              return incoming;
            },
          },
        },
      },
    },
  }),
});
