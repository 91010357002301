import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';
import { USER_KEY, USER_ROLE, USER_NAME } from '../../constants';

export interface UserCredentials {
  email: string;
  firstName: string;
  lastName: string;
}

export const saveUser = (roles: string[], name: string): void => {
  const encryptedRoles: string[] = roles.map((role) =>
    CryptoJS.AES.encrypt(
      role,
      process.env.REACT_APP_ROLES_KEY as string
    ).toString()
  );
  Cookies.set(USER_KEY, 'true');
  Cookies.set(USER_ROLE, JSON.stringify(encryptedRoles));
  Cookies.set(USER_NAME, name);
};

export const getUser = () => {
  return Cookies.get(USER_KEY);
};

export const getUserRoles = (): string[] => {
  let roles: string[];

  try {
    roles = JSON.parse(Cookies.get(USER_ROLE) as string) as string[];
  } catch (e: any) {
    return [];
  }

  const decryptedRoles: string[] = roles.map((role) =>
    CryptoJS.AES.decrypt(
      role,
      process.env.REACT_APP_ROLES_KEY as string
    ).toString(CryptoJS.enc.Utf8)
  );

  return decryptedRoles;
};

export const getUserName = () => {
  return Cookies.get(USER_NAME) as string;
};

export const removeUser = () => {
  Cookies.remove(USER_KEY);
  Cookies.remove(USER_ROLE);
  Cookies.remove(USER_NAME);
};
