import React from 'react';
import { IconButton, Stack, TextField } from '@mui/material';
import { StyledBox } from '../../../../../styles/admin/QuestionsPageStyles';
import CloseIcon from '@mui/icons-material/Close';
import { EditAnswerTextFieldProps } from '../editQuestionProps';

function EditAnswerTextField({
  onChange,
  textValue,
  removeFunction,
}: EditAnswerTextFieldProps) {
  return (
    <StyledBox>
      <Stack direction="row" spacing={2}>
        <TextField
          variant="standard"
          defaultValue={textValue}
          onChange={onChange}
          sx={{ width: '100%' }}
        />
        <IconButton onClick={removeFunction} edge="end">
          <CloseIcon />
        </IconButton>
      </Stack>
    </StyledBox>
  );
}

export default EditAnswerTextField;
