/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { AllQuestions } from '../pages/admin/editExam/editExamProps';

const randomizeQuestions = (array: AllQuestions[], size: number) => {
  const arrayCopy = [...array];
  return [...Array(size)].map(
    () => arrayCopy.splice(Math.floor(Math.random() * arrayCopy.length), 1)[0]
  );
};

export default randomizeQuestions;
