import { styled, TableCell, tableCellClasses } from '@mui/material';

export const DashboardContainer = styled('div')(() => ({
  margin: 30,
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'transparent',
    color: theme.palette.common.white,
    fontWeight: 'bold',
    fontSize: 14,
    paddingBottom: 8,
    paddingTop: 8,
  },
  [`&.${tableCellClasses.body}`]: {
    paddingBottom: 8,
    paddingTop: 8,
  },
}));

export const RowContainer = styled('div')(() => ({
  minHeight: '32px',
}));
