import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetStudentExamsByStudentIdQueryVariables = Types.Exact<{
  studentId: Types.Scalars['String'];
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetStudentExamsByStudentIdQuery = { __typename?: 'Query', studentExamsConnectionByStudentId?: { __typename?: 'studentExamsConnectionByStudentIdPayload', nodes?: Array<{ __typename?: 'StudentHistoryByExam', studentExams?: Array<{ __typename?: 'StudentExam', id?: string | null, score?: number | null, submittedAt?: any | null, passed?: boolean | null, examDetails?: { __typename?: 'ExamDetails', id?: string | null, description?: string | null, title?: string | null, module?: string | null, maxQuestionCount?: number | null, endDate?: any | null, passingRate?: number | null } | null } | null> | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, startCursor?: string | null, endCursor?: string | null } } | null };


export const GetStudentExamsByStudentIdDocument = gql`
    query GetStudentExamsByStudentId($studentId: String!, $first: Int, $last: Int, $after: String, $before: String) {
  studentExamsConnectionByStudentId(
    studentId: $studentId
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    nodes {
      studentExams {
        id
        score
        submittedAt
        passed
        examDetails {
          id
          description
          title
          module
          maxQuestionCount
          endDate
          passingRate
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useGetStudentExamsByStudentIdQuery__
 *
 * To run a query within a React component, call `useGetStudentExamsByStudentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentExamsByStudentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentExamsByStudentIdQuery({
 *   variables: {
 *      studentId: // value for 'studentId'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGetStudentExamsByStudentIdQuery(baseOptions: Apollo.QueryHookOptions<GetStudentExamsByStudentIdQuery, GetStudentExamsByStudentIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentExamsByStudentIdQuery, GetStudentExamsByStudentIdQueryVariables>(GetStudentExamsByStudentIdDocument, options);
      }
export function useGetStudentExamsByStudentIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentExamsByStudentIdQuery, GetStudentExamsByStudentIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentExamsByStudentIdQuery, GetStudentExamsByStudentIdQueryVariables>(GetStudentExamsByStudentIdDocument, options);
        }
export type GetStudentExamsByStudentIdQueryHookResult = ReturnType<typeof useGetStudentExamsByStudentIdQuery>;
export type GetStudentExamsByStudentIdLazyQueryHookResult = ReturnType<typeof useGetStudentExamsByStudentIdLazyQuery>;
export type GetStudentExamsByStudentIdQueryResult = Apollo.QueryResult<GetStudentExamsByStudentIdQuery, GetStudentExamsByStudentIdQueryVariables>;