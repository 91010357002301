/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import {
  Box,
  IconButton,
  Button,
  Typography,
  Stack,
  FormControl,
  TextField,
  Radio,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  useMediaQuery,
} from '@mui/material';
import { CloseOutlined } from '@mui/icons-material';
import { MCQOptionInterface } from './optionInterface';
import CustomAlert from '../../../../alert/CustomAlert';
import CREATE_MC_QUESTION_MUTATION from '../../../../../graphql/mutations/admin/multipleChoice/question/createMultipleChoiceQuestion';
import {
  CreateMultipleChoiceQuestionMutation,
  CreateMultipleChoiceQuestionMutationVariables,
} from '../../../../../graphql/mutations/admin/multipleChoice/question/createMultipleChoiceQuestion.generated';
import { QuestionTagsProps } from '../addQuestionProps';

const style = {
  maxHeight: 200,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column-reverse',
};

const style2 = {
  maxHeight: 100,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column-reverse',
};

const defaultOptions = [
  {
    id: '1',
    description: '',
    isCorrect: false,
  },
  {
    id: '2',
    description: '',
    isCorrect: false,
  },
  {
    id: '3',
    description: '',
    isCorrect: false,
  },
  {
    id: '4',
    description: '',
    isCorrect: false,
  },
];

let options: MCQOptionInterface[] = JSON.parse(
  JSON.stringify(defaultOptions)
) as MCQOptionInterface[];

const MultipleChoiceComponent = ({ program, module }: QuestionTagsProps) => {
  const [questionContent, setQuestionContent] = useState('');
  const [option, setOption] = useState('');
  const [allOptions, updateAllOptions] =
    useState<MCQOptionInterface[]>(options);
  const [selectedValue, setSelectedValue] = useState('');
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(5);

  const matches = useMediaQuery('(min-width:600px)');

  const handleQuestionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuestionContent(event.target.value);
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOption(event.target.value);
  };

  const handleDefaultOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    const { id, value } = event.target;
    const allOptionsCopy = [...allOptions];
    allOptionsCopy[Number(id)].description = value;
    updateAllOptions(allOptionsCopy);
  };

  const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const setCorrectOption = (value: string) => {
    const foundOption = allOptions.find((element) => element.id === value);
    const incorrectOptions = allOptions.filter(
      (element) => element.id !== value
    );

    if (foundOption) {
      foundOption.isCorrect = true;
      incorrectOptions.forEach((element) => {
        element.isCorrect = false;
      });
    }
  };

  const removeOption = (index: number) => {
    updateAllOptions(options.splice(index, 1));
  };

  const resetInputField = () => {
    setOption('');
  };

  const resetDefaultOptions = () => {
    options = JSON.parse(
      JSON.stringify(defaultOptions)
    ) as MCQOptionInterface[];
    updateAllOptions(options);
  };

  const resetFields = (waitTime: number) => {
    setTimeout(() => {
      resetDefaultOptions();
      setQuestionContent('');
      setSelectedValue('');
    }, waitTime);
  };

  useEffect(() => {
    resetFields(0);
  }, []);

  useEffect(() => {
    updateAllOptions(options);
  }, [allOptions]);

  useEffect(() => {
    setCorrectOption(selectedValue);
  }, [selectedValue]);

  const [createMCQuestion] = useMutation<
    CreateMultipleChoiceQuestionMutation,
    CreateMultipleChoiceQuestionMutationVariables
  >(CREATE_MC_QUESTION_MUTATION);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const optionsList = allOptions
      .map((optionElement) => ({
        description: optionElement.description,
        isCorrect: optionElement.isCorrect,
      }))
      .filter((optionElement) => optionElement.description !== '');

    const isListValid =
      optionsList.filter((optionElement) => optionElement.isCorrect).length > 0;

    if (isListValid && module) {
      createMCQuestion({
        variables: {
          input: {
            program: program,
            module: module,
            question: questionContent,
            options: optionsList,
          },
        },
        onCompleted: () => {
          setOpen(true);
          resetFields(1500);
        },
      });
    }
  };

  return (
    <Box>
      <CustomAlert
        message="Question was successfully added!"
        open={open}
        handleClose={() => setOpen(false)}
      />

      <form onSubmit={handleSubmit}>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" spacing={matches ? 2 : 2.2} marginY={1}>
            <Typography
              data-testid="mcq-question-label"
              sx={{ mt: 2, fontSize: matches ? 16 : 14 }}
            >
              Question Content
            </Typography>
            <TextField
              required
              multiline
              data-testid="mcq-question-content"
              variant="outlined"
              placeholder="Type question here"
              onChange={handleQuestionChange}
              value={questionContent}
              sx={{ width: matches ? 400 : 220 }}
            />
          </Stack>

          <Box sx={matches ? style : style2}>
            <div>
              {allOptions?.map((optionElement: MCQOptionInterface, index) => {
                const { id, description } = optionElement;

                return (
                  <Stack direction="row" marginY={1} key={index}>
                    <Box width={matches ? 140 : 90} />
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        data-testid="mcq-options-radio"
                        value={selectedValue}
                        onChange={handleAnswerChange}
                      >
                        <FormControlLabel
                          data-testid="radio"
                          value={id}
                          control={<Radio />}
                          label={
                            <TextField
                              key={id}
                              multiline
                              id={String(index)}
                              data-testid="mcq-added-option"
                              variant="standard"
                              placeholder={`option ${index + 1}`}
                              value={description}
                              onChange={handleDefaultOptionChange}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => {
                                        removeOption(index);
                                      }}
                                      edge="end"
                                    >
                                      <CloseOutlined />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              sx={{ width: matches ? 380 : 200 }}
                            />
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Stack>
                );
              })}
            </div>
          </Box>

          <Stack direction="row" spacing={0.5}>
            <Box width={140} />
            <TextField
              data-testid="mcq-option"
              variant="standard"
              placeholder="new option"
              onChange={handleOptionChange}
              value={option}
              sx={{ width: 400 }}
            />
            <Button
              disableRipple
              onClick={() => {
                options.push({
                  id: String(count),
                  description: option,
                  isCorrect: false,
                });
                setCount(count + 1);
                resetInputField();
              }}
              sx={{
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                },
              }}
            >
              Add
            </Button>
          </Stack>

          <Box height={10} />

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            <Button
              type="submit"
              variant="contained"
              disableElevation
              size={matches ? 'medium' : 'small'}
              sx={{
                width: matches ? 155 : 130,
                textTransform: 'none',
              }}
            >
              Save Question
            </Button>
          </div>
        </Stack>
      </form>
    </Box>
  );
};

export default MultipleChoiceComponent;
