import { gql } from '@apollo/client';

const DELETE_ADMIN_MUTATION = gql`
  mutation DeleteAdmin($input: DeleteAdminInput!) {
    deleteAdmin(input: $input) {
      id
      email
    }
  }
`;

export default DELETE_ADMIN_MUTATION;
