import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExamQueryVariables = Types.Exact<{
  examId: Types.Scalars['String'];
}>;


export type ExamQuery = { __typename?: 'Query', exam?: { __typename?: 'Exam', id?: string | null, title?: string | null, description?: string | null, program?: Types.Program | null, module?: string | null, year?: number | null, quarter?: Types.Quarter | null, isPublished?: boolean | null, maxQuestionCount?: number | null, createdAt?: any | null, updatedAt?: any | null, createdById?: string | null, startDate?: any | null, endDate?: any | null, duration?: number | null, examType?: Types.ExamType | null, questions?: Array<{ __typename?: 'MultipleChoiceQuestion', id?: string | null, question?: string | null, updatedAt?: any | null, createdById?: string | null } | { __typename?: 'MultipleSelectionQuestion', id?: string | null, question?: string | null, updatedAt?: any | null, createdById?: string | null } | null> | null } | null };


export const ExamDocument = gql`
    query Exam($examId: String!) {
  exam(id: $examId) {
    id
    title
    description
    program
    module
    year
    quarter
    isPublished
    maxQuestionCount
    createdAt
    updatedAt
    createdById
    startDate
    endDate
    duration
    examType
    questions {
      id
      question
      updatedAt
      createdById
    }
  }
}
    `;

/**
 * __useExamQuery__
 *
 * To run a query within a React component, call `useExamQuery` and pass it any options that fit your needs.
 * When your component renders, `useExamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExamQuery({
 *   variables: {
 *      examId: // value for 'examId'
 *   },
 * });
 */
export function useExamQuery(baseOptions: Apollo.QueryHookOptions<ExamQuery, ExamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExamQuery, ExamQueryVariables>(ExamDocument, options);
      }
export function useExamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExamQuery, ExamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExamQuery, ExamQueryVariables>(ExamDocument, options);
        }
export type ExamQueryHookResult = ReturnType<typeof useExamQuery>;
export type ExamLazyQueryHookResult = ReturnType<typeof useExamLazyQuery>;
export type ExamQueryResult = Apollo.QueryResult<ExamQuery, ExamQueryVariables>;