import React from 'react';
import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { StyledEmptyDataMessage } from '../../../styles/admin/QuestionsPageStyles';

function EmptyDataComponent(props: { message: string }) {
  return (
    <>
      <TableBody>
        <TableRow>
          <TableCell>
            <StyledEmptyDataMessage>
              <Typography style={{ color: '#BEBEBE' }} variant="h5">
                {props.message}
              </Typography>
            </StyledEmptyDataMessage>
          </TableCell>
        </TableRow>
      </TableBody>
    </>
  );
}

export default EmptyDataComponent;
