/* eslint-disable @typescript-eslint/naming-convention */

import React, { useState, useEffect } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Typography, Stack, Chip, Button } from '@mui/material';
import ExamDescription from '../../../components/admin/viewExam/ExamDescription';
import ExamDetails from '../../../components/admin/viewExam/ExamDetails';
import {
  ExamsPageContainer,
  ExamContentsBox,
  ExamProgressContainer,
  InvalidExamLinkBox,
  InvalidExamLinkContainer,
} from '../../../styles/student/StudentExamPageStyles';
import { Exam, ExamAnswerableQuestionInterface } from './examProps';
import AnswerableQuestions from './examQuestions/AnswerableQuestions';
import {
  ExamType,
  InputMaybe,
  ExamDetailsInput,
} from '../../../types.generated';
import ExamTimer from './ExamTimer';
import formatText from '../../../utils/textFormatter';
import UPDATE_PUBLISH_MUTATION from '../../../graphql/mutations/admin/exam/updatePublishStatus/updatePublishStatus';
import {
  UpdateExamPublishStatusMutation,
  UpdateExamPublishStatusMutationVariables,
} from '../../../graphql/mutations/admin/exam/updatePublishStatus/updatePublishStatus.generated';
import { useSendEmailNotificationMutation } from '../../../graphql/mutations/admin/exam/subscription/sendEmailNotification.generated';
import OPEN_EXAM_MUTATION from '../../../graphql/mutations/student/openExam/openExam';
import {
  OpenStudentExamMutation,
  OpenStudentExamMutationVariables,
} from '../../../graphql/mutations/student/openExam/openExam.generated';
import getRandomQuestions from '../../../utils/generateRandomQuestions';
import { AnswerSheetInterface } from '../../../utils/generateAnswerSheet';
import { useIsExamLinkValidMutation } from '../../../graphql/mutations/student/checkExamLink/checkExamLink.generated';
import LoadingPage from '../../../components/states/LoadingPage';

const chipsStyle = { fontSize: '11px', backgroundColor: '#f5f5f5' };

interface StudentExamProps {
  exam: Exam;
  questions: ExamAnswerableQuestionInterface[];
  publishStatus: boolean;
}

const ExamPage = ({ exam, questions, publishStatus }: StudentExamProps) => {
  const params = useParams();
  const token = String(params.authToken);

  const navigate = useNavigate();
  const [showExamContent, setShowExamContent] = useState<boolean>(false);
  const [isExamOpen, setIsExamOpen] = useState<boolean>(false);
  const [isExamLinkValid, setIsExamLinkValid] = useState<boolean | undefined>(
    true
  );

  const [examStartTimestamp, setExamStartTimestamp] = useState<Date>(
    new Date()
  );
  const [examInstanceId, setExamInstanceId] = useState<string | undefined>();
  const [questionsToUse, setQuestionsToUse] = useState<
    ExamAnswerableQuestionInterface[]
  >([]);
  const [examExistingAnswers, setExamExistingAnswers] = useState<
    AnswerSheetInterface[]
  >([]);

  const [updatePublishStatus] = useMutation<
    UpdateExamPublishStatusMutation,
    UpdateExamPublishStatusMutationVariables
  >(UPDATE_PUBLISH_MUTATION);

  const [openExam] = useMutation<
    OpenStudentExamMutation,
    OpenStudentExamMutationVariables
  >(OPEN_EXAM_MUTATION);

  const [sendEmailNotification] = useSendEmailNotificationMutation();
  const [isExamLinkValidMutation, { loading: isExamLinkValidLoading }] =
    useIsExamLinkValidMutation();

  useEffect(() => {
    try {
      isExamLinkValidMutation({
        variables: {
          input: {
            examId: exam.id,
            token: token,
          },
        },
        onCompleted: (data) => {
          setIsExamLinkValid(Boolean(data?.isExamLinkValid));
        },
      });
    } catch (error) {
      console.log(error);
    }

    if (exam.examType === 'FIXED') {
      if (exam.startDate && exam.endDate) {
        if (Date.now() >= exam.startDate && Date.now() <= exam.endDate) {
          updatePublishStatus({
            variables: {
              input: {
                id: exam.id,
                examType: exam.examType as ExamType,
                isPublished: true,
                startDate: exam.startDate as InputMaybe<string>,
                endDate: exam.endDate as InputMaybe<string>,
              },
            },
          });
        } else {
          setIsExamOpen(false);
          updatePublishStatus({
            variables: {
              input: {
                id: exam.id,
                examType: exam.examType as ExamType,
                isPublished: false,
                startDate: exam.startDate as InputMaybe<string>,
                endDate: exam.endDate as InputMaybe<string>,
              },
            },
          });
        }
      }
    }
  }, [exam]);

  const randomizedQuestions = () => {
    const randomQuestions = getRandomQuestions(
      questions,
      exam.maxQuestionCount
    );

    return randomQuestions;
  };

  const handleOpenExam = () => {
    const examOpenTimestamp = new Date();
    openExam({
      variables: {
        input: {
          examDetails: exam as ExamDetailsInput,
          examQuestions: randomizedQuestions(),
          openedAt: examOpenTimestamp.toISOString(),
          token: token,
        },
      },
      onCompleted: (data: OpenStudentExamMutation) => {
        if (data) {
          const id = data.openStudentExam?.id;
          const date = data.openStudentExam?.openedAt;
          const answers = data.openStudentExam?.studentAnswers;
          const existingQuestions = data.openStudentExam?.examQuestions;
          setQuestionsToUse(
            existingQuestions as ExamAnswerableQuestionInterface[]
          );
          setExamInstanceId(id);
          setExamStartTimestamp(date as Date);
          setShowExamContent(true);
          setExamExistingAnswers(answers ? answers : []);
          setIsExamOpen(true);
          // sendEmailNotification({
          //   variables: {
          //     input: {
          //       event: 'started',
          //       examId: exam.id,
          //     },
          //   },
          // });
        }
      },
      onError: (errorObject: ApolloError) => {
        try {
          const { data } = JSON.parse(errorObject.message);
          navigate(`/take/${data.examDetails.id as string}/score`, {
            state: {
              examDetails: data.examDetails,
              score: data.score,
              examStartTimestamp: data.openedAt,
              examEndTimestamp: data.submittedAt,
            },
          });
        } catch (e) {
          console.log(e);
        }
      },
    });
  };

  if (isExamLinkValidLoading && isExamLinkValid) {
    return <LoadingPage />;
  }

  if (!isExamLinkValid) {
    return (
      <ExamsPageContainer>
        <InvalidExamLinkContainer>
          <InvalidExamLinkBox>
            <Typography
              sx={{ fontSize: 20, fontWeight: 'bold', color: '#505050' }}
            >
              Invalid Link
            </Typography>
          </InvalidExamLinkBox>
        </InvalidExamLinkContainer>
      </ExamsPageContainer>
    );
  }

  return (
    <ExamsPageContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={9}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            marginY={1}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                color="secondary.main"
                variant="h5"
                fontWeight="fontWeightBold"
              >
                {exam.title}
              </Typography>
              <Chip
                label={formatText(exam.program)}
                size="small"
                sx={chipsStyle}
              />
              <Chip
                label={exam.year.toString() + exam.quarter}
                size="small"
                sx={chipsStyle}
              />
            </Stack>
          </Stack>
          <ExamDetails
            numOfItems={exam.maxQuestionCount}
            typeOfExam={exam.examType as ExamType}
            startDate={exam.startDate as number}
            endDate={exam.endDate as number}
            duration={exam.duration}
            titleGridSize={4}
            detailGridSize={8}
          />
          {publishStatus && <ExamDescription content={exam.description} />}

          {showExamContent && isExamOpen === true && examInstanceId ? (
            <AnswerableQuestions
              instanceId={examInstanceId}
              examDetails={exam}
              questions={questionsToUse}
              examStartTimestamp={examStartTimestamp}
              duration={exam.duration as number}
              existingAnswers={examExistingAnswers}
            />
          ) : (
            <ExamContentsBox>
              {publishStatus && isExamOpen == false && (
                <Stack direction="column" spacing={1}>
                  <Typography sx={{ fontSize: 16, fontWeight: 'bold' }}>
                    Your exam is ready.
                  </Typography>
                  <Button
                    data-testid="take-exam-button"
                    variant="contained"
                    disableElevation
                    onClick={() => {
                      handleOpenExam();
                    }}
                    sx={{ textTransform: 'none' }}
                  >
                    Take the Exam
                  </Button>
                </Stack>
              )}
              {publishStatus == false && (
                <Typography sx={{ fontSize: 16, color: '#9F9F9F' }}>
                  The exam is unpublished and unavailable yet.
                </Typography>
              )}
            </ExamContentsBox>
          )}
        </Grid>
        {isExamOpen && exam.examType !== 'FREE' && (
          <Grid item xs={12} sm={12} md={3}>
            <ExamProgressContainer>
              <Stack
                direction="column"
                spacing={2}
                sx={{ position: 'sticky', top: 40 }}
              >
                <Typography
                  color="secondary.main"
                  fontWeight="fontWeightBold"
                  sx={{ fontSize: 16 }}
                >
                  Questions
                </Typography>
                {/* Add current question "tracker" here */}
                {exam.examType === 'FLEXIBLE' && (
                  <Stack direction="column" spacing={1}>
                    <Typography
                      color="secondary.main"
                      fontWeight="fontWeightBold"
                      sx={{ fontSize: 16 }}
                    >
                      Time Elapsed
                    </Typography>
                    <ExamTimer
                      openedAt={examStartTimestamp}
                      duration={exam.duration as number}
                    />
                  </Stack>
                )}
              </Stack>
            </ExamProgressContainer>
          </Grid>
        )}
      </Grid>
    </ExamsPageContainer>
  );
};

export default ExamPage;
