import React from 'react';
import { Stack, Skeleton } from '@mui/material';

function TableIndividualRowLoading() {
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1}>
          <Skeleton
            animation="wave"
            variant="circular"
            width={20}
            height={20}
          />
          <Skeleton animation="wave" width={150} height={30} />
          <Skeleton animation="wave" width={50} height={30} />
          <Skeleton animation="wave" width={50} height={30} />
        </Stack>
        <Skeleton animation="wave" variant="circular" width={20} height={20} />
      </Stack>
    </>
  );
}

export default TableIndividualRowLoading;
