/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import {
  GetUserQuery,
  useGetUserQuery,
} from '../../../graphql/queries/user/user.generated';
import MCQDisplay from '../ExamQuestionsView/MCQuestion/MCQDisplay';
import MSQDisplay from '../ExamQuestionsView/MSQuestion/MSQDisplay';
import { QuestionDisplayProps } from './displayProps';

function QuestionDisplayContainer({
  questionId,
  questionType,
  authorId,
  showDetails,
  handleDelete,
}: QuestionDisplayProps) {
  const { data, loading, error } = useGetUserQuery({
    variables: {
      id: authorId,
    },
    pollInterval: 2000,
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error...</div>;

  switch (questionType) {
    case 'MultipleChoiceQuestion':
      return (
        <MCQDisplay
          key={questionId}
          id={questionId}
          author={data as GetUserQuery}
          showDetails={showDetails}
          handleDelete={handleDelete}
        />
      );
    case 'MultipleSelectionQuestion':
      return (
        <MSQDisplay
          key={questionId}
          id={questionId}
          author={data as GetUserQuery}
          showDetails={showDetails}
          handleDelete={handleDelete}
        />
      );
    default:
      return null;
  }
}

export default QuestionDisplayContainer;
