import { gql } from '@apollo/client';

const UPDATE_PUBLISH_MUTATION = gql`
  mutation UpdateExamPublishStatus($input: UpdateExamInput!) {
    updateExam(input: $input) {
      isPublished
    }
  }
`;

export default UPDATE_PUBLISH_MUTATION;
