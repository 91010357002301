import React from 'react';
import { useParams } from 'react-router-dom';
import ViewExamPage from './ViewExamPage';
import ErrorPage from '../../../components/states/ErrorPage';
import LoadingPage from '../../../components/states/LoadingPage';
import { useExamQuery } from '../../../graphql/queries/admin/exam/getExam.generated';
import { ExamQuestionInterface } from './examQuestionInterface';
import { ExamType } from '../../../types.generated';

function ViewExamDataPage() {
  const params = useParams();
  const id: string = params.id as string;

  const { data, loading, error } = useExamQuery({
    variables: {
      examId: id,
    },
    pollInterval: 2000,
  });

  const examTitle: string = data?.exam?.title as string;
  const examDescription: string = data?.exam?.description as string;
  const examProgram: string = data?.exam?.program as string;
  const examModule: string = data?.exam?.module as string;
  const year: number = data?.exam?.year as number;
  const quarter: string = data?.exam?.quarter as string;
  const startDate: number = data?.exam?.startDate as number;
  const endDate: number = data?.exam?.endDate as number;
  const duration: number = data?.exam?.duration as number;
  const questions: ExamQuestionInterface[] = data?.exam
    ?.questions as ExamQuestionInterface[];
  const examType = data?.exam?.examType as ExamType;
  const isPublished: boolean = data?.exam?.isPublished as boolean;

  if (loading) return <LoadingPage />;
  if (error) return <ErrorPage errorMessage={error.message} />;

  return (
    <>
      <ViewExamPage
        id={id}
        title={examTitle}
        year={year}
        quarter={quarter}
        program={examProgram}
        module={examModule}
        description={examDescription}
        examDetails={{
          startDate: startDate,
          endDate: endDate,
          duration: duration,
          typeOfExam: examType,
          numOfItems: questions.length,
          titleGridSize: 3,
          detailGridSize: 6,
        }}
        questions={questions}
        published={isPublished}
      />
    </>
  );
}

export default ViewExamDataPage;
