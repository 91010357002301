import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StudentExamQuestionsQueryVariables = Types.Exact<{
  examId: Types.Scalars['String'];
}>;


export type StudentExamQuestionsQuery = { __typename?: 'Query', exam?: { __typename?: 'Exam', questions?: Array<{ __typename?: 'MultipleChoiceQuestion', id?: string | null, question?: string | null, program?: Types.Program | null, module?: string | null, createdAt?: any | null, updatedAt?: any | null, createdById?: string | null, questionType?: Types.QuestionType | null, options?: Array<{ __typename?: 'Option', id?: string | null, description?: string | null } | null> | null, createdBy?: { __typename?: 'User', id?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null } | null } | { __typename?: 'MultipleSelectionQuestion', id?: string | null, question?: string | null, program?: Types.Program | null, module?: string | null, createdAt?: any | null, updatedAt?: any | null, createdById?: string | null, questionType?: Types.QuestionType | null, choices?: Array<{ __typename?: 'Choice', id?: string | null, description?: string | null } | null> | null, createdBy?: { __typename?: 'User', id?: string | null, email?: string | null, firstName?: string | null, lastName?: string | null } | null } | null> | null } | null };


export const StudentExamQuestionsDocument = gql`
    query StudentExamQuestions($examId: String!) {
  exam(id: $examId) {
    questions {
      id
      question
      program
      module
      createdAt
      updatedAt
      createdById
      createdBy {
        id
        email
        firstName
        lastName
      }
      questionType
      ... on MultipleChoiceQuestion {
        options {
          id
          description
        }
      }
      ... on MultipleSelectionQuestion {
        choices {
          id
          description
        }
      }
    }
  }
}
    `;

/**
 * __useStudentExamQuestionsQuery__
 *
 * To run a query within a React component, call `useStudentExamQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStudentExamQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStudentExamQuestionsQuery({
 *   variables: {
 *      examId: // value for 'examId'
 *   },
 * });
 */
export function useStudentExamQuestionsQuery(baseOptions: Apollo.QueryHookOptions<StudentExamQuestionsQuery, StudentExamQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StudentExamQuestionsQuery, StudentExamQuestionsQueryVariables>(StudentExamQuestionsDocument, options);
      }
export function useStudentExamQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StudentExamQuestionsQuery, StudentExamQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StudentExamQuestionsQuery, StudentExamQuestionsQueryVariables>(StudentExamQuestionsDocument, options);
        }
export type StudentExamQuestionsQueryHookResult = ReturnType<typeof useStudentExamQuestionsQuery>;
export type StudentExamQuestionsLazyQueryHookResult = ReturnType<typeof useStudentExamQuestionsLazyQuery>;
export type StudentExamQuestionsQueryResult = Apollo.QueryResult<StudentExamQuestionsQuery, StudentExamQuestionsQueryVariables>;