import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ErrorPage from '../../../components/states/ErrorPage';
import LoadingPage from '../../../components/states/LoadingPage';
import ExamPage from './ExamPage';
import { Exam, ExamAnswerableQuestionInterface } from './examProps';
import { useStudentExamDetailsQuery } from '../../../graphql/queries/student/exam/getStudentExamDetails.generated';
import { useStudentExamQuestionsQuery } from '../../../graphql/queries/student/exam/getStudentExamQuestions.generated';
import { useStudentExamPublishStatusQuery } from '../../../graphql/queries/student/exam/getStudentExamPublish.generated';

function ExamData() {
  const params = useParams();
  const examId = String(params.examId);

  useEffect(() => {
    if (localStorage.getItem('link')) {
      localStorage.removeItem('link');
    }
  }, []);

  const {
    data: examDetails,
    loading: examDetailsLoading,
    error: examDetailsError,
  } = useStudentExamDetailsQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      examId: examId,
    },
    pollInterval: 2000,
  });

  const {
    data: examQuestions,
    loading: examQuestionsLoading,
    error: examQuestionsError,
  } = useStudentExamQuestionsQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      examId: examId,
    },
    pollInterval: 2000,
  });

  const {
    data: publishStatus,
    loading: publishStatusLoading,
    error: publishStatusError,
  } = useStudentExamPublishStatusQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      examId: examId,
    },
    pollInterval: 2000,
  });

  if (examDetailsLoading || examQuestionsLoading || publishStatusLoading)
    return <LoadingPage />;
  if (examDetailsError || examQuestionsError || publishStatusError) {
    const errors = [examDetailsError, examQuestionsError, publishStatusError];
    const error = errors.filter((err) => {
      if (err) return err;
    });

    return <ErrorPage errorMessage={error[0]?.message} />;
  }

  const exam = examDetails?.exam as Exam;
  const questions = examQuestions?.exam?.questions;
  const status = publishStatus?.exam?.isPublished as boolean;

  const { __typename, ...rest } = exam;

  return (
    <ExamPage
      exam={{ ...rest }}
      questions={questions as ExamAnswerableQuestionInterface[]}
      publishStatus={status}
    />
  );
}

export default ExamData;
