export const programs = [
  {
    id: 1,
    title: 'Blockchain',
    value: 'BLOCKCHAIN',
  },
  {
    id: 2,
    title: 'Full Stack',
    value: 'FULL_STACK',
  },
  {
    id: 3,
    title: 'Cybersecurity',
    value: 'CYBERSECURITY',
  },
  {
    id: 4,
    title: 'Tech Sales',
    value: 'TECH_SALES',
  },
];

export const quarters = [
  {
    id: 1,
    title: 'Q1',
  },
  {
    id: 2,
    title: 'Q2',
  },
  {
    id: 3,
    title: 'Q3',
  },
  {
    id: 4,
    title: 'Q4',
  },
];

export const types = [
  {
    id: 1,
    title: 'Free',
    value: 'FREE',
  },
  {
    id: 2,
    title: 'Fixed',
    value: 'FIXED',
  },
  {
    id: 3,
    title: 'Flexible',
    value: 'FLEXIBLE',
  },
];
