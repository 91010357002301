import { gql } from '@apollo/client';

export const UPDATE_MS_QUESTION_MUTATION = gql`
  mutation UpdateMultipleSelectionQuestion(
    $input: UpdateMultipleSelectionQuestionInput!
  ) {
    updateMultipleSelectionQuestion(input: $input) {
      id
      program
      module
      question
    }
  }
`;
