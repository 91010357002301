import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  ExamHeaderDiv,
  ExamReturnButton,
} from '../../../styles/admin/ViewExamStyles';
import QuestionComponent from '../../../components/admin/viewExam/QuestionComponent/QuestionComponent';
import { ViewStudentExamProps } from './viewStudentExamProps';

const ViewStudentExamPage = ({ exam }: ViewStudentExamProps) => {
  const navigate = useNavigate();

  const renderHeader = () => {
    const getAnswerAmount = () => {
      if (
        exam?.studentExam?.examSummary?.length ===
        exam?.studentExam?.examDetails?.maxQuestionCount
      ) {
        const answeredQuestions = exam?.studentExam?.examSummary?.filter(
          (summaryItem: any) => {
            const { choices, option } = summaryItem?.studentAnswer;
            if (choices) return choices.length !== 0;
            return option !== '';
          }
        );

        return String(answeredQuestions?.length);
      } else {
        return String(exam?.studentExam?.examSummary?.length);
      }
    };

    const values = [
      exam?.studentExam?.examDetails?.title,
      `${exam?.studentExam?.student?.firstName || ''} ${
        exam?.studentExam?.student?.lastName || ''
      }`,
      `(${getAnswerAmount()} out of ${
        exam?.studentExam?.examDetails?.maxQuestionCount || ''
      } questions answered)`,
    ];

    return values.map((item, i) => (
      <Typography
        key={i}
        color="secondary.main"
        variant="h6"
        fontWeight="fontWeightBold"
      >
        {item}
      </Typography>
    ));
  };

  return (
    <div>
      <ExamReturnButton>
        <Button
          color="secondary"
          startIcon={<ArrowBackIosNewIcon />}
          sx={{ textTransform: 'none' }}
          onClick={() => navigate(-1)}
        >
          Return
        </Button>
      </ExamReturnButton>
      <Container maxWidth="md" sx={{ mt: 1, paddingBottom: '40px' }}>
        <ExamHeaderDiv>{renderHeader()}</ExamHeaderDiv>
        {exam?.studentExam?.examSummary?.map((item, i) => {
          if (item?.question?.__typename && item.question.id)
            return (
              <QuestionComponent
                key={item.question.id}
                type={item.question.__typename}
                id={item.question.id}
                studentAnswer={item?.studentAnswer}
              />
            );
        })}
      </Container>
    </div>
  );
};

export default ViewStudentExamPage;
