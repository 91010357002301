import React from 'react';
import {
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { CloseOutlined } from '@mui/icons-material';
import AddQuestionModal from '../../modal/admin/AddQuestion/AddQuestionModal';
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from '../../../styles/admin/QuestionsPageStyles';
import { QBHeaderProps } from './questionBankProps';

function QuestionBankHeader(props: QBHeaderProps) {
  const { data, searchValue, changeFunction, handleClearInput } = props;
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} md={6}>
        <Typography
          color="secondary.main"
          variant="h5"
          fontWeight="fontWeightBold"
          gutterBottom
          sx={{ mr: 1 }}
        >
          Question bank
        </Typography>
      </Grid>
      <Grid item xs={10} md={4} container alignItems="center">
        <Search>
          <SearchIconWrapper>
            <SearchIcon sx={{ color: '#C4C4C4' }} />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            value={searchValue}
            onChange={changeFunction}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  disableRipple
                  onClick={
                    handleClearInput as React.MouseEventHandler<HTMLButtonElement>
                  }
                  edge="end"
                  sx={{
                    paddingRight: 2,
                    paddingLeft: matches ? 0 : 5,
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: 'transparent',
                    },
                  }}
                >
                  <CloseOutlined />
                </IconButton>
              </InputAdornment>
            }
          />
        </Search>
      </Grid>
      <Grid
        item
        xs={2}
        md={2}
        container
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <AddQuestionModal data={data} />
      </Grid>
    </Grid>
  );
}

export default QuestionBankHeader;
