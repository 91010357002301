import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Grid,
  Stack,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  Dialog,
  DialogContent,
  useMediaQuery,
  FormControl,
  RadioGroup,
  Radio,
  TextField,
} from '@mui/material';
import EditExamContainer from '../../../styles/admin/EditExamPageStyles';
import { useMutation } from '@apollo/client';
import {
  StyledDialogContent,
  StyledEmptyDataMessage,
  StyledTableCell,
} from '../../../styles/admin/QuestionsPageStyles';
import { Add } from '@mui/icons-material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import formatQuestionContent from '../../../utils/questionContentFormatter';
import QuestionDisplayContainer from '../../../components/card/ExamQuestionsView/QuestionDisplayContainer';
import CustomDialog from '../../../components/dialog/CustomDialog';
import message from '../../../components/dialog/message.json';
import { AllQuestions, EditExamPageProps } from './editExamProps';
import { formatProgramText } from '../../../utils/wordFormatter';
import EditExamModal from '../../../components/modal/admin/EditExam/EditExamModal';
import ExamDescription from '../../../components/admin/viewExam/ExamDescription';
import ExamDetails from '../../../components/admin/viewExam/ExamDetails';
import EmptyDataComponent from '../../../components/states/componentStates/EmptyDataComponent';
import { StyledDialogButtonBox } from '../../../styles/styledComponents/CustomDialogStyles';
import ADD_QUESTION_ON_EXAM_MUTATION from '../../../graphql/mutations/admin/exam/addQuestionOnExam';
import REMOVE_QUESTION_ON_EXAM_MUTATION from '../../../graphql/mutations/admin/exam/removeQuestionOnExam';
import randomizeQuestions from '../../../utils/questionsRandomizer';
import findById from '../../../utils/arrayDataFinder';
import spliceArray from '../../../utils/arraySplicer';
import CustomDialogTitle from '../../../components/modal/admin/common/CustomDialogTitle';
import Tag from '../../../components/common/ChipTags';

const fontStyle = { fontSize: '18px', color: '#9F9F9F' };

const deletedQuestions: AllQuestions[] = [];

const EditExamPage = (props: EditExamPageProps) => {
  const matches = useMediaQuery('(min-width:600px)');
  const navigate = useNavigate();
  const { questions, exam } = props;
  let addedQuestions: AllQuestions[] = [];

  const [allQuestions, setAllQuestions] = useState<AllQuestions[]>(questions);
  const [examQuestions, setExamQuestions] = useState<AllQuestions[]>(
    exam.questions
  );
  const [checked, setChecked] = useState(false);
  const [openUnsaveModal, setOpenUnsaveModal] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('all');
  const [maxQuestionsCount, setMaxQuestionsCount] = useState(0);

  const [addQuestion] = useMutation(ADD_QUESTION_ON_EXAM_MUTATION);
  const [removeQuestion] = useMutation(REMOVE_QUESTION_ON_EXAM_MUTATION);

  const questionsLength = allQuestions?.length;
  const examQuestionsLength = examQuestions?.length;
  const examId = exam.id;

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue((event.target as HTMLInputElement).value);
  };

  const handleMaxQuestionsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMaxQuestionsCount(Number(event.target.value));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleProceed = () => {
    if (selectedValue === 'all') {
      const allQuestionsCopy = [...allQuestions];
      allQuestionsCopy?.forEach((questionData) => {
        const existingDeletedQuestion = findById(
          deletedQuestions,
          String(questionData.id)
        );

        if (existingDeletedQuestion) {
          spliceArray(deletedQuestions, existingDeletedQuestion);
        }
      });
      setExamQuestions(allQuestionsCopy);
    } else {
      const randomQuestions = randomizeQuestions(
        allQuestions,
        maxQuestionsCount
      );
      randomQuestions?.forEach((questionData) => {
        const existingDeletedQuestion = findById(
          deletedQuestions,
          String(questionData.id)
        );

        if (existingDeletedQuestion) {
          spliceArray(deletedQuestions, existingDeletedQuestion);
        }
      });
      setExamQuestions(randomQuestions);
    }
    handleClose();
    setSelectedValue('all');
    setMaxQuestionsCount(0);
  };

  const handleDelete = (questionData: AllQuestions) => {
    addedQuestions = examQuestions?.filter(
      (element) => element.id !== questionData.id
    );
    const existingDeletedQuestion = findById(
      deletedQuestions,
      String(questionData.id)
    );

    setExamQuestions(addedQuestions);

    if (!existingDeletedQuestion) {
      deletedQuestions.push(questionData);
    }
  };

  const handleAddQuestion = (questionData: AllQuestions) => {
    const existingQuestion = findById(examQuestions, String(questionData.id));
    const deletedQuestion = findById(deletedQuestions, String(questionData.id));

    if (!existingQuestion) {
      addedQuestions.push(questionData);
      setExamQuestions(examQuestions.concat(addedQuestions));
    }

    if (deletedQuestion) {
      spliceArray(deletedQuestions, questionData);
    }
  };

  const handleConfirmSave = () => {
    deletedQuestions.forEach((deletedQuestion) => {
      const existingQuestion = findById(
        exam.questions,
        String(deletedQuestion.id)
      );

      if (existingQuestion) {
        removeQuestion({
          variables: {
            input: {
              examId: examId,
              questionId: deletedQuestion.id,
            },
          },
        });
      }
    });

    examQuestions?.forEach((examQuestion) => {
      const existingQuestion = findById(
        exam.questions,
        String(examQuestion.id)
      );

      if (!existingQuestion) {
        addQuestion({
          variables: {
            input: {
              examId: examId,
              questionId: examQuestion.id,
            },
          },
        });
      }
    });
    setOpenSaveModal(false);
    deletedQuestions.length = 0;
    setTimeout(() => {
      navigate('/exambank');
    }, 1000);
  };

  const handleConfirmUnsave = () => {
    setOpenUnsaveModal(false);
    deletedQuestions.length = 0;
    addedQuestions.length = 0;
    setTimeout(() => {
      navigate('/exambank');
    }, 1000);
  };

  useEffect(() => {
    setAllQuestions(questions);
  }, [questions]);

  useEffect(() => {
    setExamQuestions(exam.questions);
  }, [exam]);

  return (
    <EditExamContainer>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            marginY={1}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                color="secondary.main"
                variant="h5"
                fontWeight="fontWeightBold"
              >
                {exam.title}
              </Typography>
              <Tag
                label={formatProgramText(exam.program)}
                mRight={0}
                mLeft={0}
              />
              <Tag label={exam.module} mRight={0} mLeft={0} />
              <Tag
                label={exam.year.toString() + exam.quarter}
                mRight={0}
                mLeft={0}
              />
            </Stack>
            <EditExamModal exam={exam} />
          </Stack>
          <ExamDetails
            numOfItems={exam.questions.length}
            typeOfExam={exam.examType}
            startDate={exam.startDate as number}
            endDate={exam.endDate as number}
            duration={exam.duration}
            titleGridSize={4}
            detailGridSize={8}
          />
          <ExamDescription content={exam.description} />
          <Stack direction="column" spacing={2}>
            {examQuestionsLength > 0 ? (
              <Stack direction="column" spacing={2}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'end',
                  }}
                >
                  <FormControlLabel
                    label={
                      <Typography
                        sx={{
                          color: 'rgba(0, 0, 0, 0.8)',
                          fontSize: '14px',
                        }}
                      >
                        Show Question Details
                      </Typography>
                    }
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        sx={{
                          '&.Mui-checked': {
                            color: '#1976D2',
                          },
                        }}
                      />
                    }
                  />
                </div>
                {examQuestions?.map((row) => (
                  <QuestionDisplayContainer
                    key={String(row.id)}
                    questionId={String(row.id)}
                    authorId={String(row.createdById)}
                    questionType={String(row.__typename)}
                    showDetails={checked}
                    handleDelete={() => handleDelete(row)}
                  />
                ))}
              </Stack>
            ) : (
              <StyledEmptyDataMessage>
                <Stack direction="column" sx={{ marginTop: '100px' }}>
                  <Typography
                    style={{ color: '#BEBEBE', fontWeight: 600 }}
                    variant="h6"
                  >
                    There are no questions here!
                  </Typography>
                  <Typography style={{ color: '#BEBEBE' }} variant="subtitle1">
                    Start adding your questions
                  </Typography>
                  <Box height={20} />
                  <div>
                    <Button
                      data-testid="add-exam-button"
                      variant="contained"
                      disableElevation
                      disableRipple
                      onClick={handleOpen}
                      startIcon={<SaveAltIcon />}
                      sx={{
                        '&.MuiButtonBase-root': {
                          bgcolor: '#374EA2',
                          width: '65%',
                        },
                        '&.MuiButtonBase-root:hover': {
                          bgcolor: 'transparent',
                          border: '1px #374EA2 solid ',
                          color: '#374EA2',
                        },
                        borderRadius: 5,
                        textTransform: 'none',
                        fontSize: '15px',
                        boxShadow: 0,
                      }}
                    >
                      Import questions
                    </Button>
                  </div>
                  <Dialog
                    data-testid="exam-link-modal"
                    open={open}
                    onClose={handleClose}
                    fullScreen={!matches}
                    fullWidth
                  >
                    <CustomDialogTitle
                      text="Import Questions"
                      handleClose={handleClose}
                    />
                    <Box height={2} />
                    <DialogContent>
                      <StyledDialogContent>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={selectedValue}
                            onChange={handleSelectChange}
                          >
                            <FormControlLabel
                              value="all"
                              control={<Radio />}
                              label="All questions"
                            />
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="flex-start"
                              spacing={2}
                            >
                              <FormControlLabel
                                value="max"
                                control={<Radio />}
                                label="Max questions"
                              />
                              {selectedValue === 'max' && (
                                <Stack
                                  direction="row"
                                  spacing={1}
                                  paddingLeft={2.5}
                                >
                                  <TextField
                                    required
                                    data-testid="max-questions-count"
                                    type="number"
                                    sx={{
                                      width: 90,
                                    }}
                                    value={maxQuestionsCount || ''}
                                    onChange={handleMaxQuestionsChange}
                                    InputProps={{
                                      inputProps: {
                                        min: 0,
                                        max: questionsLength,
                                      },
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    out of {questionsLength}
                                  </Typography>
                                </Stack>
                              )}
                            </Stack>
                          </RadioGroup>
                        </FormControl>
                      </StyledDialogContent>
                      <StyledDialogButtonBox>
                        <Button
                          onClick={handleClose}
                          sx={{
                            marginRight: '15px',
                            textTransform: 'none',
                            boxShadow: 0,
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          disableElevation
                          variant="contained"
                          onClick={handleProceed}
                          autoFocus
                          sx={{
                            color: '#FFFFFF',
                            textTransform: 'none',
                            boxShadow: 0,
                          }}
                        >
                          Proceed
                        </Button>
                      </StyledDialogButtonBox>
                    </DialogContent>
                  </Dialog>
                </Stack>
              </StyledEmptyDataMessage>
            )}
            <Box height={30} />
          </Stack>
          {examQuestionsLength > 0 && (
            <Stack
              direction="row"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Button
                data-testid="action-button"
                variant="outlined"
                disableElevation
                onClick={() => setOpenUnsaveModal(true)}
                sx={{ textTransform: 'none', boxShadow: 0 }}
              >
                Cancel
              </Button>
              <CustomDialog
                isOpen={openUnsaveModal}
                handleClose={() => setOpenUnsaveModal(false)}
                handleConfirm={handleConfirmUnsave}
                title={message.unsaveExam.title}
                content={message.unsaveExam.content}
              />
              <Button
                data-testid="action-button"
                variant="contained"
                disableElevation
                onClick={() => setOpenSaveModal(true)}
                sx={{ textTransform: 'none', boxShadow: 0 }}
              >
                Save
              </Button>
              <CustomDialog
                isOpen={openSaveModal}
                handleClose={() => setOpenSaveModal(false)}
                handleConfirm={handleConfirmSave}
                title={message.saveExam.title}
                content={message.saveExam.content}
              />
            </Stack>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Paper sx={{ width: '100%', boxShadow: 'none' }}>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Questions</StyledTableCell>
                  </TableRow>
                </TableHead>
                {questionsLength > 0 ? (
                  <TableBody>
                    {allQuestions?.map((row, index) => (
                      <TableRow key={row.id}>
                        <StyledTableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <IconButton
                              data-testid={`add-question-${index + 1}`}
                              aria-label="add"
                              onClick={() => handleAddQuestion(row)}
                            >
                              <Add sx={fontStyle} />
                            </IconButton>
                            <Typography
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                fontSize: '14px',
                              }}
                            >
                              {row.question}
                            </Typography>
                          </Stack>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <EmptyDataComponent message="No questions have been added under this program yet" />
                )}
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </EditExamContainer>
  );
};

export default EditExamPage;
