import * as Types from '../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SubscribeToExamMutationVariables = Types.Exact<{
  examId: Types.Scalars['String'];
}>;


export type SubscribeToExamMutation = { __typename?: 'Mutation', subscribe?: { __typename?: 'SubscriptionPayload', examId?: string | null } | null };


export const SubscribeToExamDocument = gql`
    mutation SubscribeToExam($examId: String!) {
  subscribe(examId: $examId) {
    examId
  }
}
    `;
export type SubscribeToExamMutationFn = Apollo.MutationFunction<SubscribeToExamMutation, SubscribeToExamMutationVariables>;

/**
 * __useSubscribeToExamMutation__
 *
 * To run a mutation, you first call `useSubscribeToExamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToExamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeToExamMutation, { data, loading, error }] = useSubscribeToExamMutation({
 *   variables: {
 *      examId: // value for 'examId'
 *   },
 * });
 */
export function useSubscribeToExamMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeToExamMutation, SubscribeToExamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribeToExamMutation, SubscribeToExamMutationVariables>(SubscribeToExamDocument, options);
      }
export type SubscribeToExamMutationHookResult = ReturnType<typeof useSubscribeToExamMutation>;
export type SubscribeToExamMutationResult = Apollo.MutationResult<SubscribeToExamMutation>;
export type SubscribeToExamMutationOptions = Apollo.BaseMutationOptions<SubscribeToExamMutation, SubscribeToExamMutationVariables>;