import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchQuestionsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  keywords?: Types.InputMaybe<Types.Scalars['String']>;
  filters?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SearchQuestionsQuery = { __typename?: 'Query', searchQuestions?: { __typename?: 'QuestionConnection', nodes?: Array<{ __typename?: 'MultipleChoiceQuestion', id?: string | null, question?: string | null, program?: Types.Program | null, module?: string | null, updatedAt?: any | null, createdById?: string | null } | { __typename?: 'MultipleSelectionQuestion', id?: string | null, question?: string | null, program?: Types.Program | null, module?: string | null, updatedAt?: any | null, createdById?: string | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasPreviousPage?: boolean | null, hasNextPage?: boolean | null, endCursor?: string | null, startCursor?: string | null } } | null };


export const SearchQuestionsDocument = gql`
    query SearchQuestions($first: Int, $after: String, $last: Int, $before: String, $keywords: String, $filters: String) {
  searchQuestions(
    first: $first
    after: $after
    last: $last
    before: $before
    keywords: $keywords
    filters: $filters
  ) {
    nodes {
      id
      question
      program
      module
      updatedAt
      createdById
    }
    pageInfo {
      hasPreviousPage
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `;

/**
 * __useSearchQuestionsQuery__
 *
 * To run a query within a React component, call `useSearchQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuestionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      keywords: // value for 'keywords'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useSearchQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<SearchQuestionsQuery, SearchQuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchQuestionsQuery, SearchQuestionsQueryVariables>(SearchQuestionsDocument, options);
      }
export function useSearchQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchQuestionsQuery, SearchQuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchQuestionsQuery, SearchQuestionsQueryVariables>(SearchQuestionsDocument, options);
        }
export type SearchQuestionsQueryHookResult = ReturnType<typeof useSearchQuestionsQuery>;
export type SearchQuestionsLazyQueryHookResult = ReturnType<typeof useSearchQuestionsLazyQuery>;
export type SearchQuestionsQueryResult = Apollo.QueryResult<SearchQuestionsQuery, SearchQuestionsQueryVariables>;