import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMultipleChoiceQuestionQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetMultipleChoiceQuestionQuery = { __typename?: 'Query', multipleChoiceQuestion?: { __typename?: 'MultipleChoiceQuestion', id?: string | null, program?: Types.Program | null, module?: string | null, question?: string | null, createdAt?: any | null, updatedAt?: any | null, options?: Array<{ __typename?: 'Option', id?: string | null, description?: string | null, isCorrect?: boolean | null } | null> | null } | null };


export const GetMultipleChoiceQuestionDocument = gql`
    query GetMultipleChoiceQuestion($id: String!) {
  multipleChoiceQuestion(id: $id) {
    id
    program
    module
    question
    createdAt
    updatedAt
    options {
      id
      description
      isCorrect
    }
  }
}
    `;

/**
 * __useGetMultipleChoiceQuestionQuery__
 *
 * To run a query within a React component, call `useGetMultipleChoiceQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMultipleChoiceQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMultipleChoiceQuestionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMultipleChoiceQuestionQuery(baseOptions: Apollo.QueryHookOptions<GetMultipleChoiceQuestionQuery, GetMultipleChoiceQuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMultipleChoiceQuestionQuery, GetMultipleChoiceQuestionQueryVariables>(GetMultipleChoiceQuestionDocument, options);
      }
export function useGetMultipleChoiceQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMultipleChoiceQuestionQuery, GetMultipleChoiceQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMultipleChoiceQuestionQuery, GetMultipleChoiceQuestionQueryVariables>(GetMultipleChoiceQuestionDocument, options);
        }
export type GetMultipleChoiceQuestionQueryHookResult = ReturnType<typeof useGetMultipleChoiceQuestionQuery>;
export type GetMultipleChoiceQuestionLazyQueryHookResult = ReturnType<typeof useGetMultipleChoiceQuestionLazyQuery>;
export type GetMultipleChoiceQuestionQueryResult = Apollo.QueryResult<GetMultipleChoiceQuestionQuery, GetMultipleChoiceQuestionQueryVariables>;