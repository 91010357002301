import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  useMediaQuery,
} from '@mui/material';
import ModalClose from '../modal/admin/common/ModalClose';
import { CustomDialogProps } from './customDialogProps';
import { StyledDialogContent } from '../../styles/admin/QuestionsPageStyles';
import { StyledDialogButtonBox } from '../../styles/styledComponents/CustomDialogStyles';

function CustomDialog({
  isOpen,
  handleClose,
  handleConfirm,
  title,
  content,
}: CustomDialogProps) {
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <>
      <Dialog open={isOpen}>
        <ModalClose handleClose={handleClose} />
        <DialogContent>
          <StyledDialogContent>
            <Box>
              <Typography sx={{ fontSize: matches ? 18 : 14, fontWeight: 600 }}>
                {title}
              </Typography>
            </Box>
            <Box sx={{ paddingTop: '10px' }}>
              <Typography
                sx={{ fontSize: matches ? 14 : 12, color: '#616161' }}
              >
                {content}
              </Typography>
            </Box>
          </StyledDialogContent>
          <StyledDialogButtonBox>
            <Button
              onClick={handleClose}
              sx={{
                marginRight: '15px',
                textTransform: 'none',
                boxShadow: 0,
              }}
            >
              Cancel
            </Button>
            <Button
              disableElevation
              variant="contained"
              onClick={handleConfirm}
              data-testid="confirm-button"
              autoFocus
              sx={{
                color: '#FFFFFF',
                textTransform: 'none',
                boxShadow: 0,
              }}
            >
              Confirm
            </Button>
          </StyledDialogButtonBox>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CustomDialog;
