import {
  styled,
  Typography,
  TableCell,
  tableCellClasses,
  Tab,
  Tabs,
} from '@mui/material';

export const DashboardContainer = styled('div')(() => ({
  margin: 30,
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    fontWeight: 'bold',
    fontSize: 18,
    paddingBottom: 8,
    paddingTop: 8,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.common.white,
    paddingBottom: 4,
    paddingTop: 4,
    pointerEvents: 'fill',
    '&:hover': {
      backgroundColor: 'rgb(33,30,71,0.15)',
    },
  },
}));

export const RowContainer = styled('div')(() => ({
  justifyContent: 'space-between',
  display: 'flex',
  alignItems: 'center',
}));

export const ExamContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: 'auto',
  height: '41px',
  display: 'flex',
  alignItems: 'flex-end',
  '& .MuiTab-root.Mui-selected': {
    color: theme.palette.secondary.main,
    borderRadius: '4px 4px 0px 0px',
    background: '#FFFFFF',
    border: '1px solid #FFFFFF',
    height: '36px',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  '.MuiTabs-flexContainer': {
    alignItems: 'flex-end',
  },
}));

export const StyledTab = styled(Tab)(() => ({
  minHeight: 'auto',
  height: '30px',
  color: '#FFFFFF',
  fontSize: '14px',
  fontWeight: 400,
  textTransform: 'none',
  border: '1px solid #FFFFFF',
  borderBottom: 0,
  borderRadius: '4px 4px 0px 0px',
  marginRight: '4px',
}));
