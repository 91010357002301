import React from 'react';
import { Button, Box } from '@mui/material';
import { HeaderButtonProps } from './headerButtonProps';

function HeaderButton(props: HeaderButtonProps) {
  const { label, handleClick, testId, isMainMenu, redirectLink } = props;
  return (
    <Box
      sx={{
        display: { xs: isMainMenu ? 'flex' : 'none', md: 'flex' },
        justifyContent: 'center',
      }}
    >
      <Button
        variant={isMainMenu ? 'text' : 'contained'}
        disableElevation
        sx={{
          color: isMainMenu ? '' : '#FFFFFF',
          textTransform: 'none',
          fontSize: isMainMenu ? '16px' : '',
          boxShadow: 0,
          '&:hover': isMainMenu
            ? {
                backgroundColor: 'transparent',
                color: '#539536',
              }
            : {},
        }}
        href={redirectLink}
        onClick={handleClick}
        data-testid={testId}
      >
        {label}
      </Button>
    </Box>
  );
}

export default HeaderButton;
