import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllStudentExamsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type AllStudentExamsQuery = { __typename?: 'Query', studentExamsConnection?: { __typename?: 'StudentExamsConnection', nodes?: Array<{ __typename?: 'StudentExam', id?: string | null, score?: number | null, submittedAt?: any | null, passed?: boolean | null, examDetails?: { __typename?: 'ExamDetails', description?: string | null, title?: string | null, module?: string | null, maxQuestionCount?: number | null, endDate?: any | null, passingRate?: number | null } | null } | null> | null, pageInfo: { __typename?: 'PageInfo', hasNextPage?: boolean | null, hasPreviousPage?: boolean | null, startCursor?: string | null, endCursor?: string | null } } | null };


export const AllStudentExamsDocument = gql`
    query AllStudentExams($first: Int, $last: Int, $after: String, $before: String) {
  studentExamsConnection(
    first: $first
    last: $last
    after: $after
    before: $before
  ) {
    nodes {
      id
      score
      submittedAt
      passed
      examDetails {
        description
        title
        module
        maxQuestionCount
        endDate
        passingRate
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useAllStudentExamsQuery__
 *
 * To run a query within a React component, call `useAllStudentExamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllStudentExamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllStudentExamsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useAllStudentExamsQuery(baseOptions?: Apollo.QueryHookOptions<AllStudentExamsQuery, AllStudentExamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllStudentExamsQuery, AllStudentExamsQueryVariables>(AllStudentExamsDocument, options);
      }
export function useAllStudentExamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllStudentExamsQuery, AllStudentExamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllStudentExamsQuery, AllStudentExamsQueryVariables>(AllStudentExamsDocument, options);
        }
export type AllStudentExamsQueryHookResult = ReturnType<typeof useAllStudentExamsQuery>;
export type AllStudentExamsLazyQueryHookResult = ReturnType<typeof useAllStudentExamsLazyQuery>;
export type AllStudentExamsQueryResult = Apollo.QueryResult<AllStudentExamsQuery, AllStudentExamsQueryVariables>;