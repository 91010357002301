import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Box, Button, Container } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { PropState, ViewExamProps } from './viewExamProps';
import { ExamQuestionInterface } from './examQuestionInterface';
import ExamHeader from '../../../components/admin/viewExam/ExamHeader';
import ExamDescription from '../../../components/admin/viewExam/ExamDescription';
import ExamDetails from '../../../components/admin/viewExam/ExamDetails';
import QuestionComponent from '../../../components/admin/viewExam/QuestionComponent/QuestionComponent';
import { ExamReturnButton } from '../../../styles/admin/ViewExamStyles';
import DELETE_EXAM_MUTATION from '../../../graphql/mutations/admin/exam/deleteExam';
import {
  DeleteExamMutation,
  DeleteExamMutationVariables,
} from '../../../graphql/mutations/admin/exam/deleteExam.generated';
import LoadingPage from '../../../components/states/LoadingPage';

function ViewExamPage({
  id,
  title,
  program,
  module,
  year,
  quarter,
  description,
  examDetails,
  questions,
  published,
}: ViewExamProps) {
  const {
    typeOfExam,
    numOfItems,
    startDate,
    endDate,
    duration,
    titleGridSize,
    detailGridSize,
  } = examDetails;

  const navigate = useNavigate();
  const location = useLocation();
  const { prevPath }: PropState = location.state as PropState;

  const [deleteExam] = useMutation<
    DeleteExamMutation,
    DeleteExamMutationVariables
  >(DELETE_EXAM_MUTATION, {
    update(cache) {
      const deletedId = cache.identify({
        id,
        __typename: 'Exam',
      });
      cache.evict({
        id: deletedId,
      });
      cache.gc();
    },
  });

  const [loading, setLoading] = useState(false);

  const handleDeleteExam = () => {
    setLoading(true);
    setTimeout(() => {
      deleteExam({
        variables: {
          input: {
            id: id,
          },
        },
      });
    }, 1000);
  };

  if (loading) {
    setTimeout(() => {
      navigate(prevPath);
    }, 1000);
    return <LoadingPage />;
  }

  return (
    <Box component="main">
      <ExamReturnButton>
        <Button
          color="secondary"
          startIcon={<ArrowBackIosNewIcon />}
          sx={{ textTransform: 'none' }}
          onClick={() => navigate(-1)}
        >
          Return
        </Button>
      </ExamReturnButton>
      <Container maxWidth="md" sx={{ mt: 1, paddingBottom: '40px' }}>
        <ExamHeader
          questionsLength={numOfItems}
          examId={id}
          examType={typeOfExam}
          title={title}
          program={program}
          module={module}
          quarter={quarter}
          year={year}
          isPublished={published}
          duration={duration as number}
          startDate={startDate as number}
          endDate={endDate as number}
          handleEdit={() => navigate(`/exam/edit/${id}`)}
          handleDelete={handleDeleteExam}
        />
        <ExamDetails
          numOfItems={numOfItems}
          typeOfExam={typeOfExam}
          startDate={startDate}
          endDate={endDate}
          duration={duration}
          titleGridSize={titleGridSize}
          detailGridSize={detailGridSize}
        />
        <ExamDescription content={description} />
        {questions?.map((question: ExamQuestionInterface) => {
          return (
            <QuestionComponent
              key={question.id}
              type={question.__typename}
              id={question.id}
            />
          );
        })}
      </Container>
    </Box>
  );
}

export default ViewExamPage;
