import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GenerateExamLinkMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type GenerateExamLinkMutation = { __typename?: 'Mutation', generateExamLink?: string | null };


export const GenerateExamLinkDocument = gql`
    mutation GenerateExamLink {
  generateExamLink
}
    `;
export type GenerateExamLinkMutationFn = Apollo.MutationFunction<GenerateExamLinkMutation, GenerateExamLinkMutationVariables>;

/**
 * __useGenerateExamLinkMutation__
 *
 * To run a mutation, you first call `useGenerateExamLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateExamLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateExamLinkMutation, { data, loading, error }] = useGenerateExamLinkMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateExamLinkMutation(baseOptions?: Apollo.MutationHookOptions<GenerateExamLinkMutation, GenerateExamLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateExamLinkMutation, GenerateExamLinkMutationVariables>(GenerateExamLinkDocument, options);
      }
export type GenerateExamLinkMutationHookResult = ReturnType<typeof useGenerateExamLinkMutation>;
export type GenerateExamLinkMutationResult = Apollo.MutationResult<GenerateExamLinkMutation>;
export type GenerateExamLinkMutationOptions = Apollo.BaseMutationOptions<GenerateExamLinkMutation, GenerateExamLinkMutationVariables>;