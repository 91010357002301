import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { StyledDialogContent } from '../../../styles/admin/QuestionsPageStyles';
import CloseIcon from '@mui/icons-material/Close';

import { StyledDialogButtonBox } from '../../../styles/styledComponents/CustomDialogStyles';
import { LoadingButton } from '@mui/lab';
import { useDeleteUserMutation } from '../../../graphql/mutations/admin/user/deleteUser.generated';

export interface CandidateDeleteDialogProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  candidateEmail: string;
  userId: string;
}

function CandidateDeleteDialog({
  isOpen,
  setOpen,
  candidateEmail,
  userId,
}: CandidateDeleteDialogProps) {
  const matches = useMediaQuery('(min-width:600px)');
  const [email, setEmail] = useState('');
  const [deleteCandidate, { loading }] = useDeleteUserMutation();

  const handleConfirmDelete = async () => {
    await deleteCandidate({
      variables: {
        id: userId,
      },
      onCompleted: () => {
        setOpen(false);
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
    setEmail('');
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogContent>
          <StyledDialogContent>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ fontSize: matches ? 18 : 14, fontWeight: 600 }}>
                Are you sure you want to delete this Candidate?
              </Typography>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ paddingTop: '10px' }}>
              <Typography
                sx={{ fontSize: matches ? 14 : 12, color: '#616161' }}
              >
                This will permanently delete the candidate account and any data
                related to it. This action is irreversible.
              </Typography>
            </Box>
            <Box sx={{ marginTop: '12px' }}>
              <Typography
                sx={{
                  fontSize: matches ? 14 : 12,
                  fontWeight: 'bold',
                }}
              >
                Type the candidate email to confirm delete.
              </Typography>
              <TextField
                variant="outlined"
                type="email"
                placeholder="email"
                helperText={candidateEmail}
                onChange={handleOptionChange}
                value={email}
                sx={{ width: 400, marginY: '4px' }}
                size="small"
              />
            </Box>
          </StyledDialogContent>
          <StyledDialogButtonBox>
            <Button
              onClick={handleClose}
              sx={{
                marginRight: '15px',
                textTransform: 'none',
                boxShadow: 0,
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              disableElevation
              loading={loading}
              variant="contained"
              onClick={() => {
                handleConfirmDelete();
              }}
              data-testid="confirm-button"
              autoFocus
              sx={{
                color: '#FFFFFF',
                textTransform: 'none',
                boxShadow: 0,
              }}
              disabled={!(email === candidateEmail)}
            >
              Confirm
            </LoadingButton>
          </StyledDialogButtonBox>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CandidateDeleteDialog;
