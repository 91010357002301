import * as Types from '../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnsubscribeFromExamMutationVariables = Types.Exact<{
  examId: Types.Scalars['String'];
}>;


export type UnsubscribeFromExamMutation = { __typename?: 'Mutation', unsubscribe?: { __typename?: 'SubscriptionPayload', examId?: string | null } | null };


export const UnsubscribeFromExamDocument = gql`
    mutation UnsubscribeFromExam($examId: String!) {
  unsubscribe(examId: $examId) {
    examId
  }
}
    `;
export type UnsubscribeFromExamMutationFn = Apollo.MutationFunction<UnsubscribeFromExamMutation, UnsubscribeFromExamMutationVariables>;

/**
 * __useUnsubscribeFromExamMutation__
 *
 * To run a mutation, you first call `useUnsubscribeFromExamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeFromExamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeFromExamMutation, { data, loading, error }] = useUnsubscribeFromExamMutation({
 *   variables: {
 *      examId: // value for 'examId'
 *   },
 * });
 */
export function useUnsubscribeFromExamMutation(baseOptions?: Apollo.MutationHookOptions<UnsubscribeFromExamMutation, UnsubscribeFromExamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnsubscribeFromExamMutation, UnsubscribeFromExamMutationVariables>(UnsubscribeFromExamDocument, options);
      }
export type UnsubscribeFromExamMutationHookResult = ReturnType<typeof useUnsubscribeFromExamMutation>;
export type UnsubscribeFromExamMutationResult = Apollo.MutationResult<UnsubscribeFromExamMutation>;
export type UnsubscribeFromExamMutationOptions = Apollo.BaseMutationOptions<UnsubscribeFromExamMutation, UnsubscribeFromExamMutationVariables>;