/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState, useEffect } from 'react';
import { useAllStudentExamsQuery } from '../../../graphql/queries/student/exam/getPaginatedExams.generated';
import TableLoadingState from '../../states/componentStates/TableLoadingState';
import ErrorPage from '../../states/ErrorPage';
import { StudentExamsListInterface } from './studentExamsInterface';
import StudentExamsListTable from './StudentExamListTable';

const StudentExamsList = () => {
  const { data, loading, error, fetchMore } = useAllStudentExamsQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      first: 10,
      last: null,
      after: null,
      before: null,
    },
    pollInterval: 2000,
  });
  const [page, setPage] = useState(1);

  const endCursor = data?.studentExamsConnection?.pageInfo.endCursor;
  const startCursor = data?.studentExamsConnection?.pageInfo.startCursor;
  const hasPrevPage = data?.studentExamsConnection?.pageInfo.hasPreviousPage;
  const hasNextPage = data?.studentExamsConnection?.pageInfo.hasNextPage;

  const moreResults = () => {
    fetchMore({
      variables: {
        first: 10,
        last: null,
        after: endCursor,
        before: null,
      },
    });
  };

  const prevResults = () => {
    fetchMore({
      variables: {
        first: null,
        last: 10,
        after: null,
        before: startCursor,
      },
    });
  };

  const [disableButton, setDisableButton] = useState<boolean>(!hasNextPage);

  useEffect(() => {
    if (hasNextPage) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [hasNextPage]);

  const handleClickNext = () => {
    if (hasNextPage) {
      moreResults();
    }
    setPage(page + 1);
  };

  const handleClickPrev = () => {
    if (hasPrevPage) {
      prevResults();
      setPage(page - 1);
      setDisableButton(false);
    }
  };

  useEffect(() => {
    if (!hasPrevPage && page !== 1) {
      setPage(1);
    }
  }, [data]);

  if (loading) return <TableLoadingState />;
  if (error) return <ErrorPage errorMessage={error.message} />;

  const exams: StudentExamsListInterface[] = data?.studentExamsConnection
    ?.nodes as unknown as StudentExamsListInterface[];

  return (
    <StudentExamsListTable
      exams={exams}
      examTableTitle="Exams History"
      pageNum={page}
      onNext={handleClickNext}
      onPrev={handleClickPrev}
      nextDisabled={disableButton}
    />
  );
};

export default StudentExamsList;
