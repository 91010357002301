import { gql } from '@apollo/client';

const OPEN_EXAM_MUTATION = gql`
  mutation OpenStudentExam($input: OpenStudentExamInput!) {
    openStudentExam(input: $input) {
      id
      openedAt
      examDetails {
        id
      }
      studentAnswers
      examQuestions
      student {
        id
      }
    }
  }
`;

export default OPEN_EXAM_MUTATION;
