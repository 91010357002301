import { gql } from '@apollo/client';

const CREATE_MS_QUESTION_MUTATION = gql`
  mutation CreateMultipleSelectionQuestion(
    $input: CreateMultipleSelectionQuestionInput!
  ) {
    createMultipleSelectionQuestion(input: $input) {
      program
      module
      question
      choices {
        id
        description
        isCorrect
      }
    }
  }
`;

export default CREATE_MS_QUESTION_MUTATION;
