import React from 'react';
import {
  Box,
  CircularProgress,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { StyledEmptyDataMessage } from '../../../styles/admin/QuestionsPageStyles';

function SearchLoadingComponent() {
  return (
    <>
      <TableBody>
        <TableRow>
          <TableCell>
            <StyledEmptyDataMessage>
              <Stack direction="column" spacing={1}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress />
                </Box>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: '#BEBEBE',
                  }}
                >
                  please wait
                </Typography>
              </Stack>
            </StyledEmptyDataMessage>
          </TableCell>
        </TableRow>
      </TableBody>
    </>
  );
}

export default SearchLoadingComponent;
