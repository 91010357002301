import React from 'react';
import {
  Typography,
  TextField,
  MenuItem,
  Grid,
  useMediaQuery,
} from '@mui/material';
import { EditProgramProps } from '../editQuestionProps';

function EditProgram({ program, programs, onChange }: EditProgramProps) {
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <>
      <Grid container>
        <Grid item xs={12} container sx={{ paddingBottom: '10px' }}>
          <Typography
            sx={{ mt: 2, fontSize: matches ? 16 : 14, fontWeight: 600 }}
          >
            Program
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            select
            inputProps={{ 'data-testid': 'program-textfield' }}
            value={program}
            onChange={onChange}
            defaultValue={program}
            sx={{ width: 400 }}
          >
            {programs.map((option) => (
              <MenuItem key={option.id} value={option.value}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </>
  );
}

export default EditProgram;
