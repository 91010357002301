import { styled, Typography, Alert } from '@mui/material';

export const ExamsPageContainer = styled('div')(() => ({
  margin: '30px 40px 30px 40px',
}));

export const ExamContentsBox = styled('div')(() => ({
  marginTop: 20,
  backgroundColor: '#f5f5f5',
  borderRadius: 5,
  height: '200px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const ExamProgressContainer = styled('div')(() => ({
  height: '100%',
  backgroundColor: '#f5f5f5',
  borderRadius: 5,
  padding: 20,
  position: 'relative',
}));

export const ExamSubmitButtonDiv = styled('div')(() => ({
  paddingTop: '35px',
  textAlign: 'end',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
}));

export const InvalidExamLinkContainer = styled('div')(() => ({
  height: '100vh',
  display: 'flex',
  alignItems: 'start',
  marginTop: '60px',
  justifyContent: 'center',
}));

export const InvalidExamLinkBox = styled('div')(() => ({
  backgroundColor: 'rgba(216, 50, 50, 0.05)',
  borderRadius: 5,
  border: '1px solid #D83232',
  height: '120px',
  width: '320px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
