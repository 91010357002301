import { gql } from '@apollo/client';

export const UPDATE_MC_OPTION_MUTATION = gql`
  mutation UpdateMultipleChoiceOption($input: UpdateOptionInput!) {
    updateMultipleChoiceOption(input: $input) {
      id
      description
      isCorrect
    }
  }
`;
