/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Typography, Stack, Divider, Box } from '@mui/material';
import ExamDescription from '../../../../components/admin/viewExam/ExamDescription';
import ExamDetails from '../../../../components/admin/viewExam/ExamDetails';
import formatText from '../../../../utils/textFormatter';
import {
  ExamScoreContentsBox,
  ExamScorePageContainer,
} from '../../../../styles/student/StudentExamScorePageStyles';
import Tag from '../../../../components/common/ChipTags';
import { ExamDetailsCustomState } from './submissionDetails';
import SubmissionDetailsComponent from './components/SubmissionDetailsComponent';
import ScorePercentageChart from './components/ScorePercentageChart';
import calculateScorePercentage from '../../../../utils/percentageCalculator';
import { ExamType } from '../../../../types.generated';
import CustomAlert from '../../../../components/alert/CustomAlert';
import { useSendEmailCertificateMutation } from '../../../../graphql/mutations/student/certificate/sendEmailCertificate.generated';

const ExamScorePage = () => {
  const location = useLocation();
  const state = location.state as ExamDetailsCustomState;
  const [data, setData] = useState<ExamDetailsCustomState>(state);

  const [openAlert, setOpenAlert] = useState(false);
  const [endAngle, setEndAngle] = useState(0);

  const [sendEmailCertificate] = useSendEmailCertificateMutation();

  const {
    examDetails,
    score,
    examEndTimestamp,
    examStartTimestamp,
    instanceId,
    passed,
  } = data;

  const title = examDetails.title as string;
  const description = examDetails.description as string;
  const program = examDetails.program as string;
  const module = examDetails.module as string;
  const year = examDetails.year as number;
  const quarter = examDetails.quarter as string;
  const startDate = Number(examDetails.startDate);
  const endDate = Number(examDetails.endDate);
  const duration = examDetails.duration as number;
  const examType = examDetails.examType as ExamType;
  const totalItems = examDetails.maxQuestionCount as number;
  const scorePercentage = calculateScorePercentage(score, totalItems);

  useEffect(() => {
    setTimeout(() => {
      setData(state);
      setEndAngle(360);
    }, 100);

    if (passed) {
      sendEmailCertificate({
        variables: {
          input: {
            certificateLink: `${window.location.origin}/certificate/view/${instanceId}`,
          },
        },
        onCompleted: (res) => {
          if (res.sendEmailCertificate?.successful) {
            setOpenAlert(true);
          }
        },
      });
    }
  }, []);

  return (
    <ExamScorePageContainer>
      <CustomAlert
        message="Certificate link has been sent to your email"
        open={openAlert}
        durationInMs={5000}
        handleClose={() => setOpenAlert(false)}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={9}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
            marginY={1}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                color="secondary.main"
                variant="h5"
                fontWeight="fontWeightBold"
              >
                {title}
              </Typography>

              <Tag label={formatText(program)} mRight={0} mLeft={0} />
              <Tag label={year.toString() + quarter} mRight={0} mLeft={0} />
              <Tag label={module} mRight={0} mLeft={0} />
            </Stack>
          </Stack>
          <ExamDetails
            numOfItems={totalItems}
            typeOfExam={examType}
            startDate={startDate}
            endDate={endDate}
            duration={duration}
            titleGridSize={4}
            detailGridSize={8}
          />
          <ExamDescription content={description} />
          <Box height={10} />
          <Divider sx={{ borderBottomWidth: 2 }} />
          <Box height={10} />
          <Grid container item alignItems={'center'} direction={'column'}>
            <Grid item>
              <Typography variant="h6">Assessment Complete.</Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  fontSize: 15,
                  color: 'rgba(0, 0, 0, 0.7)',
                  textAlign: 'center',
                }}
              >
                Exam results are protected and are not visible to candidates.
                <br />
                If you have any questions, please reach out to your Instructor
                or Program Administrator on Slack.
              </Typography>
            </Grid>
            <Grid item mt={5}>
              <Typography sx={{ fontSize: 20 }}>
                You scored{' '}
                <span
                  style={{
                    fontWeight: 'bold',
                    color: '#374EA2',
                    fontSize: 24,
                  }}
                >
                  {Math.round(score * 100) / 100}{' '}
                </span>
                out of {totalItems}
              </Typography>
            </Grid>
            <Grid item>
              <ScorePercentageChart
                scorePercentage={scorePercentage}
                endAngle={endAngle}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <SubmissionDetailsComponent
            typeOfExam={examType}
            examStartTimestamp={examStartTimestamp}
            examEndTimestamp={examEndTimestamp}
            score={score}
            titleGridSize={4}
            detailGridSize={8}
          />
        </Grid>
      </Grid>
    </ExamScorePageContainer>
  );
};

export default ExamScorePage;
