import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
// import { useGoogleLogin } from 'react-google-login';
// import GoogleButton from 'react-google-button';
import GOOGLE_SIGN_IN_MUTATION from '../../../graphql/mutations/auth/signInGoogle';
import { saveUser } from '../../../routes/userInfo/user';
import { UserProfile } from '../../../types.generated';

const SignInWithGoogle = () => {
  const navigate = useNavigate();

  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
  const [googleSignIn] = useMutation(GOOGLE_SIGN_IN_MUTATION);

  const [loading, setLoading] = useState(false);

  const onSuccess = (response: Record<any, any>) => {
    setLoading(true);
    googleSignIn({
      variables: {
        input: {
          tokenId: String(response.credential),
        },
      },
      onCompleted: (data: { googleSignIn: UserProfile }) => {
        const name = `${data.googleSignIn.firstName as string} ${
          data.googleSignIn.lastName as string
        }`;
        const roles: string[] = data.googleSignIn.roles as string[];
        const redirectLink = roles.includes('ADMIN') ? '/admindashboard' : '/';
        saveUser(roles, name);

        setTimeout(() => navigate(redirectLink), 2000);
      },
    });
  };

  useEffect(() => {
    (window as any).onSuccess = onSuccess;
  }, [])

  // const onFailure = (response: Record<string, string>) => {
  //   throw new Error(response.error);
  // };

  // const { signIn } = useGoogleLogin({
  //   onSuccess,
  //   onFailure,
  //   clientId,
  //   isSignedIn: true,
  //   accessType: 'offline',
  //   cookiePolicy: 'single_host_origin',
  // });

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {loading ? (
        <LoadingButton
          loading
          variant="contained"
          sx={{ width: '450px', height: '50px' }}
        >
          Submit
        </LoadingButton>
      ) : (
        <>
          <div id="g_id_onload"
              data-client_id={clientId}
              data-context="signin"
              data-ux_mode="popup"
              data-callback="onSuccess"
              data-auto_prompt="false">
          </div>

          <div className="g_id_signin"
              data-type="standard"
              data-shape="rectangular"
              data-theme="outline"
              data-text="signin_with"
              data-size="large"
              data-logo_alignment="left">
          </div>
        </>
      )}
    </div>
  );
};

export default SignInWithGoogle;
