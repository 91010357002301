import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { StyledDialogContent } from '../../../styles/admin/QuestionsPageStyles';
import CloseIcon from '@mui/icons-material/Close';
import { StyledDialogButtonBox } from '../../../styles/styledComponents/CustomDialogStyles';
import { LoadingButton } from '@mui/lab';
import { useSendExamLinkMutation } from '../../../graphql/mutations/admin/exam/sendExamLink.generated';
import CustomAlert from '../../alert/CustomAlert';

export interface CandidateSendExamLinkDialogProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  candidateEmail: string;
}

function CandidateSendExamLinkDialog({
  isOpen,
  setOpen,
  candidateEmail,
}: CandidateSendExamLinkDialogProps) {
  const matches = useMediaQuery('(min-width:600px)');
  const [openAlert, setOpenAlert] = useState(false);
  const [sendExamLink, { loading }] = useSendExamLinkMutation();

  const handleConfirmSend = async () => {
    await sendExamLink({
      variables: {
        email: candidateEmail,
      },
      onCompleted: () => {
        setOpenAlert(true);
        setOpen(false);
      },
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CustomAlert
        message="Exam link has been sent"
        open={openAlert}
        handleClose={() => setOpenAlert(false)}
      />
      <Dialog
        open={isOpen}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogContent>
          <StyledDialogContent>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ fontSize: matches ? 18 : 14, fontWeight: 600 }}>
                Send Exam Link
              </Typography>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box sx={{ paddingTop: '10px', marginTop: '10px' }}>
              <Typography
                sx={{ fontSize: matches ? 14 : 12, color: '#616161' }}
              >
                Are you sure you want to send a unique exam link to Candidate
                email?
              </Typography>
            </Box>
          </StyledDialogContent>
          <StyledDialogButtonBox>
            <Button
              onClick={handleClose}
              sx={{
                marginRight: '15px',
                textTransform: 'none',
                boxShadow: 0,
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              disableElevation
              loading={loading}
              variant="contained"
              onClick={() => {
                handleConfirmSend();
              }}
              data-testid="confirm-button"
              autoFocus
              sx={{
                color: '#FFFFFF',
                textTransform: 'none',
                boxShadow: 0,
              }}
            >
              Confirm
            </LoadingButton>
          </StyledDialogButtonBox>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CandidateSendExamLinkDialog;
