import { gql } from '@apollo/client';

const UPDATE_EXAM_MUTATION = gql`
  mutation UpdateExam($input: UpdateExamInput!) {
    updateExam(input: $input) {
      id
      title
      description
      program
      year
      quarter
      startDate
      endDate
      duration
      examType
      passingRate
    }
  }
`;

export default UPDATE_EXAM_MUTATION;
