import {
  Box,
  Grid,
  InputBase,
  styled,
  TableCell,
  tableCellClasses,
} from '@mui/material';

export const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderStyle: 'solid',
  borderRadius: theme.shape.borderRadius,
  borderWidth: '1px',
  borderColor: '#C4C4C4',
  marginLeft: 0,
  marginRight: '5px',
  width: '90%',
  [theme.breakpoints.up('xs')]: {
    // marginLeft: theme.spacing(1),
    width: '90%',
  },
}));

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'primary',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    fontWeight: 'bold',
    fontSize: 18,
    paddingBottom: 8,
    paddingTop: 8,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.common.white,
    paddingBottom: 8,
    paddingTop: 8,
  },
}));

export const DivStyle = styled('div')(({ theme }) => ({
  textAlign: 'start',
  alignItems: 'center',
  paddingBottom: '20px',
  paddingLeft: '25px',
  paddingRight: '25px',
  [theme.breakpoints.up('md')]: {
    textAlign: 'end',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '10px',
  },
}));

export const ModuleProgramDivStyle = styled('div')(({ theme }) => ({
  textAlign: 'start',
  alignItems: 'center',
  paddingBottom: '20px',
  paddingLeft: '25px',
  paddingRight: '25px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '10px',
  },
}));

export const StyledQuestion = styled('div')(() => ({
  alignItems: 'center',
  paddingRight: '25px',
  paddingLeft: '25px',
}));

export const StyledAuthorDiv = styled('div')(() => ({
  alignItems: 'center',
  paddingRight: '25px',
  paddingLeft: '25px',
}));

export const StyledEmptyDataMessage = styled('div')(() => ({
  height: '200px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
}));

export const StyledDiv = styled('div')(({ theme }) => ({
  textAlign: 'start',
  alignItems: 'center',
  paddingLeft: '25px',
  paddingRight: '25px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
  },
}));

export const StyledOptionDiv = styled('div')(() => ({
  textAlign: 'start',
  alignItems: 'center',
  paddingLeft: '15px',
  paddingBottom: '8px',
}));

export const StyledBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    width: '250px',
  },
  [theme.breakpoints.up('sm')]: {
    width: '650px',
  },
  [theme.breakpoints.up('md')]: {
    width: '760px',
  },
}));

export const StyledAddOptionBox = styled(Box)(({ theme }) => ({
  paddingLeft: '35px',
  paddingRight: '25px',
  [theme.breakpoints.up('xs')]: {
    width: '270px',
  },
  [theme.breakpoints.up('sm')]: {
    width: '610px',
  },
  [theme.breakpoints.up('md')]: {
    width: '785px',
  },
}));

export const StyledGridItem = styled(Grid)(({ theme }) => ({
  justifyContent: 'start',
  paddingBottom: '8px',
  paddingRight: '15px',
  paddingLeft: '15px',
  [theme.breakpoints.up('xs')]: {
    paddingLeft: '5px',
    paddingRight: '5px',
  },

  [theme.breakpoints.up('md')]: {
    alignItems: 'start',
  },
  [theme.breakpoints.up('lg')]: {
    alignItems: 'center',
    textAlign: 'start',
  },
}));

export const StyledDialogContent = styled('div')(({ theme }) => ({
  textAlign: 'start',
  alignItems: 'center',
  paddingBottom: '25px',
  paddingLeft: '15px',
  paddingRight: '15px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '10px',
  },
}));

export const DivAddQuestionStyle = styled('div')(({ theme }) => ({
  textAlign: 'start',
  alignItems: 'center',
  paddingBottom: '25px',
  paddingLeft: '25px',
  paddingRight: '60px',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '10px',
  },
}));

export const QuestionLinkDiv = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));
