import React, { ChangeEvent } from 'react';
import { Typography, Stack, TextField } from '@mui/material';

type EditExamTextFieldType = {
  title: string;
  defaultValue: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  testId: string;
};

const EditExamTextField = (props: EditExamTextFieldType) => {
  const { title, defaultValue, onChange, testId } = props;
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
      marginY={1}
    >
      <Typography sx={{ fontSize: 16 }}>{title}</Typography>
      <TextField
        required
        variant="outlined"
        defaultValue={defaultValue}
        sx={{ width: 400 }}
        onChange={onChange}
        data-testid={testId}
      />
    </Stack>
  );
};

export default EditExamTextField;
