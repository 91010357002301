/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState, useEffect } from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  useMediaQuery,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { StyledTableCell } from '../../../styles/admin/QuestionsPageStyles';
import { QuestionTableProps } from './questionBankProps';
import { QuestionInterface } from '../../../pages/admin/questionBank/QuestionInterface';
import { FilterInterface } from '../../filters/filtersInterface';
import { getDate } from '../../../utils/dateConverter';
import EmptySearchResultsComponent from './EmptySearchResultsComponent';
import SearchLoadingComponent from './SearchLoadingComponent';
import MCDataPage from '../../modal/admin/ViewQuestion/MCQuestion/MCDataPage';
import MSDataPage from '../../modal/admin/ViewQuestion/MSQuestion/MSDataPage';
import EmptyDataComponent from '../../states/componentStates/EmptyDataComponent';
import FilterSlider from '../../filters/FilterSlider';
import TablePagination from '../../common/TablePagination';

function QuestionBankTable({
  questions,
  searchValue,
  noMatchFound,
  isSearchLoading,
  pageNum,
  onNext,
  onPrev,
  nextDisabled,
  onFilter,
}: QuestionTableProps) {
  const matches = useMediaQuery('(min-width:600px)');

  const [isFilterOpen, setFilterOpen] = useState(false);
  const [questionFilters, setQuestionFilters] = useState<FilterInterface[]>([]);
  const [allQuestions, setAllQuestions] = useState<QuestionInterface[]>([]);

  useEffect(() => {
    setAllQuestions(questions);
  }, [questions]);

  const QuestionModalDataPage = (props: {
    questionType: 'MultipleChoiceQuestion' | 'MultipleSelectionQuestion';
    questionId: string;
    authorId: string;
  }) => {
    switch (props.questionType) {
      case 'MultipleChoiceQuestion':
        return (
          <MCDataPage questionId={props.questionId} authorId={props.authorId} />
        );
      case 'MultipleSelectionQuestion':
        return (
          <MSDataPage questionId={props.questionId} authorId={props.authorId} />
        );

      default:
        return null;
    }
  };

  const addToFilter = (filter: FilterInterface) => {
    const map = Object.fromEntries(questionFilters.map((o) => [o.type, o]));
    if (filter.value && filter.value !== 'All') map[filter.type] = filter;
    else delete map[filter.type];
    setQuestionFilters(Object.values(map));
  };

  const filterTable = () => {
    onFilter(questionFilters);
  };

  const filterSchema = [
    {
      label: 'Question Type',
      filterFor: 'questionType',
      componentType: 'select',
      options: [
        { label: 'All', value: 'All' },
        { label: 'Multiple Choice', value: 'MULTIPLE_CHOICE_QUESTION' },
        { label: 'Multiple Select', value: 'MULTIPLE_SELECTION_QUESTION' },
      ],
      onChange: (e: string) => addToFilter({ type: 'questionType', value: e }),
    },
    {
      label: 'Program Type',
      filterFor: 'program',
      componentType: 'select',
      options: [
        { label: 'All', value: 'All' },
        { label: 'Blockchain', value: 'BLOCKCHAIN' },
        { label: 'Full Stack', value: 'FULL_STACK' },
        { label: 'Cybersecurity', value: 'CYBERSECURITY' },
        { label: 'Tech Sales', value: 'TECH_SALES' },
      ],
      onChange: (e: string) => addToFilter({ type: 'program', value: e }),
    },
    {
      label: 'Module',
      filterFor: 'module',
      componentType: 'text',
      onChange: (e: string) => addToFilter({ type: 'module', value: e }),
    },
  ];

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '20px',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
          <TableContainer sx={{ height: '70vh' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <span>Questions</span>
                    <FilterAltIcon
                      onClick={() => setFilterOpen(true)}
                      sx={{ cursor: 'pointer' }}
                    />
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              {isSearchLoading && <SearchLoadingComponent />}
              {!isSearchLoading && noMatchFound ? (
                <EmptySearchResultsComponent searchValue={searchValue} />
              ) : (
                <>
                  {!isSearchLoading && allQuestions.length > 0 ? (
                    <TableBody>
                      {allQuestions.map((row: QuestionInterface) => (
                        <TableRow key={row.id}>
                          <StyledTableCell component="th" scope="row">
                            <QuestionModalDataPage
                              questionType={row.__typename}
                              questionId={row.id}
                              authorId={row.createdById}
                            />
                            <Typography sx={{ fontSize: 12, color: '#9F9F9F' }}>
                              updated last {getDate(row.updatedAt)}
                            </Typography>
                          </StyledTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  ) : (
                    !isSearchLoading && (
                      <EmptyDataComponent message="No questions have been added yet" />
                    )
                  )}
                </>
              )}
            </Table>
          </TableContainer>
          {(!isSearchLoading && noMatchFound && searchValue) || searchValue ? (
            <div />
          ) : (
            <TablePagination
              pageNum={pageNum}
              buttonDisabled={nextDisabled}
              handleNext={onNext}
              handlePrev={onPrev}
            />
          )}
        </Paper>
        <FilterSlider
          isOpen={isFilterOpen}
          handleClose={() => setFilterOpen(false)}
          filterSchema={filterSchema}
          onSubmit={filterTable}
        />
      </div>
    </>
  );
}

export default QuestionBankTable;
