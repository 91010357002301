import React, { useState, useEffect } from 'react';
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Link,
  Stack,
  Chip,
  IconButton,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  RowContainer,
  ExamContainer,
  StyledTableCell,
  ExamDetailsText,
} from '../../../styles/admin/ExamsListStyles';
import { StudentExamsListRowInterface } from './studentExamsInterface';
import calculateScorePercentage from '../../../utils/percentageCalculator';
import { VictoryLabel, VictoryPie } from 'victory';
import Tag from '../../common/ChipTags';

const examDetailsStyles = { fontSize: '14px', py: 2, pl: 5 };

const StudentExamsListRow = (props: StudentExamsListRowInterface) => {
  const { exam } = props;
  const [open, setOpen] = useState(false);
  const [examOngoing, setExamOngoing] = useState(false);

  const scorePercentage = calculateScorePercentage(
    exam.score,
    exam.examDetails.maxQuestionCount
  );

  const now = Date.now();

  useEffect(() => {
    if (Number(exam.examDetails.endDate) > now) {
      setExamOngoing(true);
    }
  }, []);

  return (
    <>
      <TableRow>
        <StyledTableCell>
          <RowContainer>
            <ExamContainer>
              <Link
                component="button"
                variant="body2"
                underline="hover"
                color="#212427"
                sx={{ fontSize: '14px', marginRight: 2 }}
              >
                {exam.examDetails.title}
              </Link>
            </ExamContainer>
            <Stack
              direction="row"
              component="span"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              spacing={0.5}
            >
              {examOngoing ? (
                <Chip
                  label="Ongoing"
                  size="small"
                  sx={{
                    fontSize: '11px',
                    backgroundColor: '#f5f5f5',
                    width: 60,
                  }}
                />
              ) : (
                <></>
              )}
              {exam.passed != null ? (
                exam.passed ? (
                  <>
                    <Link
                      rel="noreferrer"
                      href={`${window.location.origin}/certificate/view/${exam.id}`}
                      target="_blank"
                      underline="hover"
                      sx={{ mr: '12px' }}
                    >
                      view certificate
                    </Link>
                    <Tag minWidth="60px" label="Passed" bg="#BDE7BD" />
                  </>
                ) : (
                  <Tag minWidth="60px" label="Failed" bg="#FF9997" />
                )
              ) : null}
              {exam.submittedAt && (
                <>
                  <svg
                    width={44}
                    height={44}
                    style={{
                      marginLeft: '12px',
                    }}
                  >
                    <VictoryPie
                      endAngle={360}
                      standalone={false}
                      radius={22}
                      width={44}
                      innerRadius={17}
                      height={44}
                      data={[
                        { x: 0, y: scorePercentage },
                        { x: 0, y: 100 - scorePercentage },
                      ]}
                      labels={() => ''}
                      colorScale={['#374EA2', '#EEEEEE']}
                    />

                    <VictoryLabel
                      textAnchor="middle"
                      style={{ fontSize: 11, fontWeight: 600 }}
                      x={22}
                      y={22}
                      text={`${scorePercentage}%`}
                    />
                  </svg>
                  <IconButton size="small" onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </>
              )}
            </Stack>
          </RowContainer>
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ border: 0, padding: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableBody style={{ backgroundColor: '#fafafa' }}>
                <TableRow key={exam.id}>
                  <TableCell
                    align="center"
                    sx={examDetailsStyles}
                    style={{ width: '25%' }}
                  >
                    <ExamContainer>
                      <ExamDetailsText>Module:</ExamDetailsText>
                      <Typography sx={{ fontSize: '12px' }}>
                        {exam.examDetails.module}
                      </Typography>
                    </ExamContainer>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={examDetailsStyles}
                    style={{ width: '25%' }}
                  >
                    <ExamContainer>
                      <ExamDetailsText>Score:</ExamDetailsText>
                      <Typography sx={{ fontSize: '12px' }}>
                        <span
                          style={{
                            fontWeight: 'bold',
                            fontSize: 14,
                          }}
                        >
                          {Math.round(exam.score * 100) / 100}
                        </span>
                        /{exam.examDetails.maxQuestionCount}
                      </Typography>
                    </ExamContainer>
                  </TableCell>
                  <TableCell align="center" sx={examDetailsStyles}>
                    <ExamContainer>
                      <ExamDetailsText>Submitted at:</ExamDetailsText>
                      <Typography sx={{ fontSize: '12px' }}>
                        {new Date(exam.submittedAt).toLocaleString()}
                      </Typography>
                    </ExamContainer>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default StudentExamsListRow;
