/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useState } from 'react';
import { IconButton, TableRow, Typography } from '@mui/material';
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded';
import {
  RowContainer,
  ExamContainer,
  StyledTableCell,
} from '../../../styles/admin/ExamsListStyles';
import { AdminsInterface } from './AdminsList';
import DeleteAdminModal from '../../modal/admin/DeleteAdmin/DeleteAdminModal';

export interface AdminsListRowInterface {
  admin: AdminsInterface;
}

const AdminsListRow = (props: AdminsListRowInterface) => {
  const { admin } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <StyledTableCell>
          <RowContainer>
            <ExamContainer>
              <Typography sx={{ fontSize: '14px', color: '#242526' }}>
                {admin.email}
              </Typography>
            </ExamContainer>
            {open ? (
              <DeleteAdminModal admin={admin} open={open} setOpen={setOpen} />
            ) : (
              <></>
            )}
            <IconButton size="small" onClick={() => setOpen(true)}>
              <PersonRemoveRoundedIcon sx={{ color: '#ED4337' }} />
            </IconButton>
          </RowContainer>
        </StyledTableCell>
      </TableRow>
    </>
  );
};

export default AdminsListRow;
