import { gql } from '@apollo/client';

export const UPDATE_MS_CHOICE_MUTATION = gql`
  mutation UpdateMultipleSelectionChoice($input: UpdateChoiceInput!) {
    updateMultipleSelectionChoice(input: $input) {
      id
      description
      isCorrect
    }
  }
`;
