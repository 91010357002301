import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { StyledDialogContent } from '../../../../styles/admin/QuestionsPageStyles';
import { StyledDialogButtonBox } from '../../../../styles/styledComponents/CustomDialogStyles';
import CustomAlert from '../../../alert/CustomAlert';
import { ExamLinkModalProps } from './examLinkModalProps';
import CustomDialogTitle from '../common/CustomDialogTitle';

function ExamLinkModal({ isOpen, handleClose, examLink }: ExamLinkModalProps) {
  const matches = useMediaQuery('(min-width:600px)');
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return Promise.resolve(navigator.clipboard.writeText(text));
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(examLink)
      .then(() => {
        setIsCopied(true);
      })
      .catch((error: Error) => {
        throw Error(error.message);
      });
  };

  return (
    <div>
      <CustomAlert
        message="Copied to clipboard."
        open={isCopied}
        handleClose={() => setIsCopied(false)}
      />
      <Dialog
        data-testid="exam-link-modal"
        open={isOpen}
        onClose={handleClose}
        fullScreen={!matches}
        fullWidth
      >
        <CustomDialogTitle text="Exam Link" handleClose={handleClose} />
        <Box height={10} />
        <DialogContent>
          <StyledDialogContent>
            <Box>
              <Typography sx={{ fontSize: matches ? 16 : 14, fontWeight: 600 }}>
                Link
              </Typography>
            </Box>
            <Box sx={{ paddingTop: '10px' }}>
              <TextField
                data-testid="exam-link-field"
                variant="standard"
                fullWidth
                value={examLink}
                InputProps={{
                  readOnly: true,
                }}
                onFocus={(event) => event.target.select()}
              />
            </Box>
          </StyledDialogContent>
          <StyledDialogButtonBox>
            <Button
              onClick={handleClose}
              sx={{
                marginRight: '15px',
                textTransform: 'none',
                boxShadow: 0,
              }}
            >
              Cancel
            </Button>
            <Button
              disableElevation
              variant="contained"
              onClick={handleCopyClick}
              autoFocus
              sx={{
                color: '#FFFFFF',
                textTransform: 'none',
                boxShadow: 0,
              }}
            >
              Copy
            </Button>
          </StyledDialogButtonBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ExamLinkModal;
