/* eslint-disable @typescript-eslint/no-shadow */
import React, { ChangeEvent, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Stack,
  TextField,
  MenuItem,
  Dialog,
  useMediaQuery,
  DialogContent,
  IconButton,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import {
  DatePicker,
  LocalizationProvider,
  MobileDatePicker,
  MobileTimePicker,
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Edit } from '@mui/icons-material';
import {
  AddExamButtonContainer,
  DivAddExamStyle,
  AddExamContentStyle,
} from '../../../../styles/admin/AddExamModalStyles';
import CustomDialogTitle from '../common/CustomDialogTitle';
import EditExamTextField from './EditExamDetailsTextField';
import {
  UpdateExamMutation,
  UpdateExamMutationVariables,
} from '../../../../graphql/mutations/admin/exam/updateExam.generated';
import UPDATE_EXAM_MUTATION from '../../../../graphql/mutations/admin/exam/updateExam';
import { ExamType, Program, Quarter } from '../../../../types.generated';
import { EditExamModalProps } from '../../../../pages/admin/editExam/editExamProps';
import { programs, quarters, types } from '../AddExam/dropdownOptionsData';
import { mergeDateTime } from '../../../../utils/dateConverter';

const EditExamModal = (props: EditExamModalProps) => {
  const { exam } = props;
  const matches = useMediaQuery('(min-width:600px)');
  const examStartDate = new Date(exam.startDate as Date);
  const examEndDate = new Date(exam.endDate as Date);

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(String(exam.title));
  const [description, setDescription] = useState(exam.description);
  const [examType, setExamType] = useState(String(exam.examType));
  const [year, setYear] = useState<Date | null>(new Date());
  const [program, setProgram] = useState(String(exam.program));
  const [module, setModule] = useState(String(exam.module));
  const [quarter, setQuarter] = useState(String(exam.quarter));
  const [startDate, setStartDate] = useState<Date | null>(
    exam.startDate ? examStartDate : null
  );
  const [startTime, setStartTime] = useState<Date | null>(
    exam.startDate ? examStartDate : null
  );
  const [endDate, setEndDate] = useState<Date | null>(
    exam.endDate ? examEndDate : null
  );
  const [endTime, setEndTime] = useState<Date | null>(
    exam.endDate ? examEndDate : null
  );
  const [duration, setDuration] = useState<number | null>(
    exam.duration ? exam.duration : null
  );

  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const handleExamTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setExamType(event.target.value);
  };

  const handleProgramChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProgram(event.target.value);
  };

  const handleModuleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setModule(event.target.value);
  };

  const handleQuarterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuarter(event.target.value);
  };

  const handleDurationChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDuration(Number(event.target.value));
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const startDateTime = mergeDateTime(startDate as Date, startTime as Date);
  const endDateTime = mergeDateTime(endDate as Date, endTime as Date);

  const [updateExam] = useMutation<
    UpdateExamMutation,
    UpdateExamMutationVariables
  >(UPDATE_EXAM_MUTATION, {
    variables: {
      input: {
        id: exam.id,
        title: title,
        description: description,
        program: program as Program,
        module: module,
        year: year?.getFullYear() as number,
        quarter: quarter as Quarter,
        startDate: examType === 'FIXED' ? startDateTime : null,
        endDate: examType === 'FIXED' ? endDateTime : null,
        duration: examType === 'FLEXIBLE' ? duration : null,
        examType: examType as ExamType,
      },
    },
    onCompleted: () => {
      handleClose();
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateExam();
  };

  return (
    <div>
      <IconButton data-testid="edit-exam-button" onClick={handleOpen}>
        <Edit color="primary" sx={{ fontSize: '18px' }} />
      </IconButton>
      <Dialog
        data-testid="edit-exam-modal"
        open={open}
        onClose={handleClose}
        maxWidth={matches ? 'md' : 'sm'}
        fullScreen={!matches}
      >
        <CustomDialogTitle text="Edit Exam" handleClose={handleClose} />
        <DialogContent>
          <Box sx={{ height: '30px' }} />
          <form onSubmit={handleSubmit}>
            <DivAddExamStyle>
              <AddExamContentStyle>
                <EditExamTextField
                  title="Exam Title"
                  defaultValue={title}
                  onChange={handleTitleChange}
                  testId="exam-title-textfield"
                />
                <EditExamTextField
                  title="Description"
                  defaultValue={description}
                  onChange={handleDescriptionChange}
                  testId="exam-description-textfield"
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                  marginY={1}
                >
                  <Typography sx={{ fontSize: 16 }}>Exam Type</Typography>
                  <TextField
                    required
                    select
                    data-testid="exam-type-textfield"
                    value={examType}
                    onChange={handleExamTypeChange}
                    defaultValue={examType}
                    sx={{ width: 400 }}
                  >
                    {types.map((type) => (
                      <MenuItem key={type.id} value={type.value}>
                        {type.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                  marginY={1}
                >
                  <Typography sx={{ fontSize: 16 }}>Program</Typography>
                  <TextField
                    required
                    select
                    data-testid="program-textfield"
                    value={program}
                    onChange={handleProgramChange}
                    defaultValue={program}
                    sx={{ width: 400 }}
                  >
                    {programs.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
                <EditExamTextField
                  title="Module"
                  defaultValue={module}
                  onChange={handleModuleChange}
                  testId="exam-module-textfield"
                />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                  marginY={1}
                >
                  <Typography sx={{ fontSize: 16 }}>Cohort</Typography>
                  <Stack direction="row" spacing={1}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        views={['year']}
                        value={year}
                        PopperProps={{ placement: 'right-start' }}
                        onChange={(newValue) => setYear(newValue)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            data-testid="year-date-picker"
                            sx={{ width: 196 }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <TextField
                      required
                      data-testid="quarter-textfield"
                      select
                      value={quarter}
                      onChange={handleQuarterChange}
                      defaultValue={quarter}
                      sx={{ width: 196 }}
                    >
                      {quarters.map((option) => (
                        <MenuItem key={option.id} value={option.title}>
                          {option.title}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Stack>
                </Stack>
                {examType === 'FIXED' && (
                  <>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                      marginY={1}
                    >
                      <Typography sx={{ fontSize: 16 }}>Start</Typography>
                      <Stack direction="row" spacing={1}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            disablePast
                            showTodayButton
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                required
                                data-testid="start-date-picker"
                                placeholder="Select Date"
                                sx={{ width: 196 }}
                              />
                            )}
                            value={startDate}
                            onChange={(newValue) => {
                              setStartDate(newValue);
                            }}
                          />
                          <MobileTimePicker
                            showTodayButton
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                required
                                data-testid="start-time-picker"
                                placeholder="Select Time"
                                sx={{ width: 196 }}
                              />
                            )}
                            value={startTime}
                            onChange={(newValue) => {
                              setStartTime(newValue);
                            }}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                      marginY={1}
                    >
                      <Typography sx={{ fontSize: 16 }}>End</Typography>
                      <Stack direction="row" spacing={1}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            minDate={startDate}
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                required
                                data-testid="end-date-picker"
                                placeholder="Select Date"
                                sx={{ width: 196 }}
                              />
                            )}
                            value={endDate}
                            onChange={(newValue) => {
                              setEndDate(newValue);
                            }}
                          />
                          <MobileTimePicker
                            showTodayButton
                            renderInput={(props) => (
                              <TextField
                                {...props}
                                required
                                data-testid="end-time-picker"
                                placeholder="Select Time"
                                sx={{ width: 196 }}
                              />
                            )}
                            value={endTime}
                            onChange={(newValue) => {
                              setEndTime(newValue);
                            }}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Stack>
                  </>
                )}
                {examType === 'FLEXIBLE' && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={2}
                    marginY={1}
                  >
                    <Typography sx={{ fontSize: 16 }}>Duration</Typography>
                    <Stack direction="row" spacing={1} paddingLeft={2.5}>
                      <TextField
                        required
                        data-testid="exam-duration"
                        type="number"
                        sx={{
                          width: 90,
                        }}
                        value={duration || ''}
                        onChange={handleDurationChange}
                        InputProps={{ inputProps: { min: 0 } }}
                      />
                      <Typography
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        Minutes
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </AddExamContentStyle>
              <Box height={20} />
              <AddExamButtonContainer>
                <Button
                  data-testid="save-edit-exam-button"
                  variant="contained"
                  type="submit"
                  disableElevation
                  sx={{ textTransform: 'none', boxShadow: 0 }}
                >
                  Edit Exam
                </Button>
              </AddExamButtonContainer>
            </DivAddExamStyle>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditExamModal;
