import React from 'react';
import QuestionModal from '../QuestionModal';
import { MSDialogProps } from '../questionModalProps';
import {
  QuestionAnswerInterface,
  UserInterface,
} from '../../../../../pages/admin/questionBank/QuestionInterface';
import { Program } from '../../../../../types.generated';

function MSQuestionModal({ data, author }: MSDialogProps) {
  const id: string = data?.multipleSelectionQuestion?.id ?? '';
  const program: Program = data?.multipleSelectionQuestion?.program as Program;
  const module: string = data.multipleSelectionQuestion?.module as string;
  const questionType =
    data?.multipleSelectionQuestion?.__typename ?? 'MultipleSelectionQuestion';
  const question: string = data?.multipleSelectionQuestion?.question ?? '';
  const questionChoices: QuestionAnswerInterface[] = data
    ?.multipleSelectionQuestion?.choices as QuestionAnswerInterface[];
  const createdAt: Date = data?.multipleSelectionQuestion?.createdAt as Date;

  const user: UserInterface = author.user as UserInterface;
  const firstName: string = user.firstName;
  const lastName: string = user.lastName;
  const createdBy = `${firstName} ${lastName}`;

  return (
    <QuestionModal
      questionId={id}
      program={program}
      module={module}
      questionType={questionType}
      question={question}
      possibleAnswers={questionChoices}
      author={createdBy}
      createdAt={createdAt}
    />
  );
}
export default MSQuestionModal;
