import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    secondary: {
      main: '#374EA2',
    },
    background: {
      paper: '#f8f8f8',
    },
  },
  typography: {
    fontFamily: '"Mulish","Muli","Roboto", "Arial", "Calibri", "Helvetica"',
    fontSize: 14,
  },
});

export default theme;
