import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import PageNotFound from '../components/states/PageNotFound';
import SideNavigation from '../components/navigation/admin/SideNavigation';
import { getUser, getUserName, getUserRoles } from './userInfo/user';

const AdminPrivateRouteLayout = (props: { adminName: string }) => {
  return (
    <>
      <SideNavigation name={props.adminName} />
      <div style={{ paddingLeft: 180 }}>
        <Outlet />
      </div>
    </>
  );
};

function ProtectedAdminRoute(props: { allowedRole: string;}) {
  const isAuthenticated = getUser();
  const roles = getUserRoles()
  const name = getUserName()


  return !isAuthenticated ? (
    <Navigate to="/signin" />
  ) : roles.includes(props.allowedRole) ? (
    <AdminPrivateRouteLayout adminName={name} />
  ) : (
    <PageNotFound redirectTo="/" />
  );
}

export default ProtectedAdminRoute;
